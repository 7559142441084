 /************************************************************************************* */
let initialState = {
  inputEmail: '',
  inputCode: '',
  inputPassword: '',
  inputConfirmationPassword: '',
  errorMessage: '',
  currentPage: 'email',

  isAuthorized: undefined,
  clubCoachesIdArray: [], 
  isCoach: false,
  isAdmin: false,

  isFetching: false,
}
/************************************************************************************* */
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SN/AUTH-REDUCER/SET-NEW-OBJECTS': 
      return { ...state, ...action.values }

    case 'SN/AUTH-REDUCER/SET-INPUT-EMAIL':
      return { ...state, errorMessage: '', inputEmail: action.email }

    case 'SN/AUTH-REDUCER/SET-INPUT-CODE':
      return { ...state, errorMessage: '', inputCode: action.code }

    case 'SN/AUTH-REDUCER/SET-INPUT-PASSWORD':
      return { ...state, errorMessage: '', inputPassword: action.password }

    case 'SN/AUTH-REDUCER/SET-INPUT-CONFIRMATION-PASSWORD':
      return {
        ...state,
        errorMessage: '',
        inputConfirmationPassword: action.сonfirmationPassword,
      }

    case 'SN/AUTH-REDUCER/SET-ERROR-MESSAGE':
      return { ...state, errorMessage: action.message }

    case 'SN/AUTH-REDUCER/SET-CURRENT-PAGE':
      return { ...state, currentPage: action.pageName }

    case 'SN/AUTH-REDUCER/SET-IS-AUTHORIZED':
      return { ...state, isAuthorized: action.boolValue }


      case 'SN/AUTH-REDUCER/SET-ISFETCHING':
        return { ...state, isFetching: action.value }

    default:
      return state
  }
}
/************************************************************************************* */
export const setNewObjects = (values) => ({
  type: 'SN/AUTH-REDUCER/SET-NEW-OBJECTS',
  values,
})

export const setInputEmail = (email) => ({
  type: 'SN/AUTH-REDUCER/SET-INPUT-EMAIL',
  email,
})

export const setInputCode = (code) => ({
  type: 'SN/AUTH-REDUCER/SET-INPUT-CODE',
  code,
})

export const setInputPassword = (password) => ({
  type: 'SN/AUTH-REDUCER/SET-INPUT-PASSWORD',
  password,
})

export const setInputConfirmationPassword = (сonfirmationPassword) => ({
  type: 'SN/AUTH-REDUCER/SET-INPUT-CONFIRMATION-PASSWORD',
  сonfirmationPassword,
})

export const setErrorMessage = (message) => ({
  type: 'SN/AUTH-REDUCER/SET-ERROR-MESSAGE',
  message,
})

export const setCurrentPage = (pageName) => ({
  type: 'SN/AUTH-REDUCER/SET-CURRENT-PAGE',
  pageName,
})

export const setIsAuthorized = (boolValue) => ({
  type: 'SN/AUTH-REDUCER/SET-IS-AUTHORIZED',
  boolValue,
})

export const setIsFetching = (value) => ({
  type: 'SN/AUTH-REDUCER/SET-ISFETCHING', 
  value,
})
/************************************************************************************* */
export default authReducer
