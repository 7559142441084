import React from "react";
import OneUser from "../../OneUser/OneUser"; 

let MinUserList = (props) => {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
          <div className=" container-fluid">
            <div className="row    gy-1  ">
              {props.users.map((u, i) => (
                <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <OneUser {...u} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MinUserList;
