import React from "react";
import Preloader from "../../../common/preloaders/Preloader";
import TEXTJSON from "../../../../data/text.json";
let CarouselItem = (props) => {
  let deletePhoto = (deleteImg) => {
    props.setIsDeliting(true);
    Promise.all([props.API.DeleteImage(deleteImg)]).then((results) => {
      props.setIsDeliting(false);

      if (results[0].data === "successfully") alert(TEXTJSON.FOTO_UDALENO);
      else alert(TEXTJSON.universal_error);
      props.setClubImage(null);
      Promise.all([props.API.GetImages()]).then((results) => {
        if (results[0].data.clubImages) props.setClubImage(results[0].data.clubImages);
      });
    });
  };
  // ============================================================
  return (
    <div className={"carousel-item w-100 h-100 " + props.activeOrEmpty}>
      <div style={{ backgroundImage: `url(${props.photoData.image.url.replace("wwwroot", "")})` }} className="w-100 h-100 myImg rounded-3"></div>
      {props.isEditMode ? (
        <div className="carousel-caption "> 
          <button type="button" onClick={() => deletePhoto(props.photoData.id)} className="btn btn-danger rounded-pill">
            {props.isDeliting ? <Preloader preloaderColor={"white"} /> : TEXTJSON.delete}
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
  // ============================================================
};

export default CarouselItem;
