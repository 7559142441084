import React from "react";
import  TEXTJSON  from "../../../../data/text.json";
let ListBtnChangeCoach = (props) => {
  return (
    <div>
      <label htmlFor="inputEmail4" className="form-label">
      {TEXTJSON.TRENER}
      </label>
      <select
      value={props.coachId}
        className="form-select "
        onChange={(e) => {
          props.act(e.target.value,props.userId);
        }}
      >
        <option selected>{TEXTJSON.TRENER}</option>
        {props.coaches.length === 0 ? (
          <option>{TEXTJSON.ZAGRUZKA}</option>
        ) : (
          props.coaches.map((u, index) => (
            <option id={u.user.id} value={u.user.id}>
              {u.user.firstName} {u.user.lastName}
            </option>
          ))
        )}
      </select>
    </div>
  );
};
export default ListBtnChangeCoach;
