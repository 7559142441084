let initialState = {
  club: null,
  weekDaysArray: [],
  newPhotoDataUrl: '',

  changingModalInput:"",
  changingModalSelect:"",
}

const editorClubReducer = (state = initialState, action) => {
  switch (action.type) {
    //ЗАПОЛНИТЬ КЛУБЫ
    case 'SN/EDITOR-CLUB/SET-CLUB':
      return { ...state, club: action.object }
    //СОРТИРУЯ ОБЕКТ "КЛУБ" РАСПРЕДЕЛИТЬ ДНИ НЕДЕЛИ ПО МАССИВУ С ДНЯМИ НЕДЕЛИ [weekDaysArray]
    case 'SN/EDITOR-CLUB/DISTRIBUTES-CLUB-WEEK-DAYS-OVER-ARRAY':
      let days = []
      for (var key in action.timetable) {
        switch (key) {
          case 'monday':
            days[0] = { ...action.timetable.monday }
            days[0].rusName = 'пн'
            break
          case 'tuesday':
            days[1] = { ...action.timetable.tuesday }
            days[1].rusName = 'вт'
            break
          case 'wednesday':
            days[2] = { ...action.timetable.wednesday }
            days[2].rusName = 'ср'
            break
          case 'thursday':
            days[3] = { ...action.timetable.thursday }
            days[3].rusName = 'чт'
            break
          case 'friday':
            days[4] = { ...action.timetable.friday }
            days[4].rusName = 'пт'
            break
          case 'saturday':
            days[5] = { ...action.timetable.saturday }
            days[5].rusName = 'вс'
            break
          case 'sunday':
            days[6] = { ...action.timetable.sunday }
            days[6].rusName = 'сб'
            break

          default:
            break
        }
      }
      return { ...state, weekDaysArray: days }
    //ЗАДАТЬ ЗНАЧЕНИЕ ДЛЯ DATAURL НОВОЙ ФОТОГРАФИИ
    case 'SN/EDITOR-CLUB/SET-NEW-PHOTO-DATAURL':
      return { ...state, newPhotoDataUrl: action.dataUrl }

    default:
      return state
  }
}

export const setClub = (object) => ({
  type: 'SN/EDITOR-CLUB/SET-CLUB',
  object,
})

export const distributesClubWeekDaysOverArray = (timetable) => ({
  type: 'SN/EDITOR-CLUB/DISTRIBUTES-CLUB-WEEK-DAYS-OVER-ARRAY',
  timetable,
})

export const setNewPhotoDataUrl = (dataUrl) => ({
  type: 'SN/EDITOR-CLUB/SET-NEW-PHOTO-DATAURL',
  dataUrl,
})

export default editorClubReducer
