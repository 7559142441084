import React from "react";
import { connect } from "react-redux";

import { setSocialMedia, setIsErrorCaught, setAllSocialMedia, setIsFetching,setItemIsLoadArray,setAllItemIsLoadArrayFalse } from "../../redux/socialMedia-reducer";
import * as axios from "axios";
import SocialMedia from "./SocialMedia";
import BigPreloader from "../common/preloaders/BigPreloader";
import TEXTJSON from "../../data/text.json";

// ================================================================================
class SocialMediaAPIComponent extends React.Component {
  // ================================================================================
  componentDidMount() {
    this.props.setIsFetching(true); 
    Promise.all([this.API.GetFindOutUsingAnyId()]).then((results) => { 
      if (results[0].data === "parametrs_empty" || results[0].data === "club_and_user_unexist") { 
        this.props.setIsErrorCaught(true);
        return;
      }
      this.props.setAllSocialMedia(results[0].data);
      this.props.setIsFetching(false);
    });
  }
  // ================================================================================
  API = {
    GetFindOutUsingAnyId: () => {
      return axios({
        method: "GET",
        url: "/SocialMedia/GetFindOutUsingAnyId/" + this.props.passedId,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    UpdateSocialMedia: (smLink,smName) => { 
      return axios({
        method: "PUT",
        url: "/SocialMedia/UpdateSocialMedia",
        data: {
          IdToBeDetermined: this.props.passedId,
          SocialMediaLink: smLink,
          SocialMediaName: smName,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    // ___________
  };
  // ================================================================================
  render() {
    return this.props.isErrorCaught ? <p>{TEXTJSON.OSHIBKA}  </p> : <>{this.props.isFething ? <BigPreloader preloaderColor="danger" /> : <SocialMedia {...this.props} API={this.API} />}</>;
  }
}
// ================================================================================
let mapStateToProps = (state) => {
  return {
    isErrorCaught: state.socialMedia.isErrorCaught,

    sherdog: state.socialMedia.sherdog,
    facebook: state.socialMedia.facebook,
    instagram: state.socialMedia.instagram,
    vk: state.socialMedia.vk,
    youTube: state.socialMedia.youTube,
    telegram: state.socialMedia.telegram,
    tiktok: state.socialMedia.tiktok,
    twitter: state.socialMedia.twitter,

    isFetching: state.socialMedia.isFetching,
    isLoadArray: state.socialMedia.isLoadArray,
  };
};
// ================================================================================
const SocialMediaConteiner = connect(mapStateToProps, {
  setSocialMedia,
  setIsErrorCaught,
  setAllSocialMedia,
  setIsFetching,
  setItemIsLoadArray,setAllItemIsLoadArrayFalse 
})(SocialMediaAPIComponent);
// ================================================================================
export default SocialMediaConteiner;
