import React from "react";
import { connect } from "react-redux";

import { setArticles, setCurrentPage, setTotalProductsCount, setIsFetching ,setIsPaginationLoad} from "../../redux/mediaList-reducer";
import * as axios from "axios";
import MediaList from "./MediaList";
import BigPreloader from "../common/preloaders/BigPreloader";
import BigTextAlert from "../common/BigTextAlert/BigTextAlert";
import TEXTJSON from "../../data/text.json";
// ================================================================================
class MediaListAPIComponent extends React.Component {
  // ================================================================================
  componentDidMount() {
    this.props.setIsFetching(true);

    Promise.all([this.API.TakeManyMedia()]).then((results) => {
      console.log(results[0]); 
      this.props.setArticles(results[0].data.articles);
      this.props.setTotalProductsCount(results[0].data.count)
      this.props.setIsFetching(false);
    });
  }
  // ================================================================================
  API = {
    TakeManyMedia: () => { 
      return axios({
        method: "GET",
        url: "/api/get/article/TakeManyMedia?page=" + this.props.currentPage + "&pageItemCount=" + this.props.pageSize,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => console.log(error.response));
    },
    // ___________
  };
  // ================================================================================
  render() {
    return <>{this.props.isFetching ? <BigPreloader preloaderColor="danger" /> :this.props.articles.length?  <MediaList {...this.props} API={this.API}  />:<BigTextAlert textinner={TEXTJSON.VIDEO_OTUVSTUYUT}/>}</>;
 

  }
}
// ================================================================================
let mapStateToProps = (state) => {
  return {
    articles: state.mediaList.articles,
    isFetching: state.mediaList.isFetching,
    pageSize: state.mediaList.pageSize,
    totalProductsCount: state.mediaList.totalProductsCount,
    currentPage: state.mediaList.currentPage,
    isPaginationLoad: state.mediaList.isPaginationLoad,
  };
};
// ================================================================================
const MediaListConteiner = connect(mapStateToProps, {
  setArticles,
  setCurrentPage,
  setTotalProductsCount,
  setIsFetching, setIsPaginationLoad
})(MediaListAPIComponent);
// ================================================================================
export default MediaListConteiner;
