import React from "react";
import UserList from "../common/UserList/UserList";
import TEXTJSON from "../../data/text.json";
function ClubCoaches(props) {

  let deleteCoach=( fighterId)=>{
    props.setIsFething(true);
    Promise.all([props.API.DeleteClubCoach(fighterId)]).then((results) => { 
      Promise.all([props.API.GetApprovedCoaches()]).then((results) => { 
        props.setCoaches(results[0].data.users);
        props.setIsFething(false);
      });
    });
}

  if(props.coaches.length<1)return(<p className="display-2 text-danger    m-auto">{TEXTJSON.empty}</p>)
  return (
    <article className="d-flex flex-column  ">
      <p  className="display-6 ps-2">{TEXTJSON.TRENERSKIJ_SOSTAV}</p>
      <UserList users={props.coaches}  deleteAct={deleteCoach}/>
    </article>
  );
}

export default ClubCoaches;
