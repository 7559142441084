import React from "react";
import { NavLink } from "react-router-dom";
import NextSpanIcon from "../NextSpanIcon/NextSpanIcon";

let OneUser = (props) => {
  return (
    <NavLink to={"/PersonalAccount/" + props.id} className="  link-dark    ">
      <div className="   d-flex flex-column align-items-center justify-content-center">
        <div className="my-wrapper w-100">
          <div className="my-mini-img-box myImg rounded-pill" style={{ backgroundImage: `url(${props.avatarUrl})` }}></div>
        </div>

        <p className="text-wrap text-center  link-dark  fw-bolder ">
          <span>{props.lastName}</span>
          <br />
          <span>{props.firstName}</span>
          <NextSpanIcon />
        </p>
      </div>
    </NavLink>
  );
};
export default OneUser;
