import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  setUser,
  setUserAll,
  setIsFething,
  setLastName,
  setFirstName,
  setPhoneNumber,
  setGender,
  setDateOfBirth,
  setWeight,
  setHeight,
  setAvatarUrl,
  setAbout,
  setCity,
  setItemIsLoadArray,
  setDataUtiFromInputFile,
  setCities,
} from "../../redux/personal-account-reducer";
import * as axios from "axios";
import PersonalAccount from "./PersonalAccount";
import BigPreloader from "../common/preloaders/BigPreloader";
class PersonalAccountAPIComponent extends React.Component {
  componentDidMount() {
    this.props.setUser({});
    this.props.setIsFething(true);

    Promise.all([this.API.GetProfileById()]).then((results) => {
      console.log(results[0].data);
      this.props.setUser(results[0].data);
      this.props.setUserAll(results[0].data);

      this.props.setIsFething(false);
    });

    Promise.all([this.API.GetCities()]).then((results) => {
      console.log(results[0].data);
      let cities = results[0].data;
      cities.push({ id: 0, name: "пусто" })
      this.props.setCities(cities);
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.userId !== this.props.match.params.userId) {
      this.props.setUser({});
      this.props.setIsFething(true);

      Promise.all([this.API.GetProfileById()]).then((results) => {
        console.log(results[0].data);
        this.props.setUser(results[0].data);
        this.props.setIsFething(false);
      });
    }
  }

  apiHeaders = {
    Accept: "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
  };

  API = {
    //   GET   GET   GET   GET   GET   GET   GET   GET   GET   GET
    GetCities: () => {
      return axios
        .get("/City/GetCities")
        .then((response) => response)
        .catch((error) => error.response);
    },
    GetProfileById: () => {
      console.log(this.props.match.params.userId);
      return axios
        .get("/Profile/GetProfileById/" + this.props.match.params.userId)
        .then((response) => response)
        .catch((error) => error.response);
    },

    UpdateAll: () => {
      console.log({
        UserId: this.props.match.params.userId,
        About: this.props.about,
        AvatarUrl: this.props.dataUtiFromInputFile,
        CityId: this.props.cityId,
        DateOfBirth: this.props.dateOfBirth,
        FirstName: this.props.firstName,
        Gender: this.props.gender,
        Height: this.props.height,
        LastName: this.props.lastName,
        PhoneNumber: this.props.phoneNumber,
        Weight: this.props.weight,
      });

      return axios({
        method: "put",
        url: "/api/put/user/UpdateAll",
        data: {
          UserId: this.props.match.params.userId,
          About: this.props.about,
          AvatarDataUri: this.props.dataUtiFromInputFile,
          CityId: this.props.cityId,
          DateOfBirth: this.props.dateOfBirth,
          FirstName: this.props.firstName,
          Gender: this.props.gender,
          Height: this.props.height,
          LastName: this.props.lastName,
          PhoneNumber: this.props.phoneNumber,
          Weight: this.props.weight,
        },
        headers: this.apiHeaders,
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
  };

  render() {
    return <>{this.props.isFething ? <BigPreloader preloaderColor="danger" /> : <PersonalAccount {...this.props} API={this.API} />}</>;
  }
}
let withURLDataComponentContainer = withRouter(PersonalAccountAPIComponent);
let mapStateToProps = (state) => {
  return {
    user: state.personalAccount.user,
    isFething: state.personalAccount.isFething,

    genderArray: state.personalAccount.genderArray,

    about: state.personalAccount.about,
    avatarUrl: state.personalAccount.avatarUrl,
    city: state.personalAccount.city,
    cityId: state.personalAccount.cityId,
    dateOfBirth: state.personalAccount.dateOfBirth,
    email: state.personalAccount.email,
    firstName: state.personalAccount.firstName,
    gender: state.personalAccount.gender,
    height: state.personalAccount.height,
    id: state.personalAccount.id,
    lastName: state.personalAccount.lastName,
    phoneNumber: state.personalAccount.phoneNumber,
    userName: state.personalAccount.userName,
    weight: state.personalAccount.weight,

    isLoadArray: state.personalAccount.isLoadArray,

    dataUtiFromInputFile: state.personalAccount.dataUtiFromInputFile,

    clubCoachesIdArray: state.auth.clubCoachesIdArray,
    isAuthorized: state.auth.isAuthorized,
    isCoach: state.auth.isCoach,
    isAdmin: state.auth.isAdmin,

    cities: state.personalAccount.cities,
  };
};

const PersonalAccountConteiner = connect(mapStateToProps, {
  setUser,
  setUserAll,
  setIsFething,
  setLastName,
  setFirstName,
  setPhoneNumber,
  setGender,
  setDateOfBirth,
  setWeight,
  setHeight,
  setAvatarUrl,
  setAbout,
  setCity,
  setItemIsLoadArray,
  setDataUtiFromInputFile,
  setCities,
})(withURLDataComponentContainer);

export default PersonalAccountConteiner;
