import React from "react";
import parse from "html-react-parser";
import TEXTJSON from "../../../data/text.json";
let Card = (props) => {
  return(<div className="col-12 col-sm-12  col-md-6 col-lg-4 col-xl-4">
    <div className="card shadow">
      <img src={props.image} className="w-100 card-img-top"/>
      {/* <div className="card-img-top my-card myImg" style={{ backgroundImage: `url(${props.image})` }} /> */}
      <div className="card-body">
        <h4>
          <span className="badge bg-dark">{props.cityName}</span>
        </h4>
        <h3 className="card-title    text-uppercase">{props.name} </h3>
        <h5 className="card-title fw-bolder">
          <span>{props.dateTime.split("T")[0]}</span> <br s/>
          <span>{props.dateTime.split("T")[1]}</span> <br /> 
        </h5>

        <a href={props.linkToBuyTicket} className="btn btn-lg btn-danger rounded-pill">
        {TEXTJSON.KUPIT_BILET}
        </a>
        {props.isAuthorized && (
                <button
                  className="btn btn-lg btn-danger rounded-pill m-2 border border-5 border-dark"
                  onClick={() => {
                    props.deleteNewsItem  ? props.deleteNewsItem(props.id) : console.log("");
                  }}
                >
                  {TEXTJSON.delete}
                </button>
              )}
        <hr />
        {/*  */}
        <div className="accordion accordion-flush" id={"accordionFlushExample" + props.indexT}>
          <div className="accordion-item">
            <h2 className="accordion-header  " id={"flush-headingOne" + props.indexT}>
              <button className="accordion-button collapsed   shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapseOne" + props.indexT} aria-expanded="false" aria-controls={"flush-collapseOne" + props.indexT}>
              {TEXTJSON.PODROBNEE} 
              </button>
            </h2>
            <div id={"flush-collapseOne" + props.indexT} className="accordion-collapse collapse" aria-labelledby={"flush-headingOne" + props.indexT} data-bs-parent={"#accordionFlushExample" + props.indexT}>
              <div className="accordion-body">{props.article && parse(props.article.split("\n").join("<br>"))} </div>
            </div>
          </div>
        </div>
        {/*  */}
      </div>
    </div>
  </div>);
};
export default Card;
