/************************************************************************************* */
let initialState = {
    coaches: [],
    isFeching:true
  }
  /************************************************************************************* */
  const clubCoachesLisrViewReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SN/CLUB-VIEW-COACHES-REDUCER/SET-COACHES':
        return { ...state, coaches:action.value }

        case 'SN/CLUB-VIEW-COACHES-REDUCER/SET-ISFECHING':
          return { ...state, isFeching:action.value }
  
      default:
        return state
    }
  }
  /************************************************************************************* */
  export const setCoaches = (value) => ({
    type: 'SN/CLUB-VIEW-COACHES-REDUCER/SET-COACHES',
    value,
  })

  export const setIsFeching = (value) => ({
    type: 'SN/CLUB-VIEW-COACHES-REDUCER/SET-ISFECHING',
    value,
  })
  
  /************************************************************************************* */
  export default clubCoachesLisrViewReducer
  