import React from "react";
import { connect } from "react-redux";
import ClubCoaches from "./ClubCoaches";
import { withRouter } from "react-router-dom";
import { setCoaches, setIsFething, setCurrentPage, setTotalProductsCount } from "../../redux/clubCoaches-reducer";
import * as axios from "axios";
import BtnPreloader from "../common/preloaders/BtnPreloader";

// ===========================================================================

class ClubCoachesAPIComponent extends React.Component {
  componentDidMount() { 
    this.props.setIsFething(true);

    Promise.all([this.API.GetApprovedCoaches()]).then((results) => {  
      this.props.setCoaches(results[0].data.users);
      this.props.setIsFething(false);
    });
  }

  // ===========================================================================

  API = {
    //   GET   GET   GET   GET   GET   GET   GET   GET   GET   GET
    GetApprovedCoaches: () => {
      return axios({
        method: "GET",
        url: "/club/GetApprovedCoaches?clubId=" + this.props.match.params.clubId,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },

    // UPDATE    UPDATE    UPDATE    UPDATE    UPDATE    UPDATE    UPDATE

    // ChangeFighterStarStatus: (CoachId) => {
    //   return axios({
    //     method: "PUT",
    //     url: "/club/ChangeFighterStarStatus",
    //     data: {
    //       clubId: this.props.match.params.clubId,
    //       CoachesId: CoachId,
    //     },
    //     headers: {
    //       Accept: "application/json",
    //       Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    //     },
    //   })
    //     .then((response) => response)
    //     .catch((error) => error.response);
    // },
    // DELETE   DELETE   DELETE   DELETE   DELETE   DELETE   DELETE   DELETE
    DeleteClubCoach: (CoachId) => {
      return axios({
        method: "PUT",
        url: "/club/DeleteClubCoach",
        data: {
          clubId: this.props.match.params.clubId,
          CoachId: CoachId,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
  };

  // ===========================================================================

  render() {
    return <>{this.props.IsFetching ? <BtnPreloader preloaderColor="dark" /> : <ClubCoaches {...this.props} API={this.API} />}</>;
  }
}

// ===========================================================================

let mapStateToProps = (state) => {
  return {
    coaches: state.clubCoaches.coaches,

    IsFetching: state.clubCoaches.IsFetching,
    //_____________
    pageSize: state.clubCoaches.pageSize,
    TotalProductsCount: state.clubCoaches.TotalProductsCount,
    currentPage: state.clubCoaches.currentPage,

    // _____________________________________
    isAuthorized: state.auth.isAuthorized,
  };
};

// ===========================================================================

let withURLDataComponentContainer = withRouter(ClubCoachesAPIComponent);

// ===========================================================================

const ClubCoachesConteiner = connect(mapStateToProps, {
  setCoaches,
  setIsFething,
  setCurrentPage,
  setTotalProductsCount,
})(withURLDataComponentContainer);

// ===========================================================================

export default ClubCoachesConteiner;
