/************************************************************************************* */
let initialState = {
  isErrorCaught: false,
  sherdog: '',
  facebook: '',
  instagram: '',
  vk: '',
  youTube: '',
  telegram: '',
  tiktok: '',
  twitter: '',

  isFetching: true,

  // 0) sherdog: '',
  // 1) facebook: '',
  // 2) instagram: '',
  // 3) vk: '',
  // 4) youTube: '',
  // 5) telegram: '',
  // 6) tiktok: '',
  // 7) twitter: '',
  isLoadArray: [false, false, false, false, false, false, false, false],
}
// facebook, sherdog, vk, tiktok, twitter, youtube, instagram,telegram
/************************************************************************************* */
const socialMediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SN/SOCIALMEDIA-REDUCER/SET-SOCIALMEDIA':
      if (action.smName.toLowerCase() === 'sherdog'.toLowerCase())
        return { ...state, sherdog: action.value }
      else if (action.smName.toLowerCase() === 'facebook'.toLowerCase())
        return { ...state, facebook: action.value }
      else if (action.smName.toLowerCase() === 'instagram'.toLowerCase())
        return { ...state, instagram: action.value }
      else if (action.smName.toLowerCase() === 'vk'.toLowerCase())
        return { ...state, vk: action.value }
      else if (action.smName.toLowerCase() === 'youTube'.toLowerCase())
        return { ...state, youTube: action.value }
      else if (action.smName.toLowerCase() === 'telegram'.toLowerCase())
        return { ...state, telegram: action.value }
      else if (action.smName.toLowerCase() === 'tiktok'.toLowerCase())
        return { ...state, tiktok: action.value }
      else if (action.smName.toLowerCase() === 'twitter'.toLowerCase())
        return { ...state, twitter: action.value }
      else return { ...state }

    case 'SN/SOCIALMEDIA-REDUCER/SET-ALL-SOCIALMEDIA': 
      return { ...state, ...action.value }

    case 'SN/SOCIALMEDIA-REDUCER/SET-ISERRORCAUGHT':
      return { ...state, isErrorCaught: action.value }

    case 'SN/SOCIALMEDIA-REDUCER/SET-IS-FETCHING':
      return { ...state, isFetching: action.value }
 

    case 'SN/SOCIALMEDIA-REDUCER/SET-ITEM-IS-LOAD-ARRAY':
      console.log(action.arrIndex, action.value)
      var newIsLoadArray = state.isLoadArray
      newIsLoadArray[action.arrIndex] = action.value
      return { ...state, isLoadArray: [...newIsLoadArray] }

    case 'SN/SOCIALMEDIA-REDUCER/SET-ALL-ITEM-IS-LOAD-ARRAY-FALSE':
      let newArray = []
      for (let i = 0; i < state.isLoadArray.length; i++) {
        newArray[i] = false
      }
      return { ...state, isLoadArray: newArray }

    default:
      return state
  }
}
/************************************************************************************* */

export const setSocialMedia = (smName,value) => ({
  type: 'SN/SOCIALMEDIA-REDUCER/SET-SOCIALMEDIA',
  smName,
  value,
})

export const setAllSocialMedia = (value) => ({
  type: 'SN/SOCIALMEDIA-REDUCER/SET-ALL-SOCIALMEDIA',
  value,
})

export const setIsErrorCaught = (value) => ({
  type: 'SN/SOCIALMEDIA-REDUCER/SET-ISERRORCAUGHT',
  value,
})

export const setIsFetching = (value) => ({
  type: 'SN/SOCIALMEDIA-REDUCER/SET-IS-FETHING',
  value,
})

export const setItemIsLoadArray = (arrIndex, value) => ({
  type: 'SN/SOCIALMEDIA-REDUCER/SET-ITEM-IS-LOAD-ARRAY',
  arrIndex,
  value,
})

export const setAllItemIsLoadArrayFalse = (value) => ({
  type: 'SN/SOCIALMEDIA-REDUCER/SET-ALL-ITEM-IS-LOAD-ARRAY-FALSE',
  value,
})

/************************************************************************************* */
export default socialMediaReducer
