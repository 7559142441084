import React from "react";
import Preloader from "../../../common/preloaders/Preloader";
import TEXTJSON from "../../../../data/text.json";
function AddTraining(props) {
  //
  let sendTraining = () => {
 props.setIsAdded(true)
    Promise.all([props.API.AddToTrainings()]).then((results) => {
 props.setIsAdded(false)
      console.log(results[0].data);
      if (results[0].data === "successfully") { 
        props.setIsScheduleFetching(true);
        Promise.all([props.API.GetTrainings()]).then((results) => {
          if (results[0].data.trainings) props.setClubTrainings(results[0].data.trainings);
          props.setIsScheduleFetching(false);
        });
      }  
    });


  };
  //
  return (
    <div className=" container-fluid   my-4 ">
      <div className="row gy-4 row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">
        <div className="col">
          <label htmlFor="inputAddToClubTime" className="form-label">
         {TEXTJSON.VREMYA_TRENIROVKI} 
          </label>
          <input className="form-select " value={props.newTime} type="time" onChange={(e) => props.setNewTime(e.target.value)} id="inputAddToClubTime" />
        </div>
        {/*  */}
        <div className="col">
          <label htmlFor="inputEmail4" className="form-label">
          {TEXTJSON.DEN_NEDELI} 
          </label>
          <select
            className="form-select "
            onChange={(e) => {
              // console.log()
              props.setNewIndeDayInWeekId(e.target.options[e.target.options.selectedIndex].value);
            }}
          >
              <option selected> {TEXTJSON.VYBERI_DEN}</option>
            {props.cities && <option selected={true}>{TEXTJSON.ZAGRUZKA}</option>}
            {[TEXTJSON.PONEDELNIK,TEXTJSON.VTORNIK,TEXTJSON.SREDA,TEXTJSON.CHETVEG,TEXTJSON.PATUSA,TEXTJSON.SUBOTA,TEXTJSON.VOSKRESENIYE].map((day, index) => (
              <option   id={index} value={index}>
                {day}.
              </option>
            ))}
          </select>
        </div>
        {/*  */}
        <div className="col">
          <label htmlFor="inputAddToClubTrainerName" className="form-label">
         {TEXTJSON.TRENER} 
          </label>
          <input className="form-control " value={props.newTrainerName} type="text" onChange={(e) => props.setNewTrainerName(e.target.value)} id="inputAddToClubTrainerName" />
        </div>

        {/* <div className="col">
          <label htmlFor="inputEmail4" className="form-label">
          {TEXTJSON.TRENER}  
          </label>
          <select
            className="form-select "
            onChange={(e) => {
              props.setNewUserTrainerId(e.target.options[e.target.options.selectedIndex].value);
            }}
          >
              <option selected> {TEXTJSON.VYBERI_TRENERA} </option>
            {
              (props.coaches.length === 0 ? (
                <option > {TEXTJSON.ZAGRUZKA}</option>
              ) : (
                props.coaches.map((u, index) => (
                  <option  id={u.id} value={u.id}>
                    {u.firstName}  {u.lastName}
                  </option>
                ))
              ))
            }
          </select>
        </div> */}
        {/*  */}
        {/*  */}
        <div className="col">
          <label htmlFor="inputEmail4" className="form-label">
          {TEXTJSON.VID_SPORTA}  
          </label>
          <select
            className="form-select "
            onChange={(e) => {
              props.setNewSportTypeId(e.target.options[e.target.options.selectedIndex].value);
            }}
          >
              <option selected> {TEXTJSON.VYBERI_EDINABOSTVO} </option>
            {
              (props.sportTypes.length === 0 ? (
                <option > {TEXTJSON.ZAGRUZKA} </option>
              ) : (
                props.sportTypes.map((sp, index) => (
                  <option  id={sp.id} value={sp.id}>
                    {sp.name} 
                  </option>
                ))
              ))
            }
          </select>
        </div>
        {/*  */}
        <div className="col-12">
          <button className="btn btn-primary rounded-pill" onClick={()=>sendTraining() }>{props.isAdded?<Preloader/>:TEXTJSON.DOBAVIT}</button>
        </div>
        
      </div>
    </div>
  );
}
export default AddTraining;
