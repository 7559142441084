import React from "react";
import UserList from "../common/UserList/UserList";
import TEXTJSON from "../../data/text.json";
function ClubFighterRequests(props) {

  let rejectRequestFighter=( fighterId)=>{
    props.setIsFething(true);
    Promise.all([props.API.DeleteClubFighter(fighterId)]).then((results) => {  console.log(results[0].data)
      Promise.all([props.API.GetUnapprovedFighter()]).then((results) => { 
        props.setFighters(results[0].data.users);
        props.setIsFething(false);
      });
    });
}

let approveRequestFighter=(fighterId)=>{
    props.setIsFething(true);
    Promise.all([props.API.ApproveFighterRequest(fighterId)]).then((results) => {
        console.log(results[0].data)
      Promise.all([props.API.GetUnapprovedFighter()]).then((results) => {
        console.log(results[0].data)
        props.setFighters(results[0].data.users);
        props.setIsFething(false);
      });
    });
}


  if(props.fighters.length<1)return(<p className="display-2 text-danger    m-auto">{TEXTJSON.empty}</p>)
  return (
    <article className="d-flex flex-column  ">
      <p  className="display-6 ps-2">{TEXTJSON.ZAPROSY_NA_CHLENSTVO_V_KLUBE}</p>
      <UserList users={props.fighters} approveAct={approveRequestFighter} rejectAct={rejectRequestFighter}/>
    </article>
  );
}

export default ClubFighterRequests;
