let initialState = {
  clubImages: null,

  isClubImageFetching: true,

  isAdded: false,
  isDeliting: false,

  newPhohtoDataUrl: null,

  isEditMode: false,
}

const clubImagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SN/CLUB-IMAGES/SET-CLUBIMAGE':
      return { ...state, clubImage: action.value }

    case 'SN/CLUB-IMAGES/SET-ISCLUBIMAGEFETCHING':
      return { ...state, isClubImageFetching: action.value }

    case 'SN/CLUB-IMAGES/SET-ISADDED':
      return { ...state, isAdded: action.value }

    case 'SN/CLUB-IMAGES/SET-ISDELITING':
      return { ...state, isDeliting: action.value }

    case 'SN/CLUB-IMAGES/SET-NEWPHOHTODATAURL':
      return { ...state, newPhohtoDataUrl: action.value }

    case 'SN/CLUB-IMAGES/SET-ISEDITMODE':
      return { ...state, isEditMode: action.value }

    default:
      return { ...state }
  }
}

export const setClubImage = (value) => ({
  type: 'SN/CLUB-IMAGES/SET-CLUBIMAGE',
  value,
})

export const setIsClubImageFetching = (value) => ({
  type: 'SN/CLUB-IMAGES/SET-ISCLUBIMAGEFETCHING',
  value,
})

export const setIsAdded = (value) => ({
  type: 'SN/CLUB-IMAGES/SET-ISADDED',
  value,
})

export const setIsDeliting = (value) => ({
  type: 'SN/CLUB-IMAGES/SET-ISDELITING',
  value,
})

export const setNewPhohtoDataUrl = (value) => ({
  type: 'SN/CLUB-IMAGES/SET-NEWPHOHTODATAURL',
  value,
})

export const setIsEditMode = (value) => ({
  type: 'SN/CLUB-IMAGES/SET-ISEDITMODE',
  value,
})

export default clubImagesReducer
