import React from "react";
import Preloader from "../../../common/preloaders/Preloader";
// ============================================================
let InputEmail = (props) => {
  // ============================================================
  let save = () => {
    props.setIsUpdating("email", true);
    Promise.all([props.API.UpdateEmail()]).then((results) => {
      props.setIsUpdating("email", false);
      Promise.all([props.API.GetBasicInfo()]).then((results) => {
        //
      });
    });
  };
  // ============================================================
  let isEmailNull = props.email ? true : false;
  if (!isEmailNull && !props.isEditMode) return <></>;
  // ============================================================
  return (
    <>
      <div className="  d-flex align-items-center    ">
        <img alt="" className="me-2" width="20" src="/images/icons/email.svg" />
        {props.isEditMode ? (
          <div className="input-group  ">
            <input value={props.email} onChange={(event) => props.setEmail(event.target.value)} type="text" className="form-control   " />
            <button onClick={() => save()} className="btn  btn-primary" type="button" id="button-addon2">
              {props.isUpdating.email ? <Preloader preloaderColor={"white"} /> : <img alt="" height="20" src="/images/icons/save.svg" />}
            </button>
          </div>
        ) : (
          <p className=" m-0 fw-bold ">{props.email}</p>
        )}
      </div>
    </>
  );
  // ============================================================
};
// ============================================================
export default InputEmail;
