import React from "react";
import { connect } from "react-redux";

import {
  setUser,
  setLastName,
  setFirstName,
  setPhoneNumber,
  setGender,
  setDateOfBirth,
  setWeight,
  setHeight,
  setAvatarUrl,
  setAbout,
  setCity,
  setItemIsLoadArray,
  setDataUtiFromInputFile,
  setIsFething,
  setCities,
} from "../../redux/profile-edit-reducer";
import * as axios from "axios";
import ProfileEdit from "./ProfileEdit";
import BigPreloader from "../common/preloaders/BigPreloader";
import TEXTJSON from "../../data/text.json";

// ================================================================================
class ProfileEditAPIComponent extends React.Component {
  // ================================================================================
  componentDidMount() {
    this.props.setIsFething(true);

    Promise.all([this.API.GetProfileInfo()]).then((results) => {
      console.log(results[0].data)
      this.props.setUser({ ...results[0].data });

      if (results[0].data.gender !== "woman" && results[0].data.gender !== "man") this.props.setGender("all");
      this.props.setIsFething(false);
    });
    Promise.all([this.API.GetCities()]).then((results) => {
      this.props.setCities(results[0].data);
    });
  }
  // ================================================================================

  apiHeaders = {
    Accept: "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
  };

  API = {
    GetCities: () => {
      return axios
        .get("/City/GetCities")
        .then((response) => response)
        .catch((error) => error.response);
    },

    GetProfileInfo: () => {
      return axios({
        method: "GET",
        url: "/Profile/GetProfileInfo",
        headers: this.apiHeaders,
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    // ___________
    SetFirstName: () => {
      return axios({
        method: "post",
        url: "/Profile/SetFirstName",
        data: { FirstName: this.props.firstName },
        headers: this.apiHeaders,
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    //|||||||||||||||||||
    setLastName: () => {
      return axios({
        method: "post",
        url: "/Profile/SetLastName",
        data: { LastName: this.props.lastName },
        headers: this.apiHeaders,
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    SetPhoneNumber: () => {
      return axios({
        method: "post",
        url: "/Profile/SetPhoneNumber",
        data: { PhoneNumber: this.props.phoneNumber },
        headers: this.apiHeaders,
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    SetGender: () => {
      return axios({
        method: "post",
        url: "/Profile/SetGender",
        data: { Gender: this.props.gender },
        headers: this.apiHeaders,
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    //|||||||||||||||||||
    SetDateOfBirth: () => {
      return axios({
        method: "post",
        url: "/Profile/SetDateOfBirth",
        data: { DateOfBirth: this.props.dateOfBirth },
        headers: this.apiHeaders,
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    //|||||||||||||||||||
    SetWeight: () => {
      return axios({
        method: "post",
        url: "/Profile/SetWeight",
        data: { Weight: this.props.weight },
        headers: this.apiHeaders,
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    //|||||||||||||||||||
    SetHeight: () => {
      return axios({
        method: "post",
        url: "/Profile/SetHeight",
        data: { Height: this.props.height },
        headers: this.apiHeaders,
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    //|||||||||||||||||||
    setAvatarUrl: () => {
      return axios({
        method: "post",
        url: "/Profile/SetAvatarUrl",
        data: { AvatarDataUri: this.props.dataUtiFromInputFile },
        headers: this.apiHeaders,
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    //|||||||||||||||||||
    SetAbout: () => {
      return axios({
        method: "post",
        url: "/Profile/SetAbout",
        data: { About: this.props.about },
        headers: this.apiHeaders,
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    //|||||||||||||||||||
    SetCity: () => {
      console.log(123456789,this.props.cityId)
      return axios({
        method: "post",
        url: "/Profile/SetCity",
        data: { CityId: this.props.cityId },
        headers: this.apiHeaders,
      })
        .then((response) => response)
        .catch((error) =>error.response );
    },

    DeleteUser: (userId) => {
      console.log("/api/delete/user/completely/" + userId);
      console.log(userId, 12412);
      return axios({
        method: "delete",
        url: "/api/delete/user/completely/" + userId,
        headers: this.apiHeaders,
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    // ___________
  };
  // ================================================================================
  render() {
    return (
      <>
        {this.props.isFetching ? (
          <BigPreloader preloaderColor="danger" />
        ) : this.props.isAuthorized ? (
          <ProfileEdit {...this.props} API={this.API} />
        ) : (
          <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center">
            <btn className="btn mb-3 btn-lg  btn-primary rounded-pill" data-bs-toggle="modal" data-bs-target="#authModal" data-bs-dismiss="offcanvas" aria-label={TEXTJSON.zakryt}>
            {TEXTJSON.vojti}  
            </btn>
            <btn className="btn   btn-lg  btn-outline-primary rounded-pill" data-bs-toggle="modal" data-bs-target="#authModal" data-bs-dismiss="offcanvas" aria-label={TEXTJSON.zakryt}>
            {TEXTJSON.zaregistrirovatsya}   
            </btn>
          </div>
        )}
      </>
    );
  }
}
// ================================================================================
let mapStateToProps = (state) => {
  return {
    genderArray: state.profileEdit.genderArray,

    user: state.profileEdit.user,
    isFetching: state.profileEdit.isFetching,
    about: state.profileEdit.about,
    avatarUrl: state.profileEdit.avatarUrl,
    city: state.profileEdit.city,
    cityId: state.profileEdit.cityId,
    dateOfBirth: state.profileEdit.dateOfBirth,
    email: state.profileEdit.email,
    firstName: state.profileEdit.firstName,
    gender: state.profileEdit.gender,
    height: state.profileEdit.height,
    id: state.profileEdit.id,
    lastName: state.profileEdit.lastName,
    phoneNumber: state.profileEdit.phoneNumber,
    userName: state.profileEdit.userName,
    weight: state.profileEdit.weight,

    isLoadArray: state.profileEdit.isLoadArray,

    dataUtiFromInputFile: state.profileEdit.dataUtiFromInputFile,
 
    clubCoachesIdArray: state.auth.clubCoachesIdArray,
    isAuthorized: state.auth.isAuthorized,
    isCoach: state.auth.isCoach,
    isAdmin: state.auth.isAdmin,

    cities: state.profileEdit.cities,
  };
};
// ================================================================================
const ProfileEditConteiner = connect(mapStateToProps, {
  setUser,
  setLastName,
  setFirstName,
  setPhoneNumber,
  setGender,
  setDateOfBirth,
  setWeight,
  setHeight,
  setAvatarUrl,
  setAbout,
  setCity,
  setItemIsLoadArray,
  setDataUtiFromInputFile,
  setIsFething,
  setCities,
})(ProfileEditAPIComponent);
// ================================================================================
export default ProfileEditConteiner;
