import React from "react";
import TEXTJSON from "../../../../data/text.json";
let ListBtnApprove = (props) => { 
  return (
      <button onClick={()=>props.act(props.userId)} type="button" className="btn rounded-pill  btn-success">
  {TEXTJSON.odobrit}      
      </button>
  );
};
export default ListBtnApprove;
