import React from "react";
import { NavLink } from "react-router-dom";
import NextSpanIcon from "../../common/NextSpanIcon/NextSpanIcon";
import TEXTJSON from "../../../data/text.json";

let Menegment = (props) => (
  <div className="col-12 ">
    <div className="d-flex flex-column   align-items-start">
      <div className="alert alert-secondary " role="alert">
        <h4 className="alert-heading"> {TEXTJSON.UPROVLENIE_KLUBOM}</h4>
        <hr />
        <ul>
          <li>
            <NavLink to={"/ClubFighters/" + props.clubId} activeClassName="    fw-bolder  active" className="      fw-bolder   ">
              {TEXTJSON.SPISOK_BOJCOV}
              <NextSpanIcon />
            </NavLink>
          </li>
          <li>
            <NavLink to={"/ClubCoaches/" + props.clubId} activeClassName="    fw-bolder  active" className="      fw-bolder   ">
              {TEXTJSON.SPISOK_TRENEROV}
              <NextSpanIcon />
            </NavLink>
          </li>
          <li>
            <NavLink to={"/ClubCoachRequests/" + props.clubId} activeClassName="    fw-bolder  active" className="      fw-bolder   ">
              {TEXTJSON.ZAPROSY_NA_TRENERSTVO}
              <NextSpanIcon />
            </NavLink>
          </li>
          <li>
            <NavLink to={"/ClubFighterRequests/" + props.clubId} activeClassName="    fw-bolder  active" className="      fw-bolder   ">
              {TEXTJSON.ZAPROSY_NA_CHLENSTVO_V_KLUBE}
              <NextSpanIcon />
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default Menegment;
