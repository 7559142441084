import React from "react";
import ArticleReaderWithEditorConteiner from "../common/ArticleReaderWithEditor/ArticleReaderWithEditorConteiner";

function AboutClub(props) {
  return (
    <article>
        <ArticleReaderWithEditorConteiner isBlock={true} searchingArticleName={"about_club"}/>
    </article>
  );
}

export default AboutClub;
