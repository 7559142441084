import React from "react";
import * as axios from "axios";
import TEXTJSON from "../../../data/text.json";
let AddClub = (props) => {
  let act = () => {
    props.setIsFetching(true);
    Promise.all([props.API.AddClub()]).then((results) => {
        console.log(results[0].data)
      axios
        .get("/Club/GetListWithMinInfo?page=1&pageItemCount=5")
        .then(function (response) {
          props.setIsFetching(false);
          props.setClubs(response.data);
        })
        .catch(function (error) {
          props.setIsFetching(false);
          window.alert(TEXTJSON.universal_error);
        });
    }); 
  };
  return (
    <>
      <p>
        <button className="btn btn-dark  rounded-pill" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
        {TEXTJSON.DOBAVIT_KLUB}
        </button>
      </p>
      <div className="collapse" id="collapseExample">
        <div className="card card-body border-dark">
          <div>
            <label htmlFor="sendNewClub" className="form-label">
            {TEXTJSON.ADRES_KLUBA}
            </label>
            {console.log( props.setNewAddress,props.newAddress)}

            <input id="sendNewClub" type="text" className="form-control" onChange={(e) => props.setNewAddress(e.target.value)} value={props.newAddress} />

            <button className="rounded-pill btn  btn-success  mt-2" onClick={() => act()}>
            {TEXTJSON.DOBAVIT} +
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddClub;
