let initialState = {
  coaches: [],

  IsFetching:true,
  //_____________
  pageSize: 3,
  TotalProductsCount: 0,
  currentPage: 1,
}

const clubCoachRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SN/CLUB-COACH-REQUESTS-REDUCER/SET-COACHES':
      return { ...state, coaches: action.value }

      case "SN/CLUB-COACH-REQUESTS-REDUCER/SET-IS-FETHING":
      console.log(action.value)
        return { ...state, IsFetching: action.value }

      case "SN/CLUB-COACH-REQUESTS-REDUCER/SET-TOTAL-PRODUCTS-COUNT":
        return { ...state, TotalProductsCount: action.value }
      case "SN/CLUB-COACH-REQUESTS-REDUCER/SET-CURRENT-PAGE":
        return { ...state, currentPage: action.value }
   

    default:
      return state
  }
}

export const setCoaches = (value) => ({
  type: 'SN/CLUB-COACH-REQUESTS-REDUCER/SET-COACHES',
  value,
})

export const setIsFething = (value) => ({ type: "SN/CLUB-COACH-REQUESTS-REDUCER/SET-IS-FETHING", value });

export const setCurrentPage = (value) => ({ type: "SN/CLUB-COACH-REQUESTS-REDUCER/SET-CURRENT-PAGE", value });
export const setTotalProductsCount = (value) => ({ type: "SN/CLUB-COACH-REQUESTS-REDUCER/SET-TOTAL-PRODUCTS-COUNT",   value });

export default clubCoachRequestsReducer
