import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { setIsFeching, setCoaches } from "../../../redux/club-coachesLisrView-reducer";
import * as axios from "axios";
import Coaches from "./Coaches";
// ================================================================================
class CoachesAPIComponent extends React.Component {
  // ================================================================================
  componentDidMount() {
    this.props.setIsFeching(true);

    Promise.all([this.API.GetApprovedCoaches()]).then((results) => {
      if (results[0].data) {
        console.log(results[0].data);
        this.props.setCoaches(results[0].data.users);
      }
    });
    this.props.setIsFeching(false);
  }
  // ================================================================================
  API = {
    GetApprovedCoaches: () => {
      return axios({
        method: "GET",
        url: "/club/GetApprovedCoachesNoAuth?clubId=" + this.props.match.params.clubId,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      }).then((response) => response);
    },
    // ___________
  };
  // ================================================================================
  render() {
    return (
      <>
        {" "}
        <Coaches {...this.props} API={this.API} />{" "}
      </>
    );
  }
}
// ================================================================================
let mapStateToProps = (state) => {
  return {
    coaches: state.clubCoachesLisrView.coaches,
    isFeching: state.clubCoachesLisrView.isFeching,
    isAuthorized: state.auth.isAuthorized
  };
};
// ================================================================================
let withURLDataComponentContainer = withRouter(CoachesAPIComponent);

const CoachesConteiner = connect(mapStateToProps, {
  setIsFeching,
  setCoaches,
})(withURLDataComponentContainer);
// ================================================================================
export default CoachesConteiner;
