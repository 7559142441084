import React from "react";
import TEXTJSON from "../../data/text.json";

import ArticlesList from "../common/ArticlesList/ArticlesList";
import PaginationEnlarge from "../common/PaginationEnlarge/PaginationEnlarge";
function MediaList(props) {
  let moreArticles = () => {
    props.setIsPaginationLoad(true);
    props.setCurrentPage(props.currentPage + 1);
    Promise.all([props.API.TakeManyMedia()]).then((results) => {
      props.setArticles(props.articles.concat(results[0].data.articles));
      props.setIsPaginationLoad(false);
    });
  };
  return (
    <article>
      <div className=" d-flex flex-column align-items-center">
        <ArticlesList forNavLink={"media"} noPhoto={true}  titleForList={TEXTJSON.video} items={props.articles} />
        <div className="mt-5">
            <PaginationEnlarge act={() => moreArticles()}  localUsersCount={props.articles.length} totalProductsCount={props.totalProductsCount} isLoad={props.isPaginationLoad} />
        </div>
      </div>
    </article>
  );
}

export default MediaList;
