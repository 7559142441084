import React from "react";
import { NavLink } from "react-router-dom";
import TEXTJSON from "../../../../data/text.json";

let InLoginCoachButton = (props) => { 
  return (
    <NavLink   type="button" to="/ProfileEdit" className="btn btn-success rounded-pill btn-sm text-uppercase ">
     {TEXTJSON.STAT_TRENEROM}  
    </NavLink>
  );
};
export default InLoginCoachButton;
