/************************************************************************************* */
let initialState = {
  isFetching: true,

  articleId: "",
  articleName: "",
  articleTitle: "",
  articleCode: "",
  articleDate: "",
  article: "",
  isBtnLoad: false,
  editorState: "",
};
/************************************************************************************* */
const ArticleReaderWithEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SN/ARTICLE-READER-WITH-EDITOR/SET-ISFETCHING":
      return { ...state, isFetching: action.value };
    //==================================================================================
    case "SN/ARTICLE-READER-WITH-EDITOR/SET-ARTICLE":
      return { ...state, article: action.value };

    case "SN/ARTICLE-READER-WITH-EDITOR/SET-ARTICLE-ID":
      return { ...state, articleId: action.value };
    case "SN/ARTICLE-READER-WITH-EDITOR/SET-ARTICLE-NAME":
      return { ...state, articleName: action.value };
    case "SN/ARTICLE-READER-WITH-EDITOR/SET-ARTICLE-TITLE":
      return { ...state, articleTitle: action.value };
    case "SN/ARTICLE-READER-WITH-EDITOR/SET-ARTICLE-CODE":
      return { ...state, articleCode: action.value };
    case "SN/ARTICLE-READER-WITH-EDITOR/SET-ARTICLE-DATE":
      return { ...state, articleDate: action.value };

    //==================================================================================
    case "SN/ARTICLE-READER-WITH-EDITOR/SET-IS-IS-BTNLOAD":
      return { ...state, isBtnLoad: action.value };
    case "SN/ARTICLE-READER-WITH-EDITOR/SET-EDITORSTATE":
      return { ...state, editorState: action.value };

    default:
      return state;
  }
};
/************************************************************************************* */
export const setIsFetching = (value) => ({
  type: "SN/ARTICLE-READER-WITH-EDITOR/SET-ISFETCHING",
  value,
});
//==================================================================================

export const setArticle = (value) => ({
  type: "SN/ARTICLE-READER-WITH-EDITOR/SET-ARTICLE",
  value,
});

export const setArticleId = (value) => ({
  type: "SN/ARTICLE-READER-WITH-EDITOR/SET-ARTICLE-ID",
  value,
});

export const setArticleName = (value) => ({
  type: "SN/ARTICLE-READER-WITH-EDITOR/SET-ARTICLE-NAME",
  value,
});

export const setArticleTitle = (value) => ({
  type: "SN/ARTICLE-READER-WITH-EDITOR/SET-ARTICLE-TITLE",
  value,
});

export const setArticleCode = (value) => ({
  type: "SN/ARTICLE-READER-WITH-EDITOR/SET-ARTICLE-CODE",
  value,
});

export const setArticleDate = (value) => ({
  type: "SN/ARTICLE-READER-WITH-EDITOR/SET-ARTICLE-DATE",
  value,
});
//==================================================================================
export const setIsBtnLoad = (value) => ({
  type: "SN/ARTICLE-READER-WITH-EDITOR/SET-IS-IS-BTNLOAD",
  value,
});
export const setEditorState = (value) => ({
  type: "SN/ARTICLE-READER-WITH-EDITOR/SET-EDITORSTATE",
  value,
});

/************************************************************************************* */
export default ArticleReaderWithEditorReducer;
