import React from "react";
import  TEXTJSON  from "../../../data/text.json";
let InputText = (props) => {
  let activeAct = () => {
    props.act();
  };
  return (
    <div className="     d-flex flex-row justify-content-center align-items-center">
      {props.lable ? <div className=" fs-5  me-2  text-nowrap">{props.lable}</div> : <></>}
      {props.lableImg && <img alt="" className="me-2" width="20" src={props.lableImg} />}

      <input value={props.inputValue} className=" form-control   rounded-pill" type="text" onChange={(e) => props.setItem(e.target.value,props.typeFotMethod)} />
      {!props.noBtn && (
        <button type="button" className={"btn btn-primary btn-sm p-1  rounded-pill  ms-1 btn-" + (props.isLoad ? "secondary" : "primary")} onClick={() => activeAct()} disabled={props.isLoad ? true : false}>
          {props.isLoad ? (
            <div className="spinner-border spinner-border-sm text-light " role="status">
              <span className="visually-hidden ">{TEXTJSON.ZAGRUZKA}</span>
            </div>
          ) : (
            // <img alt="" className=" " width="20" src="/images/icons/save.svg"></img>
          <div className=" ">{TEXTJSON.SOHRANIT}</div>  
          )}
        </button>
      )}
    </div>
  );
};

export default InputText;
