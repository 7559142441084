import React from "react";
import UserList from "../common/UserList/UserList";
import TEXTJSON from "../../data/text.json";
function ClubCoachRequests(props) {

  let rejectRequestCoach=( fighterId)=>{
    props.setIsFething(true);
    Promise.all([props.API.DeleteClubCoach(fighterId)]).then((results) => { 
      Promise.all([props.API.GetUnapprovedCoaches()]).then((results) => { 
        props.setCoaches(results[0].data.users);
        props.setIsFething(false);
      });
    });
}

let approveRequestCoach=(fighterId)=>{
    props.setIsFething(true);
    Promise.all([props.API.ApproveCoachRequest(fighterId)]).then((results) => { 
      console.log(results[0].data)
      Promise.all([props.API.GetUnapprovedCoaches()]).then((results) => { 
        props.setCoaches(results[0].data.users);
        props.setIsFething(false);
      });
    });
}


  if(props.coaches.length<1)return(<p className="display-2 text-danger    m-auto">{TEXTJSON.empty}</p>)
  return (
    <article className="d-flex flex-column  ">
      <p  className="display-6 ps-2">{TEXTJSON.ZAPROSY_NA_TRENERSTVO}</p>
      <UserList users={props.coaches} approveAct={approveRequestCoach} rejectAct={rejectRequestCoach}/>
    </article>
  );
}

export default ClubCoachRequests;
