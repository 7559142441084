import React from "react";
import TEXTJSON from "../../../data/text.json";
let InputTextarea = (props) => {
  let activeAct = () => {
    props.act();
  };
  return (
    <div className="     d-flex flex-row justify-content-center align-items-center">
      {props.lable ? <div className="  ms-2  text-nowrap text-secondary">{props.lable}</div> : <></>}

      <textarea rows="4" value={props.inputValue} className=" form-control " onChange={(e) => props.setItem(e.target.value)} />
      {!props.noBtn && (
        <button type="button" className={"btn align-self-end btn-sm p-1  rounded-pill  ms-1  btn-" + (props.isLoad ? "secondary" : "primary")} onClick={() => activeAct()} disabled={props.isLoad ? true : false}>
          {props.isLoad ? (
            <div className="spinner-border spinner-border-sm text-light " role="status">
              <span className="visually-hidden">{TEXTJSON.ZAGRUZKA}</span>
            </div>
          ) : (
            // <img alt="" className=" " width="20" src="/images/icons/save.svg"></img>
            <div className=" ">{TEXTJSON.SOHRANIT}</div>
          )}
        </button>
      )}
    </div>
  );
};

export default InputTextarea;
