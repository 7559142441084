import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setIsFetching, setImages, setFolder, setDate, setIsOpen, setActivePhotoIndex, setImagesMin, setImagesBig } from "../../redux/eventImages-reducer";

import * as axios from "axios";
import EventImages from "./EventImages";
import BigPreloader from "../common/preloaders/BigPreloader";
// ================================================================================
class EventImagesAPIComponent extends React.Component {
  // ================================================================================
  componentDidMount() {
    this.props.setIsFetching(true);
    Promise.all([this.API.TakeFolders()]).then((results) => {


      let PHOTOS = results[0].data.images.map((img) => {
        let imgHref = img.href.replace("size=S", "size=XXXL");

        return `/api/get/photo/ImgWithAuth/${encodeURIComponent(img.href)}`
      });

      let PHOTOSBIG = results[0].data.images.map((img) => {
        let imgHref = img.href.replace("size=S", "size=XXXL");
        return `/api/get/photo/ImgWithAuth/${encodeURIComponent(imgHref)}`
      });

      this.props.setImagesMin(PHOTOS);
      this.props.setImagesBig(PHOTOSBIG);


      this.props.setImages(results[0].data.images);
      this.props.setFolder(results[0].data.folder);
      this.props.setDate(results[0].data.date);
      this.props.setIsFetching(false);
    });
  }
  // ================================================================================
  API = {
    TakeFolders: () => {
      return axios({
        method: "GET",
        url: "/api/get/photo/AllImagesFromFolderByName/" + this.props.match.params.folderName,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => console.log(error.response));
    },
    TakePic: (yUrl, ySize) => {
      return axios({
        method: "GET",
        url: yUrl.replace(`size=S`, `size=${ySize}`),
        headers: {
          responseType: "arraybuffer",
          Authorization: "OAuth AQAAAABVE5eJAAci_v6JgGAWYExMhssPIJyPF9s",
        },
      })
        .then((response) => {
          return response;
        })
    }
    // ___________
  };
  // ================================================================================
  render() {
    // return   <EventImages {...this.props} API={this.API}  /> ;
    return <> {this.props.isFetching ? <BigPreloader preloaderColor="danger" /> : <EventImages {...this.props} API={this.API} />}</>;
  }
}
// ================================================================================
let mapStateToProps = (state) => {
  return {
    images: state.eventImages.images,
    folder: state.eventImages.folder,
    date: state.eventImages.date,
    isFetching: state.eventImages.isFetching,
    isOpen: state.eventImages.isOpen,
    activePhotoIndex: state.eventImages.activePhotoIndex,
    postImagesMin: state.eventImages.postImagesMin,
    postImagesBig: state.eventImages.postImagesBig
  };
};

let withURLDataComponentContainer = withRouter(EventImagesAPIComponent);
// ================================================================================
const EventImagesConteiner = connect(mapStateToProps, {
  setIsOpen, setIsFetching,
  setImages,
  setFolder,
  setDate, setActivePhotoIndex, setImagesMin, setImagesBig
})(withURLDataComponentContainer);
// ================================================================================
export default EventImagesConteiner;
