import React from "react"; 
import EmbedContainer from "react-oembed-container"; 
import BigPreloader from "../common/preloaders/BigPreloader";
var options = {
  year: "numeric",
  month: "long",
  day: "numeric",
  // weekday: 'long',
  timezone: "UTC",
};

function Media(props) {
  return (
    <article>
      {props.isFetching ? (
        <BigPreloader preloaderColor="danger" />
      ) : (
        <>
          <div className="container">
            <div className="row justify-content-center">
              {!props.isBlock ? (
                <>
                  <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">
                    <p className="text-black-50">{new Date(props.articleDate).toLocaleString("ru", options)}</p>
                  </div>
                  <div className="col-12"></div>
                </>
              ) : (
                <></>
              )}

              <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">
                <EmbedContainer markup={props.article}>
                  {/* for example, */}
                  <article className="my-news">
                    <h1 className=" text-uppercase">{props.articleTitle}</h1>
                    <div dangerouslySetInnerHTML={{ __html: props.article }} />
                  </article>
                </EmbedContainer>
              </div>
            </div>
          </div>
        </>
      )}
    </article>
  );
}

export default Media;
