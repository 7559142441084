let initialState = {
  clubPriceList: null,

  sportTypes:[],

  isClubPriceListFetching: true,

  isAdded: false,
  isDeliting: false,

  newPrice: null,
  newSportTypeId: null,
  isEditMode: false,
}

const clubPriceListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SN/CLUB-PRICELIST/SET-CLUBPRICELIST':
      return { ...state, clubPriceList: action.value }

    case 'SN/CLUB-PRICELIST/SET-ISCLUBPRICELISTFETCHING':
      return { ...state, isClubPriceListFetching: action.value }

      
      case 'SN/CLUB-SCHEDULE-REDUCER/SET-SPORTTYPES':
        return { ...state, sportTypes: action.value }

    case 'SN/CLUB-PRICELIST/SET-ISADDED':
      return { ...state, isAdded: action.value }

    case 'SN/CLUB-PRICELIST/SET-ISDELITING':
      return { ...state, isDeliting: action.value }

    case 'SN/CLUB-PRICELIST/SET-NEWPRICE':
      console.log(action.value)
      return { ...state, newPrice: action.value }

    case 'SN/CLUB-PRICELIST/SET-NEWSPORTTYPEID':
      return { ...state, newSportTypeId: action.value }

    case 'SN/CLUB-PRICELIST/SET-ISEDITMODE':
      return { ...state, isEditMode: action.value }

    default:
      return { ...state }
  }
}

export const setClubPriceList = (value) => ({
  type: 'SN/CLUB-PRICELIST/SET-CLUBPRICELIST',
  value,
})

export const setIsClubPriceListFetching = (value) => ({
  type: 'SN/CLUB-PRICELIST/SET-ISCLUBPRICELISTFETCHING',
  value,
})

export const setSportTypes = (value) => ({
  type: 'SN/CLUB-SCHEDULE-REDUCER/SET-SPORTTYPES',
  value,
})


export const setIsAdded = (value) => ({
  type: 'SN/CLUB-PRICELIST/SET-ISADDED',
  value,
})

export const setIsDeliting = (value) => ({
  type: 'SN/CLUB-PRICELIST/SET-ISDELITING',
  value,
})

export const setNewPrice = (value) => ({
  
  type: 'SN/CLUB-PRICELIST/SET-NEWPRICE',
  value,
})

export const setNewSportTypeId = (value) => ({
  type: 'SN/CLUB-PRICELIST/SET-NEWSPORTTYPEID',
  value,
})

export const setIsEditMode = (value) => ({
  type: 'SN/CLUB-PRICELIST/SET-ISEDITMODE',
  value,
})

export default clubPriceListReducer
