/************************************************************************************* */
let initialState = {
  users: [],

  isFetching: true,
  //_____________
  pageSize: 9,
  totalProductsCount: 0, 
  currentPage: 1,


  isPaginationLoad:false,
}
/************************************************************************************* */
const coachesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SN/STARS-LIST-REDUCER/SET-USERS':
      return { ...state, users: action.value }

    case 'SN/STARS-LIST-REDUCER/SET-IS-FETHING': 
      return { ...state, isFetching: action.value }

    case 'SN/STARS-LIST-REDUCER/SET-TOTAL-PRODUCTS-COUNT':
      return { ...state, totalProductsCount: action.value }
    case 'SN/STARS-LIST-REDUCER/SET-CURRENT-PAGE': 
      return { ...state, currentPage: action.value }

      case "SN/STARS-LIST-REDUCER/SET-ISPAGINATIONLOAD":
        return { ...state, isPaginationLoad: action.value };

    default:
      return state
  }
}
/************************************************************************************* */
export const setUsers = (value) => ({
  type: 'SN/STARS-LIST-REDUCER/SET-USERS',
  value,
})

export const setIsFething = (value) => ({
  type: 'SN/STARS-LIST-REDUCER/SET-IS-FETHING',
  value,
})

export const setCurrentPage = (value) => ({
  type: 'SN/STARS-LIST-REDUCER/SET-CURRENT-PAGE',
  value,
})
export const setTotalProductsCount = (value) => ({
  type: 'SN/STARS-LIST-REDUCER/SET-TOTAL-PRODUCTS-COUNT',
  value,
})

export const setIsPaginationLoad = (value) => ({
  type: "SN/STARS-LIST-REDUCER/SET-ISPAGINATIONLOAD",
  value,
});


/************************************************************************************* */
export default coachesListReducer
