import React from "react";
import TEXTJSON from "../../../data/text.json";
let AlertInfo = (props) => {
  let alertMessage;
  if (props.alertText === undefined) {
    alertMessage = TEXTJSON.CHITAJTE_INSTRUKCIYU;
  }
  else{
    alertMessage=props.alertText;
  }
  return (
    <div className="alert alert-info" role="alert">
      {alertMessage}
    </div>
  );
};

export default AlertInfo;
