import React from "react";
import TEXTJSON from "../../../../data/text.json";
import NextSpanIcon from "../../../common/NextSpanIcon/NextSpanIcon";
let PasswordPage = (props) => {
  let InputValidOrNo;
  if (props.errorMessage) {
    InputValidOrNo = "is-invalid";
  } else {
    InputValidOrNo = "";
  }
  return (
    <div className="row gy-3 align-items-center">
      <div className="col-12">
        <label htmlFor="FormControlInputPassword" className="form-label">
        {TEXTJSON.PAROL}
        </label>
        <input value={props.inputPassword} onChange={(event) => props.setInputPassword(event.target.value)} type="password" className={"form-control  form-control-lg   " + InputValidOrNo} id="FormControlInputPassword" />
      </div> 
      <a type="button" class="  fs-5" href="/Accountutil/ForgotPassword" >Забыл пароль<NextSpanIcon/></a>
      <div className="invalid-feedback">* {props.errorMessage}</div>
    </div>
  );
};

export default PasswordPage;
