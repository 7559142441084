import React from "react";
import InputDate from "../common/InputDate/InputDate";
import InputSelect from "../common/InputSelect/InputSelect";
import InputSelectCity from "../common/InputSelectCity/InputSelectCity";
import InputText from "../common/InputText/InputText";
import InputTextarea from "../common/InputTextarea/InputTextarea";
import SocialMediaConteiner from "../SocialMedia/SocialMediaConteiner";
import UpdateAvatar from "./UpdateAvatar/UpdateAvatar";
import TEXTJSON from "../../data/text.json";

function ProfileEdit(props) {
  let saveAllChanges = (btnIndex) => {
    let apiArray = [props.API.SetFirstName, props.API.setLastName, props.API.SetPhoneNumber, props.API.SetGender, props.API.SetDateOfBirth, props.API.SetWeight, props.API.SetHeight, props.API.SetAbout, props.API.SetCity];

    props.setItemIsLoadArray(btnIndex, true);
    Promise.all([apiArray[0]()]).then((results) => {
      if (results[0].data === "successfully") {
        Promise.all([apiArray[1]()]).then((results) => {
          if (results[0].data === "successfully") {
            Promise.all([apiArray[2]()]).then((results) => {
              if (results[0].data === "successfully") {
                Promise.all([apiArray[3]()]).then((results) => {
                  if (results[0].data === "successfully") {
                    Promise.all([apiArray[4]()]).then((results) => {
                      if (results[0].data === "successfully") {
                        Promise.all([apiArray[5]()]).then((results) => {
                          if (results[0].data === "successfully") {
                            Promise.all([apiArray[6]()]).then((results) => {
                              if (results[0].data === "successfully") {
                                Promise.all([apiArray[7]()]).then((results) => {
                                  if (results[0].data === "successfully") {
                                    Promise.all([apiArray[8]()]).then((results) => {
                                      props.setItemIsLoadArray(btnIndex, false);
                                      if (results[0].data === "successfully") {
                                        console.log(TEXTJSON.successfully);
                                        props.setItemIsLoadArray(btnIndex, false);
                                      } else {
                                        alert(TEXTJSON.universal_error);
                                        props.setItemIsLoadArray(btnIndex, false);
                                      }
                                    });
                                  } else {
                                    alert(TEXTJSON.universal_error);
                                    props.setItemIsLoadArray(btnIndex, false);
                                  }
                                });
                              } else {
                                alert(TEXTJSON.universal_error);
                                props.setItemIsLoadArray(btnIndex, false);
                              }
                            });
                          } else {
                            alert(TEXTJSON.universal_error);
                            props.setItemIsLoadArray(btnIndex, false);
                          }
                        });
                      } else {
                        alert(TEXTJSON.universal_error);
                        props.setItemIsLoadArray(btnIndex, false);
                      }
                    });
                  } else {
                    alert(TEXTJSON.universal_error);
                    props.setItemIsLoadArray(btnIndex, false);
                  }
                });
              } else {
                alert(TEXTJSON.universal_error);
                props.setItemIsLoadArray(btnIndex, false);
              }
            });
          } else {
            alert(TEXTJSON.universal_error);
            props.setItemIsLoadArray(btnIndex, false);
          }
        });
      } else {
        alert(TEXTJSON.universal_error);
        props.setItemIsLoadArray(btnIndex, false);
      }
    });
  };

  let preservation = (btnIndex, apiFunc, setFunc) => {
    props.setItemIsLoadArray(btnIndex, true);
    Promise.all([apiFunc()]).then((results) => {
      props.setItemIsLoadArray(btnIndex, false);
      if (results[0].data === "successfully") console.log(TEXTJSON.successfully);
      else alert(TEXTJSON.universal_error);
    });
  };

  const exitFromAccaunt = () => {
    sessionStorage.removeItem("accessToken");
    window.location.reload();
  };

  const deleteBtn = (userId) => {
    Promise.all([props.API.DeleteUser(userId)]).then((results) => {
      if (results[0].data === "successfully") {
        sessionStorage.removeItem("accessToken");
        window.location.reload();
      } else {
        window.alert(TEXTJSON.universal_error);
      }
    });
  };

  return (
    <article className="">
      <div className=" container">
        <div className="row   justify-content-center  g-sm-0 g-md-0 g-lg-0 g-xl-0  gy-4 gy-sm-4 gy-md-4 gy-lg-4 gy-xl-4">
          <div className="col-10  col-sm-6 col-md-6 col-lg-6 col-xl-3">
            <div className="w-100 d-flex flex-column">
              <div className="my-wrapper w-100 ">
                <div className="my-mini-img-box   myImg rounded-pill" style={{ backgroundImage: `url(${props.avatarUrl})` }}></div>
              </div>

              <UpdateAvatar
                updataPhoto={props.setAvatarUrl}
                setItemIsLoadArray={props.setItemIsLoadArray}
                isLoad={props.isLoadArray[6]}
                setAvatarUrl={props.API.setAvatarUrl}
                dataUtiFromInputFile={props.dataUtiFromInputFile}
                setDataUtiFromInputFile={props.setDataUtiFromInputFile}
              />
            </div>
          </div>
          <div className="col-12 rounded-3 border bg-white p-3">
            <p className=" display-6">{TEXTJSON.IMYA}</p>
            <InputText isLoad={props.isLoadArray[0]} setItem={props.setFirstName} inputValue={props.firstName} act={() => preservation(0, props.API.SetFirstName)} />
          </div>

          <div className="col-12 rounded-3 border bg-white p-3">
            <p className=" display-6">{TEXTJSON.FAMILIYA}</p>
            <InputText isLoad={props.isLoadArray[1]} setItem={props.setLastName} inputValue={props.lastName} act={() => preservation(1, props.API.setLastName)} />
          </div>

          <div className="col-12 rounded-3 border bg-white p-3">
            <p className=" display-6">{TEXTJSON.NOMER_TELEFONA}</p>
            <InputText lable="+7" isLoad={props.isLoadArray[8]} setItem={props.setPhoneNumber} inputValue={props.phoneNumber} act={() => preservation(8, props.API.SetPhoneNumber)} />
          </div>

          <div className="col-12 rounded-3 border bg-white p-3">
            <p className=" display-6">{TEXTJSON.POL}</p>
            <InputSelect optionArray={props.genderArray} isLoad={props.isLoadArray[7]} setItem={props.setGender} inputValue={props.gender} act={() => preservation(7, props.API.SetGender)} />
          </div>

          <div className="col-12 rounded-3 border bg-white p-3">
            <p className=" display-6">{TEXTJSON.DATA_ROZHDENIYA}</p>
            <InputDate isLoad={props.isLoadArray[2]} setItem={props.setDateOfBirth} inputValue={props.dateOfBirth} act={() => preservation(2, props.API.SetDateOfBirth)} />
          </div>

          <div className="col-12 rounded-3 border bg-white p-3">
            <p className=" display-6">{TEXTJSON.VES}</p>
            <InputText isLoad={props.isLoadArray[3]} setItem={props.setWeight} inputValue={props.weight} act={() => preservation(3, props.API.SetWeight)} />
          </div>

          <div className="col-12 rounded-3 border bg-white p-3">
            <p className=" display-6">{TEXTJSON.ROST}</p>
            <InputText isLoad={props.isLoadArray[4]} setItem={props.setHeight} inputValue={props.height} act={() => preservation(4, props.API.SetHeight)} />
          </div>

          {/*  */}
          <div className="col-12 rounded-3 border bg-white p-3">
            <p className=" display-6">{TEXTJSON.RODNOJ_GOROD}</p>
            <InputSelectCity optionArray={props.cities} isLoad={props.isLoadArray[9]} setItem={props.setCity} inputValue={props.city && props.cityId} act={() => preservation(9, props.API.SetCity)} />
          </div>
          {/*  */}

          <div className="col-12 rounded-3 border bg-white p-3">
            <p className=" display-6">{TEXTJSON.O_DOSTIZHENIYAH}</p>
            <InputTextarea isLoad={props.isLoadArray[5]} setItem={props.setAbout} inputValue={props.about} act={() => preservation(5, props.API.SetAbout)} />
          </div>
          {/*  */}
          <div className="col-12  d-flex flex-column p-3">
            <button type="button" className={"btn btn-primary btn-sm p-1  rounded-pill  ms-1 btn-" + (props.isLoadArray[10] ? "secondary" : "primary")} onClick={() => saveAllChanges(10)} disabled={props.isLoadArray[10] ? true : false}>
              {props.isLoadArray[10] ? (
                <div className="spinner-border spinner-border-sm text-light " role="status">
                  <span className="visually-hidden ">{TEXTJSON.ZAGRUZKA}</span>
                </div>
              ) : (
                // <img alt="" className=" " width="20" src="/images/icons/save.svg"></img>
                <div className=" ">{TEXTJSON.SOHRANIT_VSE}</div>
              )}
            </button>
          </div>
          {/*  */}
          <div className="col-12  p-5">
            <hr />
          </div>
          <div className="col-12 rounded-3 border bg-white p-3">
            <p className="display-6">{TEXTJSON.SOC_SETI}</p>
            <SocialMediaConteiner passedId={props.id} />
          </div>

          <div className="col-12  ">
            <div className="d-flex flex-row flex-wrap align-items-center justify-content-center">
              <button className="btn btn-outline-danger rounded-pill m-1" onClick={() => exitFromAccaunt()}>
                {TEXTJSON.pokinut_akaunt}
              </button>
              <button className="btn  btn-danger rounded-pill  m-1" onClick={() => deleteBtn(props.id)}>
                {TEXTJSON.UDALIT_AKAUNT}
              </button>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default ProfileEdit;
