import React from "react";
import Footer from "./content/Footer";
import Header from "./content/Header";
import EmailPage from "./pages/EmailPage";
import CodePage from "./pages/CodePage";
import NewPasswordPage from "./pages/NewPasswordPage";
import PasswordPage from "./pages/PasswordPage";
import FinishPage from "./pages/FinishPage";
//**************************************************************************************************************
function Modal(props) {
  //**************************************************************************************************************
  let PageInBody;
  switch (props.currentPage) {
    case "email":
      PageInBody = EmailPage;
      break;
    case "code":
      PageInBody = CodePage;
      break;
    case "new-password":
      PageInBody = NewPasswordPage;
      break;
    case "password":
      PageInBody = PasswordPage;
      break;
    case "finish":
      PageInBody = FinishPage;
      break;
    default:
      PageInBody = () => <></>;
      break;
  }
  //**************************************************************************************************************
  return (
    <div className="modal fade " id="authModal" tabIndex="-1" aria-labelledby="authModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <Header />
          <div className="modal-body  ">
            <PageInBody {...props} />
          </div>
          <Footer {...props} />
        </div>
      </div>
    </div>
  );
}
//**************************************************************************************************************
export default Modal;
