import React from "react";
import Preloader from "../../../common/preloaders/Preloader";
import TEXTJSON from "../../../../data/text.json";
function AddItem(props) {
  //
  let sendPriceListItem = () => {
    props.setIsAdded(true);
    Promise.all([props.API.AddToPriceList()]).then((results) => {
      props.setIsAdded(false);
      console.log(results[0].data);
      if (results[0].data === "successfully") { 
         props.setIsClubPriceListFetching(true);
        Promise.all([props.API.GetPriceList()]).then((results) => {
          if (results[0].data.priceList) props.setClubPriceList(results[0].data.priceList);
          props.setIsClubPriceListFetching(false);
        });
      }
    });
  };
  //
  return (
    <div className=" container-fluid   my-2 ">
      <div className="row gy-3 row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">
        {/*  */}
        <div className="col">
          <label htmlFor="inputEmail4" className="form-label">
         {TEXTJSON.VID_SPORTA} 
          </label>
          <select
            className="form-select "
            onChange={(e) => {
              props.setNewSportTypeId(e.target.options[e.target.options.selectedIndex].value);
            }}
          >
            <option selected> {TEXTJSON.VYBERI_EDINABOSTVO} </option>
            {props.sportTypes.length === 0 ? (
              <option>{TEXTJSON.ZAGRUZKA}</option>
            ) : (
              props.sportTypes.map((sp, index) => (
                <option id={sp.id} value={sp.id}>
                  {sp.name}
                </option>
              ))
            )}
          </select>
        </div>
        {/*  */}
        <div className="col">
          <label htmlFor="inputAddToClubTime" className="form-label">
          {TEXTJSON.CENA}
          </label>
          <input className="form-control " value={props.newPrice} type="number" onChange={(e) => props.setNewPrice(e.target.value)} id="inputAddToClubTime" />
        </div>
        {/*  */}
        <div className="col-12">
          <button className="btn btn-primary rounded-pill" onClick={() => sendPriceListItem()}>
            {props.isAdded ? <Preloader /> : TEXTJSON.DOBAVIT}
          </button>
        </div>
      </div>
    </div>
  );
}
export default AddItem;
