import React from "react";
import { connect } from "react-redux";
import Auth from "./Auth";

import { setNewObjects, setInputEmail, setInputCode, setInputPassword, setInputConfirmationPassword, setErrorMessage, setCurrentPage, setIsAuthorized, setIsFetching } from "../../redux/auth-reducer";
import * as axios from "axios";

class AuthAPIComponent extends React.Component {
  componentDidMount() {
    Promise.all([this.checkToken()]).then((results) => {
      if (results[0] !== "error") {
        this.props.setNewObjects(results[0].data);
      }
    });
  }

  checkEmail = () => {
    return axios
      .post("/Account/CreatesUserByEmailIfItDoesntExist", {
        email: this.props.inputEmail,
      })
      .then((response) => response)
      .catch((error) => error.response);
  };

  checkCode = () => {
    return axios
      .post("/Account/CheckIsCodeCorrect", {
        email: this.props.inputEmail,
        code: this.props.inputCode,
      })
      .then((response) => response)
      .catch((error) => error.response);
  };

  createPassword = () => {
    return axios
      .post("/Account/SetPassword", {
        email: this.props.inputEmail,
        Password: this.props.inputPassword,
        code: this.props.inputCode,
      })
      .then((response) => response)
      .catch((error) => error.response);
  };

  getToken = () => {
    return axios
      .post("/Account/Token", {
        email: this.props.inputEmail,
        Password: this.props.inputPassword,
      })
      .then((response) => response)
      .catch((error) => error.response);
  };

  checkToken = () => {
    return axios({
      method: "GET",
      url: "/Account/TestToken",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
      },
    })
      .then((response) => response)
      .catch((error) => "error");
  };

  mySetTimeout = () => {
    let that = this;
    setTimeout(function () {
      if (that.props.isAdmin) {
        window.location.href = "/admin";
      } else {
        window.location.href = "/ProfileEdit";
      }
    }, 1 * 1000);
  };

  render() {
    return (
      <>
        <Auth {...this.props} mySetTimeout={this.mySetTimeout} checkEmail={this.checkEmail} checkToken={this.checkToken} checkCode={this.checkCode} createPassword={this.createPassword} getToken={this.getToken} />
      </>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    inputEmail: state.auth.inputEmail,
    inputCode: state.auth.inputCode,
    inputPassword: state.auth.inputPassword,
    inputConfirmationPassword: state.auth.inputConfirmationPassword,
    errorMessage: state.auth.errorMessage,
    currentPage: state.auth.currentPage,

    isAuthorized: state.auth.isAuthorized,
    clubCoachesIdArray: state.auth.clubCoachesIdArray,
    isCoach: state.auth.isCoach,
    isAdmin: state.auth.isAdmin,

    isFetching: state.auth.isFetching,
  };
};

const AuthConteiner = connect(mapStateToProps, {
  setNewObjects,
  setInputEmail,
  setInputCode,
  setInputPassword,
  setInputConfirmationPassword,
  setErrorMessage,
  setCurrentPage,
  setIsAuthorized,
  setIsFetching,
})(AuthAPIComponent);

export default AuthConteiner;
