import React from 'react' 

let Pagination = (props) => {
  let pagNumArray = []
  let pagesCount = Math.ceil(props.totalProductsCount / props.pageSize)

  for (let index = 1; index < pagesCount; index++) {
    pagNumArray[index - 1] = index
  }
  return (
    <>
      <div className="d-flex flex-row flex-wrap justify-content-center align-items-center ">
        {/* Array(10).fill().map((e, i) => i + 1) */}

        {pagNumArray.map((n) => {
          return (
            <button
              className={
                'btn m-1 btn-sm  ' +
                (props.currentPage === n
                  ? 'btn-outline-primary'
                  : ' btn-primary ')
              }
              onClick={() => props.onPageChanged(n)}
            >
              {n}
            </button>
          )
        })}
      </div>
    </>
  )
}
export default Pagination
