import React from "react";
import { NavLink } from "react-router-dom";
var options = {
  year: "numeric",
  month: "long",
  day: "numeric",
  // weekday: 'long',
  timezone: "UTC",
};
let Item = (props) => {
  return (
    <NavLink className="text-decoration-none link-dark" to={{ pathname: props.forNavLink + "/" + props.name, data: props.name }}>
      <div className="card border-0 shadow-lg">
        <div className={`  rounded-top myImg   w-100 ${props.srcViewImg && "my-article-item-size"}`} style={{ backgroundImage: `url(/images/icons/metaball-loader.gif)` }}>
        <div className={`  rounded-top myImgv2   w-100 ${props.srcViewImg && "my-article-item-size"}`} style={{ backgroundImage: `url(${props.srcViewImg})` }}>
          
          </div>
        </div>
        <div className="card-body">
          <h5 className="card-title">{props.title}</h5>
          <p className="card-text text-black-50">{new Date(props.date).toLocaleString("ru", options)}</p>
        </div>
      </div>
    </NavLink>
  );
};

export default Item; 
