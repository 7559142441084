let initialState = {
  cities: [],

  isBasicInfoFetching: false,

  isUpdating: {
    city: false,
    phoneNumber: false,
    email: false,
    address: false,
  },

  city: '',
  phoneNumber: null,
  email: '',
  address: '',

  isEditMode: false,
}

const clubBasicInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SN/CLUB-EDIT/SET-CITIES':
      return { ...state, cities: action.value }

    case 'SN/CLUB-EDIT/SET-PHONENUMBER':
      return { ...state, phoneNumber: action.value }

    case 'SN/CLUB-EDIT/SET-EMAIL':
      return { ...state, email: action.value }

    case 'SN/CLUB-EDIT/SET-ADDRESS':
      return { ...state, address: action.value }

    case 'SN/CLUB-EDIT/SET-CITY':
      return { ...state, city: action.value }

    case 'SN/CLUB-EDIT/SET-ISEDITMODE':
      return { ...state, isEditMode: action.value }

    case 'SN/CLUB-EDIT/SET-ISBASICINFOFETCHING':
      return { ...state, isBasicInfoFetching: action.value }

    case 'SN/CLUB-EDIT/SET-ISUPDATING':
      let iu = state.isUpdating;
      console.log(iu)
      if (action.updatingObj === 'city')return { ...state, isUpdating: {...state.isUpdating,city:action.value} }
      if (action.updatingObj === 'phoneNumber') return { ...state, isUpdating: {...state.isUpdating,phoneNumber:action.value} }
      if (action.updatingObj === 'email') return { ...state, isUpdating: {...state.isUpdating,email:action.value} }
      if (action.updatingObj === 'address') return { ...state, isUpdating: {...state.isUpdating,address:action.value} }
      
      return { ...state }

    default:
      return state
  }
}

export const setCities = (value) => ({
  type: 'SN/CLUB-EDIT/SET-CITIES',
  value,
})

export const setPhoneNumber = (value) => ({
  type: 'SN/CLUB-EDIT/SET-PHONENUMBER',
  value,
})

export const setEmail = (value) => ({
  type: 'SN/CLUB-EDIT/SET-EMAIL',
  value,
})

export const setAddress = (value) => ({
  type: 'SN/CLUB-EDIT/SET-ADDRESS',
  value,
})

export const setCity = (value) => ({
  type: 'SN/CLUB-EDIT/SET-CITY',
  value,
})

export const setIsEditMode = (value) => ({
  type: 'SN/CLUB-EDIT/SET-ISEDITMODE',
  value,
})

export const setIsBasicInfoFetching = (value) => ({
  type: 'SN/CLUB-EDIT/SET-ISBASICINFOFETCHING',
  value,
})


export const setIsUpdating = (updatingObj,value) => ({
  type: 'SN/CLUB-EDIT/SET-ISUPDATING',
  updatingObj,
  value,
})

export default clubBasicInfoReducer
