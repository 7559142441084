/************************************************************************************* */
let initialState = {
  articles: [],
  //_____________
isPaginationLoad:false,
  isFetching: true,
  //_____________
  pageSize: 8,
  totalProductsCount: 0,
  currentPage: 1,
};
/************************************************************************************* */
const newsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SN/NEWS-LIST-REDUCER/SET-ARTICLES":
      return { ...state, articles: action.value };

    case "SN/NEWS-LIST-REDUCER/SET-ISFETCHING":
      return { ...state, isFetching: action.value };

      case "SN/NEWS-LIST-REDUCER/SET-ISPAGINATIONLOAD":
      return { ...state, isPaginationLoad: action.value };

    case "SN/NEWS-LIST-REDUCER/SET-TOTAL-PRODUCTS-COUNT":
      return { ...state, totalProductsCount: action.value };
    case "SN/NEWS-LIST-REDUCER/SET-CURRENT-PAGE":
      return { ...state, currentPage: action.value };

    default:
      return state;
  }
};
/************************************************************************************* */
export const setArticles = (value) => ({
  type: "SN/NEWS-LIST-REDUCER/SET-ARTICLES",
  value,
});

export const setIsFetching = (value) => ({
  type: "SN/NEWS-LIST-REDUCER/SET-ISFETCHING",
  value,
});


export const setIsPaginationLoad = (value) => ({
    type: "SN/NEWS-LIST-REDUCER/SET-ISPAGINATIONLOAD",
    value,
  });
  
export const setCurrentPage = (value) => ({
  type: "SN/NEWS-LIST-REDUCER/SET-CURRENT-PAGE",
  value,
});
export const setTotalProductsCount = (value) => ({
  type: "SN/NEWS-LIST-REDUCER/SET-TOTAL-PRODUCTS-COUNT",
  value,
});

/************************************************************************************* */
export default newsListReducer;
