import React from "react";
import Preloader from "../../../common/preloaders/Preloader";
// ============================================================
let InputPhoneNum = (props) => {
  // ============================================================
  let save = () => {
    props.setIsUpdating("phoneNumber",true);
    Promise.all([props.API.UpdatePhoneNumber()]).then((results) => {
      props.setIsUpdating("phoneNumber",false);
      Promise.all([  props.API.GetBasicInfo()]).then((results) => {
        //
      });
    });
  };
  // ============================================================
  let isPhoneNull = props.phoneNumber ? true : false;
  if (!isPhoneNull&&!props.isEditMode  ) return <></>;
  // ============================================================
  return (
    <>
        <div className="  d-flex align-items-center   ">
          <img alt="" className="me-2" width="20" src="/images/icons/phone.svg" />
          {props.isEditMode ? (
              <div className="input-group      ">
                <span className="input-group-text" id="addon-wrapping">+7</span>
                <input value={props.phoneNumber} onChange={(event) => props.setPhoneNumber(event.target.value)} type="text" className="form-control   " />
                <button onClick={() => save()} className="btn  btn-primary" type="button" id="button-addon2">
                  {props.isUpdating.phoneNumber?<Preloader preloaderColor={"white"}/>:
                  <img alt="" height="20" src="/images/icons/save.svg" />
                  }
                </button>
              </div>
          ) : (
            <p className=" m-0 fw-bold ">+7{props.phoneNumber}</p>
          )}
        </div>
    </>
  );
  // ============================================================
};
// ============================================================
export default InputPhoneNum;
