import React from "react";
import { connect } from "react-redux";
import InteractionButtonsArea from "./InteractionButtonsArea";
import { withRouter } from "react-router-dom";
import { setFigterStatus, setIsFigterStatusFetching,setCoachStatus,setIsCoachStatusFetching } from "../../../redux/club-interactionButtonsArea-reducer";
import * as axios from "axios";

// ===========================================================================

class InteractionButtonsAreaComponent extends React.Component {
  componentDidMount() {
    this.props.setFigterStatus("load");
    this.props.setCoachStatus("load");
    Promise.all([this.API.GetFighterStatusInClub(),this.API.GetCoachStatusInClub()]).then((results) => {
      this.props.setFigterStatus(results[0].data);
      this.props.setCoachStatus(results[1].data);
    });
  }

  // ===========================================================================

  API = {
    //   GET   GET   GET   GET   GET   GET   GET   GET   GET   GET
    GetFighterStatusInClub: () => {
      return axios({
        method: "GET",
        url: "/club/GetFighterStatusInClub/" + this.props.match.params.clubId,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    // ADD-TO    ADD-TO    ADD-TO    ADD-TO    ADD-TO    ADD-TO    ADD-TO
    AddToFighters: () => {
      return axios({
        method: "PUT",
        url: "/club/AddToFighters",
        data: {
          clubId: this.props.match.params.clubId,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    //  DELETE    DELETE    DELETE    DELETE    DELETE    DELETE    DELETE
    LeaveClub: (clubImageId) => {
      return axios({
        method: "PUT",
        url: "/club/LeaveClub",
        data: {
          clubId: this.props.match.params.clubId,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },

    // ╔════╗╔═══╗╔═══╗╔╗╔╗╔═══╗╔═══╗
    // ╚═╗╔═╝║╔═╗║║╔══╝║║║║║╔══╝║╔═╗║
    // ──║║──║╚═╝║║╚══╗║╚╝║║╚══╗║╚═╝║
    // ──║║──║╔══╝║╔══╝║╔╗║║╔══╝║╔══╝
    // ──║║──║║───║╚══╗║║║║║╚══╗║║
    // ──╚╝──╚╝───╚═══╝╚╝╚╝╚═══╝╚╝
    //   GET   GET   GET   GET   GET   GET   GET   GET   GET   GET
    GetCoachStatusInClub: () => {
      return axios({
        method: "GET",
        url: "/club/GetCoachStatusInClub/" + this.props.match.params.clubId,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    // ADD-TO    ADD-TO    ADD-TO    ADD-TO    ADD-TO    ADD-TO    ADD-TO
    AddToCoaches: () => {
      return axios({
        method: "PUT",
        url: "/club/AddToCoaches",
        data: {
          clubId: this.props.match.params.clubId,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    //  DELETE    DELETE    DELETE    DELETE    DELETE    DELETE    DELETE
    LeaveWork: (clubImageId) => {
      return axios({
        method: "PUT",
        url: "/club/LeaveWork",
        data: {
          clubId: this.props.match.params.clubId,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
  };

  // ===========================================================================

  render() { 
    return (
      <>
        <InteractionButtonsArea {...this.props} API={this.API} />
      </>
    );
  }
}

// ===========================================================================

let mapStateToProps = (state) => {
  return {
    figterStatus: state.clubInteractionButtonsArea.figterStatus,
    isFigterStatusFetching: state.clubInteractionButtonsArea.isFigterStatusFetching,
    coachStatus: state.clubInteractionButtonsArea.coachStatus,
    isCoachStatusFetching: state.clubInteractionButtonsArea.isCoachStatusFetching,
    // _____________________________________
    isAuthorized: state.auth.isAuthorized,
    clubCoachesIdArray: state.auth.clubCoachesIdArray,
    isCoach: state.auth.isCoach,
    isAdmin: state.auth.isAdmin,
  };
};

// ===========================================================================

let withURLDataComponentContainer = withRouter(InteractionButtonsAreaComponent);

// ===========================================================================

const InteractionButtonsAreaConteiner = connect(mapStateToProps, {
  setFigterStatus,
  setIsFigterStatusFetching,setCoachStatus,setIsCoachStatusFetching
})(withURLDataComponentContainer);

// ===========================================================================

export default InteractionButtonsAreaConteiner;
