import React from "react";

let Select = (props) => {
  
  return (
    <div className="   p-2 d-flex flex-row justify-content-center align-items-center">
      <div className="me-3   text-nowrap">{props.lable}</div>

      <select
        className="form-select "
        onChange={(e) => {
          console.log(props.typeFotMethod,e.target.value)
          props.setItem( e.target.value,props.typeFotMethod);
        }}
      >
        {props.optionArray.map((item) => (
          <option id={item.value} selected={item.isSelect} value={item.value} className="text-lowercase">
            {item.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
