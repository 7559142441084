import React from "react";
import TEXTJSON from "../../../data/text.json";
let InputSelectCityOrder = (props) => {
  
  return (
    <div className="   p-2 d-flex flex-row justify-content-center align-items-center">
      <div className="me-3   text-nowrap">{props.lable}</div>

      <select
        className="form-select "
        onChange={(e) => {
          console.log(props.typeFotMethod,e.target.value)
          props.setItem( e.target.value,props.typeFotMethod);
        }}
      >
           <option value="0" selected>{TEXTJSON.VSE}</option>
        {props.optionArray.map((item) => (
          <option id={item.id}   value={item.id} className="text-lowercase">
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default InputSelectCityOrder;

