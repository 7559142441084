import React from "react";
import TEXTJSON from "../../../../data/text.json";
let ListBtnDelete = (props) => {
  return (
      <button onClick={()=>props.act(props.userId)} type="button" className="btn  rounded-pill  btn-danger">
{TEXTJSON.delete} 
      </button>
  );
};
export default ListBtnDelete;
