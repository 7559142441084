import React from "react";
import { connect } from "react-redux";

import { setArticles, setCurrentPage, setTotalProductsCount, setIsFetching ,setIsPaginationLoad} from "../../redux/news-list-reducer";
import * as axios from "axios";
import NewsList from "./NewsList";
import BigPreloader from "../common/preloaders/BigPreloader";
import BigTextAlert from "../common/BigTextAlert/BigTextAlert";
// ================================================================================
class NewsListAPIComponent extends React.Component {
  // ================================================================================
  componentDidMount() {
    this.props.setIsFetching(true);
    this.props.setCurrentPage(1);
    Promise.all([this.API.TakeMany()]).then((results) => {
      this.props.setArticles(results[0].data.articles);
      this.props.setTotalProductsCount(results[0].data.count)
      this.props.setIsFetching(false);
    });
  }
  // ================================================================================
  API = {
    TakeMany: () => {
      console.log("/api/get/article/TakeMany?page=" + this.props.currentPage + "&pageItemCount=" + this.props.pageSize);
      return axios({
        method: "GET",
        url: "/api/get/article/TakeMany?page=" + this.props.currentPage + "&pageItemCount=" + this.props.pageSize,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    // ___________
    DeleteUser: (articleId) => {
      return axios({
        method: 'delete',
        url: '/api/delete/article/DeleteArticle/' + articleId,
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('accessToken'),
        },
      })
        .then((response) => response)
        .catch((error) => error.response)
    },
  };
  // ================================================================================
  render() {
    return <>{this.props.isFetching ? <BigPreloader preloaderColor="danger" /> : <NewsList {...this.props} API={this.API}  />}</>;
  }
}
// ================================================================================
let mapStateToProps = (state) => {
  return {
    articles: state.newsList.articles,
    isFetching: state.newsList.isFetching,
    pageSize: state.newsList.pageSize,
    totalProductsCount: state.newsList.totalProductsCount,
    currentPage: state.newsList.currentPage,
    isPaginationLoad: state.newsList.isPaginationLoad,

    isAuthorized: state.auth.isAuthorized,
    clubCoachesIdArray: state.auth.clubCoachesIdArray, 
    isCoach: state.auth.isCoach,
    isAdmin: state.auth.isAdmin,
  };
};
// ================================================================================
const NewsListConteiner = connect(mapStateToProps, {
  setArticles,
  setCurrentPage,
  setTotalProductsCount,
  setIsFetching, setIsPaginationLoad
})(NewsListAPIComponent);
// ================================================================================
export default NewsListConteiner;
