import React from "react";
import TEXTJSON from "../../../data/text.json";
let Manager = (props) => {
  let setNewPhotoDataUrlFromInputFile = (iputFile) => {
    var file = iputFile.target.files[0];

    var reader = new FileReader();

    reader.onloadend = function () {
      props.setNewTournament("imageDataUrl", reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      props.setNewTournament("imageDataUrl", "");
    }
  };

  let sendReauest = () => {
    props.setIsSended(true)
    Promise.all([props.API.CreateTournament()]).then((results) => {
        props.setIsSended(false)
      if (results[0].data === "successfully") {
        window.alert(TEXTJSON.TURNIR_DOBAVLEN.toUpperCase());
        props.setIsFetching(true);
        Promise.all([props.API.GetList()]).then((results) => {
          props.setTournaments(results[0].data);
          props.setIsFetching(false);
          console.log(results[0].data);
        });
      } else if (results[0].data ==="parametrs_empty"){
        window.alert(TEXTJSON.ZAPOLNITE_VSE_POLYA.toUpperCase());
      } else{
          console.log(results[0].data);
          window.alert(TEXTJSON.universal_error.toUpperCase())}
    });
  };

  return (
    <>
      <p>
        <button className="btn btn-dark  rounded-pill" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
    {TEXTJSON.DOBAVIT_TURNIR}
        </button>
      </p>
      <div className="collapse" id="collapseExample">
        <div className="card card-body border-dark">
          <div className="d-flex flex-column">
            <label htmlFor="turFile" className="form-label ">
            {TEXTJSON.IZOBRAZHENIE}      
            </label>
            {props.newTournament.imageDataUrl && <img src={props.newTournament.imageDataUrl} className="mb-3" width="200" alt="" />}
            <input onChange={(e) => setNewPhotoDataUrlFromInputFile(e)} className="form-control" type="file" id="turFile" />

            <label htmlFor="turName" className="form-label mt-3 ">
            {TEXTJSON.NAZVANIE_TURNIRA}
            </label>
            <input onChange={(e) => props.setNewTournament("name", e.target.value)} value={props.newTournament.name} id="turName" className="form-control rounded-pill" type="text" />

            <label htmlFor="turTime" className="form-label mt-3">
            {TEXTJSON.VREMYA_TURNIRA}     
            </label>
            <input onChange={(e) => props.setNewTournament("dateTime", e.target.value)} value={props.newTournament.dateTime} id="turTime" className="form-control rounded-pill" type="datetime-local" />

            <label htmlFor="turTime" className="form-label mt-3">
            {TEXTJSON.SSYLKA_NA_POKUPKU_BILETASSYLKA_NA_POKUPKU_BILETA}
            </label>
            <input onChange={(e) => props.setNewTournament("linkToBuyTicket", e.target.value)} value={props.newTournament.linkToBuyTicket} id="turTime" className="form-control rounded-pill" type="text" />

            <label htmlFor="turTime" className="form-label mt-3">
            {TEXTJSON.GOROD}     
            </label>
            <input onChange={(e) => props.setNewTournament("cityName", e.target.value)} value={props.newTournament.cityName} id="turTime" className="form-control rounded-pill" type="text" />

            <label htmlFor="turTime" className="form-label mt-3">
            {TEXTJSON.PODROBNOSTI}     
            </label>
            <textarea onChange={(e) => props.setNewTournament("article", e.target.value)} value={props.newTournament.article} id="turTime" className="form-control  " rows="4" />
            <button  onClick={()=>sendReauest()} className={"btn btn-lg    rounded-pill mt-5 btn-"+(props.isSended?"secondary":"success")}>{props.isSended? TEXTJSON.ZAGRUZKA : TEXTJSON.DOBAVIT  }</button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Manager;
