import React from "react";
import Modal from "../Auth/Modal/Modal"; 
import ModalBtn from "./ModalBtn/ModalBtn";
import NavLinkToAdmin from "./NavLinkToAdmin/NavLinkToAdmin";
import NavLinkToPersonalAccount from "./NavLinkToPersonalAccount/NavLinkToPersonalAccount";
import TEXTJSON from "../../data/text.json";

function Auth(props) {
  let ModalButton;
  if (!props.isAuthorized ) {
    ModalButton = () => <div className="spinner-border spinner-border-sm text-white d-flex align-items-center justify-content-center" role="status">
    <span className="visually-hidden  ">{TEXTJSON.ZAGRUZKA}</span>
  </div>;
  }
  if (!props.isAuthorized ) {
    ModalButton = () => <ModalBtn  />;
  }
  if (props.isAuthorized  ) {
    ModalButton = () => <NavLinkToPersonalAccount   />;
  }
  if (props.isAdmin ) {
    ModalButton = () => <NavLinkToAdmin   />;
  }



  return (
    <>
       <ModalButton /> 
      <Modal {...props} />
    </>
  );
}

export default Auth;
