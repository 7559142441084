import React from "react";
import { connect } from "react-redux";
import ClubFighters from "./ClubFighters";
import { withRouter } from "react-router-dom";
import { setFighters, setIsFething, setCurrentPage, setTotalProductsCount,setCoaches } from "../../redux/clubFighters-reducer";
import * as axios from "axios";
import BtnPreloader from "../common/preloaders/BtnPreloader";

// ===========================================================================

class ClubFightersAPIComponent extends React.Component {
  componentDidMount() {
    console.log("fefe")
    this.props.setIsFething(true);

    Promise.all([this.API.GetApprovedFighter()]).then((results) => {
      this.props.setFighters(results[0].data.users);
      
    });

    Promise.all([this.API.GetApprovedCoaches()]).then((results) => {  
      this.props.setCoaches(results[0].data.users);
      this.props.setIsFething(false);
    });
  }

  // ===========================================================================

  API = {
    //   GET   GET   GET   GET   GET   GET   GET   GET   GET   GET
    GetApprovedFighter: () => {
      return axios({
        method: "GET",
        url: "/club/GetApprovedFighter?clubId=" + this.props.match.params.clubId,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },

    GetApprovedCoaches: () => {
      return axios({
        method: "GET",
        url: "/club/GetApprovedCoaches?clubId=" + this.props.match.params.clubId,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },

    // UPDATE    UPDATE    UPDATE    UPDATE    UPDATE    UPDATE    UPDATE

    ChangeProStatus: (fighterId) => {
      return axios({
        method: "PUT",
        url: "/api/put/user/ChangeProStatus",
        data: {
          clubId: this.props.match.params.clubId,
          UserId: fighterId,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    ChangeCoach: (fighterId,newCoachId) => {
      console.log(fighterId,newCoachId)
      return axios({
        method: "PUT",
        url: "/api/put/user/ChangeCoach",
        data: {
          CoachId:newCoachId,
          UserId: fighterId,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    // DELETE   DELETE   DELETE   DELETE   DELETE   DELETE   DELETE   DELETE
    DeleteClubFighter: (fighterId) => {
      return axios({
        method: "PUT",
        url: "/club/DeleteClubFighter",
        data: {
          clubId: this.props.match.params.clubId,
          FighterId: fighterId,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
  };

  // ===========================================================================

  render() {
    
    return <>{this.props.IsFetching ? <BtnPreloader preloaderColor="dark" /> : <ClubFighters {...this.props} API={this.API} />}</>;
  }
}

// ===========================================================================

let mapStateToProps = (state) => {
  return {
    fighters: state.clubFighters.fighters,

    IsFetching: state.clubFighters.IsFetching,
    //_____________
    pageSize: state.clubFighters.pageSize,
    TotalProductsCount: state.clubFighters.TotalProductsCount,
    currentPage: state.clubFighters.currentPage,

    // _____________________________________
    isAuthorized: state.auth.isAuthorized,

    coaches: state.clubFighters.coaches,
  };
};

// ===========================================================================

let withURLDataComponentContainer = withRouter(ClubFightersAPIComponent);

// ===========================================================================

const ClubFightersConteiner = connect(mapStateToProps, {
  setFighters,
  setIsFething,
  setCurrentPage,
  setTotalProductsCount,
  setCoaches
})(withURLDataComponentContainer);

// ===========================================================================

export default ClubFightersConteiner;
