import React from "react";
import { NavLink } from "react-router-dom";
import InputText from "../common/InputText/InputText";
import NextSpanIcon from "../common/NextSpanIcon/NextSpanIcon";
import TEXTJSON from "../../data/text.json";

function Admin(props) {
  let sendRequest = (type, btnIndex) => {
    props.setBtnsLoadingStatus(true, btnIndex);
    Promise.all([props.API.SetJsonSiteConfig(type, props[String(type)])]).then((results) => {
      props.setBtnsLoadingStatus(false, btnIndex);
      if (results[0].data === "error") window.alert(TEXTJSON.universal_error);
    });
    console.log(props[type]);
  };

  const exitFromAccaunt = () => {
    sessionStorage.removeItem("accessToken");
    window.location.reload();
  };
  
  return (
    <article>
      <div className="container">
        <div className="row gy-3 justify-content-center">
          <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
            <div className="display-6">
              {TEXTJSON.administraciya}
            </div>
          </div>
          <div className="col-12 "></div>
          <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 ">
            <div className="d-flex flex-column  align-items-start">
              <NavLink to="/adminuserlist" className="btn btn-link text-decoration-none">
                {TEXTJSON.spisok_polzovatelej}
                <NextSpanIcon />
              </NavLink>
              
            </div>
          </div>
          <div className="col-12 "></div>

          <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 ">
            <div className="d-flex flex-column  align-items-start">
              <div>
                <InputText isLoad={props.btnsLoadingStatus[0]} act={() => sendRequest("phone", 0)} setItem={props.setPhone} inputValue={props.phone} lableImg="/images/icons/phone.svg" />
              </div>
              <div className="mt-3">
                <InputText isLoad={props.btnsLoadingStatus[1]} act={() => sendRequest("email", 1)} setItem={props.setEmail} inputValue={props.email} lableImg="/images/icons/email.svg" />
              </div>
            </div>
          </div>
          <div className="col-12 "></div>

          <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 ">
            <div className="d-flex flex-column  align-items-start  ">
              <div> 
                <hr />
                <InputText isLoad={props.btnsLoadingStatus[2]} act={() => sendRequest("facebook", 2)} setItem={props.setFacebook} inputValue={props.facebook} lableImg="/images/social/facebook.svg" />
              </div>
              <div className="mt-3">
                <InputText isLoad={props.btnsLoadingStatus[3]} act={() => sendRequest("instagram", 3)} setItem={props.setInstagram} inputValue={props.instagram} lableImg="/images/social/instagram.svg" />
              </div>
              <div className="mt-3">
                <InputText isLoad={props.btnsLoadingStatus[4]} act={() => sendRequest("telegram", 4)} setItem={props.setTelegram} inputValue={props.telegram} lableImg="/images/social/telegram.svg" />
              </div>
              <div className="mt-3">
                <InputText isLoad={props.btnsLoadingStatus[5]} act={() => sendRequest("vk", 5)} setItem={props.setVk} inputValue={props.vk} lableImg="/images/social/vk.svg" />
              </div>
              <div className="mt-3">
                <InputText isLoad={props.btnsLoadingStatus[6]} act={() => sendRequest("youtube", 6)} setItem={props.setYoutube} inputValue={props.youTube} lableImg="/images/social/youTube.svg" />
              </div>
              <div className="mt-3">
                <InputText isLoad={props.btnsLoadingStatus[7]} act={() => sendRequest("odnoklassniki", 7)} setItem={props.setOdnoklassniki} inputValue={props.odnoklassniki} lableImg="/images/social/odnoklassniki.svg" />
                <hr />
              </div>
            </div>
          </div>
          <div className="col-12 "></div>
          <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 ">
            
            <button className="btn btn-outline-danger rounded-pill m-1" onClick={() => exitFromAccaunt()}>
              {TEXTJSON.pokinut_akaunt}
            </button>
          </div>
        </div>
      </div>
    </article>
  );
}

export default Admin;
