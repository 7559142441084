let GetUrlCreater = (parhBase, parametrsAndValues) => {
  let str = parhBase
  str = str + '?'

  parametrsAndValues.forEach((element) => { 
    if (Object.values(element)[0]) {
       
      str = `${str}${Object.keys(element)[0]}=${Object.values(element)[0]}&`
    }
  })
  console.log(str,"00000")
  return str
}
export default GetUrlCreater
