import React from "react";
import BigPreloader from "../../common/preloaders/BigPreloader";
import AddTraining from "./components/AddTraining";
import DayOfWeek from "./components/DayOfWeek";
import SwitchIsEditMode from "./components/SwitchIsEditMode";
import TEXTJSON from "../../../data/text.json"; 

function Schedule(props) {
  
  return (
    <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12   "+(props.isEditMode ? " " : "")}>
      <SwitchIsEditMode {...props} />
      {props.isScheduleFetching ? (
        <div className="col-12   p-5    rounded-3"> 
          <BigPreloader preloaderColor={"white"} />{" "}
        </div>
      ) : (
        <div className="container-fluid p-0">
          <div className="row g-3 row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2  ">
            {[TEXTJSON.PONEDELNIK,TEXTJSON.VTORNIK,TEXTJSON.SREDA,TEXTJSON.CHETVEG,TEXTJSON.PATUSA,TEXTJSON.SUBOTA,TEXTJSON.VOSKRESENIYE].map((d, i) => (
              <DayOfWeek key={`DayOfWeek${i}`} {...props} dayTurn={i} dayName={d} />
            ))}
          </div>
        </div>
      )}

      {!props.isEditMode ? <></> : <AddTraining {...props} />}
    </div>
  );
}

export default Schedule;
