let initialState = {
  fighters: [],

  IsFetching:true,
  //_____________
  pageSize: 3,
  TotalProductsCount: 0,
  currentPage: 1,
}

const clubFighterRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SN/CLUB-EDIT/SET-FIGHTERS':
      return { ...state, fighters: action.value }

      case "SET-IS-FETHING":
        return { ...state, IsFetching: action.value } 

      case "SET-TOTAL-PRODUCTS-COUNT":
        return { ...state, TotalProductsCount: action.value }
      case "SET-CURRENT-PAGE":
        return { ...state, currentPage: action.value }
   

    default:
      return state
  }
}

export const setFighters = (value) => ({
  type: 'SN/CLUB-EDIT/SET-FIGHTERS',
  value,
})

export const setIsFething = (value) => ({ type: "SET-IS-FETHING", value });

export const setCurrentPage = (value) => ({ type: "SET-CURRENT-PAGE", value });
export const setTotalProductsCount = (value) => ({ type: "SET-TOTAL-PRODUCTS-COUNT",   value });

export default clubFighterRequestsReducer
