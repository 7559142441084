import React from 'react'
import List from './components/List'
import TEXTJSON from "../../../data/text.json";
let UserList = (props) => {
  let acts = {
    changeRating: props.changeRatingAct,
    approve: props.approveAct,
    reject: props.rejectAct,
    changeStatus: !props.changeStatusAct   ? null : props.changeStatusAct,
    delete: !props.deleteAct   ? null : props.deleteAct,
    setNewUserTrainer:!props.setNewUserTrainerAct   ? null : props.setNewUserTrainerAct,

  }

  if (props.users.length < 1)
    return <p className="display-2 text-danger    m-auto">{TEXTJSON.empty}</p>
  return (
    <div className="container-fluid">
      <div className="row gy-4">

        {props.users.map((u) => (
          <>
            <List
            coachesFighter={props.coachesFighter?props.coachesFighter:[]}
              user={
                u.user === undefined
                  ? { ...u, isStar: u.isStar }
                  : { ...u.user, isStar: u.isStar }
              }
              act={acts}
              setRating={props.setRating}
            /> 
          </>
        ))}
      </div>
    </div>
  )
}

export default UserList
