import React from "react";
import { connect } from "react-redux";
 
import "draft-js/dist/Draft.css";
import BigPreloader from "../common/preloaders/BigPreloader";

import { withRouter } from "react-router-dom";
import AllClubs from "./AllClubs";

import {
  // 
  setClubs,
  // 
  setCities,
  // 
  setCurrentCityId,
  //
  setNewAddress,
  //
  setIsFetching,
} from "../../redux/allClubsReducer";
import * as axios from "axios";

class AllClubsAPIComponent extends React.Component {
  componentDidMount() {
    this.props.setIsFetching(true);
    this.props.setCurrentCityId(0);
    let that = this;
    axios
      .get("/Club/GetListWithMinInfo?page=1&pageItemCount=1000")
      .then(function (response) { 
        that.props.setClubs(response.data);
        that.props.setIsFetching(false);
      })
      .catch(function (error) { 
        that.props.setIsFetching(false);
      });
    axios
      .get("/City/GetCitiesWithClubs")
      .then(function (response) {
        that.props.setCities(response.data);
        that.props.setIsFetching(false);
      })
      .catch(function (error) { 
        that.props.setIsFetching(false);
      });
  }

  API = {
    updateClubListDueToFilter: (cityId) => {
      this.props.setIsFetching(true);
      let that = this;
      if (cityId === "0") {
        console.log(4523534534);
        axios
          .get("/Club/GetListWithMinInfo?page=1&pageItemCount=500")
          .then(function (response) {
            that.props.setIsFetching(false);
            that.props.setClubs(response.data);
          })
          .catch(function (error) {
            that.props.setIsFetching(false);
            console.log("error");
          });
      } else {
        axios
          .get("/Club/GetListWithMinInfo?page=1&pageItemCount=500&cityId=" + cityId)
          .then(function (response) {
            that.props.setClubs(response.data);
            that.props.setIsFetching(false);
          })
          .catch(function (error) {
            console.log("error");
            that.props.setIsFetching(false);
          });
      }
    },
    AddClub: () => {
      let that = this;
      this.props.setIsFetching(true);
      console.log(this.props.newTournament);
      return axios({
        method: "POST",
        url: "/club/AddClub",
        data: {
          Address: this.props.newAddress,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => {
          that.props.setIsFetching(false);
          return response;
        })
        .catch((error) => {
          that.props.setIsFetching(false);
          return error.response;
        });
    },
  };

  render() {
    return (
      <>
        {this.props.isFetching ? (
          <BigPreloader preloaderColor="danger" />
        ) : (
          <AllClubs
            {...this.props}
            // 
            API={this.API}
          />
        )}
      </>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    // 
    clubs: state.allClubs.clubs,
    // 
    cities: state.allClubs.cities,
      
    currentCityId: state.allClubs.currentCityId,
    newAddress: state.allClubs.newAddress,

    clubCoachesIdArray: state.auth.clubCoachesIdArray,
    isAuthorized: state.auth.isAuthorized,
    isCoach: state.auth.isCoach,
    isAdmin: state.auth.isAdmin,
    // =======================
    isFetching: state.allClubs.isFetching,
  };
};
let withURLDataComponentContainer = withRouter(AllClubsAPIComponent);
const AllClubsConteiner = connect(mapStateToProps, {
  // 
  setClubs,
  // 
  setCities,
  // 
  setCurrentCityId,
  //
  setNewAddress,
  //
  setIsFetching,
  // 
})(withURLDataComponentContainer);

export default AllClubsConteiner;
