import React from "react";
import TEXTJSON from "../../../../data/text.json";
let ClubLeaveButton = (props) => {
    let submitRequest = () => {
      props.setFigterStatus("load");
        Promise.all([props.API.LeaveClub()]).then((results) => {
            console.log(results[0].data)
          //   if (results[0].data) props.setFigterStatus(results[0].data);
          Promise.all([props.API.GetCoachStatusInClub()]).then((results) => {
            if (results[0].data) props.setFigterStatus(results[0].data);
          });
        });
      };
  return (
    <button onClick={() => submitRequest()}  type="button" className="btn btn-danger rounded-pill btn-lg text-uppercase ">
      {TEXTJSON.POKINU_KLUB}
    </button>
  );
};
export default ClubLeaveButton;
