import React from "react";
import { NavLink } from "react-router-dom";
import BigTextAlert from "../../../common/BigTextAlert/BigTextAlert";
import NextSpanIcon from "../../../common/NextSpanIcon/NextSpanIcon";
import TopItem from "./TopItem";
import TEXTJSON from "../../../../data/text.json";
import BigPreloader from "../../../common/preloaders/BigPreloader";

let Top = (props) => {
  return (
    <>
      <ol className="list-group  ">
        {props.isFeching ? (
          <>
            <li className="list-group-item display-6 fw-bold     text-uppercase">Тренера</li>
            <li className="list-group-item        ">
              <BigPreloader preloaderColor="danger" />
            </li>
          </>
        ) : (
          <>
            <li className="list-group-item display-6 fw-bold     text-uppercase">Тренера</li>
            {props.users.length ? (
              <>
                {props.users.map((u, i) => {
                  return <TopItem idItem={u.user.id} avatarUrl={u.user.avatarUrl} firstName={u.user.firstName} lastName={u.user.lastName} itemIndex={i} key={u.user.id + i + "TopItem"} />;
                })}
              </>
            ) : (
              <li className="list-group-item ">
                <BigTextAlert textinner={TEXTJSON.empty} />
              </li>
            )}
          </>
        )}
      </ol>
    </>
  );
};

export default Top;
