let initialState = {
  topUsers:[],
  fighters: [],
  coaches: [],
  isFething: true,
}

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SN/ALLCLUBS/SET-TOPUSERS':
      return { ...state, topUsers: action.value }
    case 'SN/ALLCLUBS/SET-FIGHTERS':
      return { ...state, fighters: action.value }
    case 'SN/ALLCLUBS/SET-COACHES':
      return { ...state, coaches: action.value }
    case 'SN/ALLCLUBS/SET-ISFETHING':
      return { ...state, isFething: action.value }
      

    default:
      return state
  }
}

export const setTopUsers = (value) => ({
  type: 'SN/ALLCLUBS/SET-TOPUSERS',
  value,
})

export const setFighters = (value) => ({
  type: 'SN/ALLCLUBS/SET-FIGHTERS',
  value,
})

export const setCoaches = (value) => ({
    type: 'SN/ALLCLUBS/SET-COACHES',
    value,
  })

  export const setIsFething = (value) => ({
    type: 'SN/ALLCLUBS/SET-ISFETHING',
    value,
  })

export default mainReducer
