import React from "react";
import TEXTJSON from "../../../../data/text.json";
let SubmitRequestButton = (props) => {
  let submitRequest = () => {
    props.setFigterStatus("load");

    Promise.all([props.API.AddToFighters()]).then((results) => {
      console.log(results[0].data);
      //   if (results[0].data) props.setFigterStatus(results[0].data);
      Promise.all([props.API.GetFighterStatusInClub()]).then((results) => {
        if (results[0].data) props.setFigterStatus(results[0].data);
      });
    });
  };
  return (
    <button onClick={() => submitRequest()} type="button" className="btn btn-dark rounded-pill btn-lg text-uppercase ">
     {TEXTJSON.VSTUPIT_V_KLUB}  
    </button>
  );
};
export default SubmitRequestButton;
