let initialState = {
    clubTrainings: null,

    coaches: [],
    sportTypes: [],

    isScheduleFetching: true,

    isAdded: false,

    newPhohtoDataUrl: null,

    isEditMode: false,

    newTime: '',
    newIndeDayInWeekId: 0,
    newUserTrainerId: "",
    newSportTypeId: '',
    newTrainerName: ''
}

const clubScheduleReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SN/CLUB-SCHEDULE-REDUCER/SET-CLUBTRAININGS':
            return {...state, clubTrainings: action.value }

        case 'SN/CLUB-SCHEDULE-REDUCER/SET-COACHES':
            return {...state, coaches: action.value }

        case 'SN/CLUB-SCHEDULE-REDUCER/SET-SPORTTYPES':
            return {...state, sportTypes: action.value }


        case 'SN/CLUB-SCHEDULE-REDUCER/SET-ISSCHEDULEFETCHING':
            return {...state, isScheduleFetching: action.value }

        case 'SN/CLUB-SCHEDULE-REDUCER/SET-ISADDED':
            return {...state, isAdded: action.value }

        case 'SN/CLUB-SCHEDULE-REDUCER/SET-ISDELITING':
            return {...state, isDeliting: action.value }

        case 'SN/CLUB-SCHEDULE-REDUCER/SET-ISEDITMODE':
            return {...state, isEditMode: action.value }
            //--------------------------------------------------
        case 'SN/CLUB-SCHEDULE-REDUCER/SET-NEWTIME':
            //
            return {...state, newTime: action.value }

        case 'SN/CLUB-SCHEDULE-REDUCER/SET-NEWINDEDAYINWEEKID':

            return {...state, newIndeDayInWeekId: action.value }

        case 'SN/CLUB-SCHEDULE-REDUCER/SET-NEWUSERTRAINERID':

            return {...state, newUserTrainerId: action.value }


        case 'SN/CLUB-SCHEDULE-REDUCER/SET-NEWSPORTTYPEID':
            console.log(action.value)
            return {...state, newSportTypeId: action.value }
            //--------------------------------------------------
        case 'SN/CLUB-SCHEDULE-REDUCER/SET-NEWTRAINERNAME':
            console.log(action.value)
            return {...state, newTrainerName: action.value }

        default:
            return {...state }
    }
}

export const setClubTrainings = (value) => ({
    type: 'SN/CLUB-SCHEDULE-REDUCER/SET-CLUBTRAININGS',
    value,
})

export const setIsScheduleFetching = (value) => ({
    type: 'SN/CLUB-SCHEDULE-REDUCER/SET-ISSCHEDULEFETCHING',
    value,
})


export const setSportTypes = (value) => ({
    type: 'SN/CLUB-SCHEDULE-REDUCER/SET-SPORTTYPES',
    value,
})



export const setCoaches = (value) => ({
    type: 'SN/CLUB-SCHEDULE-REDUCER/SET-COACHES',
    value,
})

export const setIsAdded = (value) => ({
    type: 'SN/CLUB-SCHEDULE-REDUCER/SET-ISADDED',
    value,
})

export const setIsDeliting = (value) => ({
    type: 'SN/CLUB-SCHEDULE-REDUCER/SET-ISDELITING',
    value,
})

export const setIsEditMode = (value) => ({
        type: 'SN/CLUB-SCHEDULE-REDUCER/SET-ISEDITMODE',
        value,
    })
    //--------------------------------------------------
export const setNewTime = (value) => ({
    type: 'SN/CLUB-SCHEDULE-REDUCER/SET-NEWTIME',
    value,
})

export const setNewIndeDayInWeekId = (value) => ({
    type: 'SN/CLUB-SCHEDULE-REDUCER/SET-NEWINDEDAYINWEEKID',
    value,
})

export const setNewUserTrainerId = (value) => ({
    type: 'SN/CLUB-SCHEDULE-REDUCER/SET-NEWUSERTRAINERID',
    value,
})

export const setNewSportTypeId = (value) => ({
    type: 'SN/CLUB-SCHEDULE-REDUCER/SET-NEWSPORTTYPEID',
    value,
})

export const setNewTrainerName = (value) => ({
        type: 'SN/CLUB-SCHEDULE-REDUCER/SET-NEWTRAINERNAME',
        value,
    })
    //--------------------------------------------------

export default clubScheduleReducer