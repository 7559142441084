import React from "react";
import { connect } from "react-redux";

import { setTournaments, setIsFetching,setNewTournament,setIsSended} from "../../redux/tournaments-reducer";
import * as axios from "axios";
import Tournaments from "./Tournaments";
import BigPreloader from "../common/preloaders/BigPreloader";
// ================================================================================
class TournamentsAPIComponent extends React.Component {
  // ================================================================================
  componentDidMount() {
    this.props.setIsFetching(true);
   
    Promise.all([this.API.GetList()]).then((results) => {
      this.props.setTournaments(results[0].data)
      this.props.setIsFetching(false); 
    }); 
  }
  // ================================================================================
  API = {
    GetList: () => {
      return axios({
        method: "GET",
        url: "/Tournaments/GetList",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    CreateTournament: () => { 
      console.log(this.props.newTournament);
      return axios({
        method: "POST",
        url: "/Tournaments/CreateTournament",
        data: this.props.newTournament,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    // ___________
    // ___________
    DeleteTournament: (tournamentId) => {
      return axios({
        method: 'delete',
        url: '/Tournaments/Delete/' + tournamentId,
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('accessToken'),
        },
      })
        .then((response) => response)
        .catch((error) => error.response)
    },
  };
  // ================================================================================
  render() {
    return <>{this.props.isFetching ? <BigPreloader preloaderColor="danger" /> : <Tournaments {...this.props} API={this.API} />}</>;
  }
}
// ================================================================================
let mapStateToProps = (state) => {
  return {
    tournaments: state.tournaments.tournaments,
    isFetching: state.tournaments.isFetching,
    newTournament:state.tournaments.newTournament,
    isSended:state.tournaments.isSended,
 
    clubCoachesIdArray: state.auth.clubCoachesIdArray,
    isAuthorized: state.auth.isAuthorized,
    isCoach: state.auth.isCoach,
    isAdmin: state.auth.isAdmin,
  };
};
// ================================================================================
const TournamentsConteiner = connect(mapStateToProps, {
  setTournaments,
  setIsFetching,setNewTournament,setIsSended
})(TournamentsAPIComponent);
// ================================================================================
export default TournamentsConteiner;
