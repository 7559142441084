import React from "react";
import { NavLink } from "react-router-dom";

let NavLinkNav = (props) => {
  return (
    <NavLink to={props.linkPath} activeClassName={"  nav-link link-light d-none d-sm-none d-md-none d-lg-block d-lg-block active  "} className={"  nav-link link-light d-none d-sm-none d-md-none d-lg-block d-lg-block " } aria-current="page" href="#">
      {props.linkText}
      {props.badgeLable && <span className="badge ms-1 p-1 bg-warning text-dark">{props.badgeLable}</span>}
    </NavLink>
  );
};

export default NavLinkNav;
