import React from "react";
import { connect } from "react-redux"; 
import TEXTJSON from "../../data/text.json";
import {
  //
  setUsers,
  setIsFething,
  setCurrentPage,
  setTotalProductsCount,
  //
} from "../../redux/starsList-reducer";
import * as axios from "axios";
import StarList from "./StarList";
import BigPreloader from "../common/preloaders/BigPreloader";
import BigTextAlert from "../common/BigTextAlert/BigTextAlert";
// ================================================================================
class StarListAPIComponent extends React.Component {
  // ================================================================================
  componentDidMount() {
    this.props.setUsers([]);
    this.props.setCurrentPage(1);

    this.props.setIsFething(true);
    Promise.all([this.API.GetTopUsers()]).then((results) => {
      this.props.setUsers(results[0].data);
      this.props.setTotalProductsCount(results[0].data);
      this.props.setIsFething(false);
    });
  }
  // ================================================================================
  API = {
    GetTopUsers: () => {
      return axios({
        method: "GET",
        url: "api/get/user/RatingFighter/25",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    // ___________
  };
  // ================================================================================
  render() {
    return <>{this.props.IsFetching ? <BigPreloader preloaderColor="danger" /> :this.props.users.length ? <StarList {...this.props} API={this.API} />:<BigTextAlert textinner={TEXTJSON.REJTING_PUST}/>}</>;
  }
}
// ================================================================================
let mapStateToProps = (state) => {
  return {
    users: state.starsList.users,
    IsFetching: state.starsList.IsFetching,
    //_____________
    pageSize: state.starsList.pageSize,
    totalProductsCount: state.starsList.totalProductsCount,
    currentPage: state.starsList.currentPage,
  };
};
// ================================================================================
const StarListConteiner = connect(mapStateToProps, {
  setUsers,
  setIsFething,
  setCurrentPage,
  setTotalProductsCount,
})(StarListAPIComponent);
// ================================================================================
export default StarListConteiner;
