import React from "react";
import { connect } from "react-redux";
import PriceList from "./PriceList";
import { withRouter } from "react-router-dom";
import { setClubPriceList, setIsClubPriceListFetching, setSportTypes, setIsAdded, setIsDeliting, setNewPrice, setNewSportTypeId, setIsEditMode } from "../../../redux/club-priceList-reducer";
import * as axios from "axios";

// ===========================================================================

class PriceListAPIComponent extends React.Component {
  componentDidMount() {
    this.props.setIsClubPriceListFetching(true);
    Promise.all([this.API.GetPriceList()]).then((results) => {
      if (results[0].data.priceList) this.props.setClubPriceList(results[0].data.priceList);
      this.props.setIsClubPriceListFetching(false);
    });
    Promise.all([this.API.GetSportTypeList()]).then((results) => {
      if (results[0].data) this.props.setSportTypes(results[0].data);
    });
  }

  // ===========================================================================

  API = {
    //   GET   GET   GET   GET   GET   GET   GET   GET   GET   GET
    GetPriceList: () => {
      return axios
        .get("/club/GetPriceList/" + this.props.match.params.clubId)
        .then((response) => response)
        .catch((error) => error.response);
    },
    GetSportTypeList: () => {
      return axios
        .get("/SportType/GetSportTypeList")
        .then((response) => response)
        .catch((error) => error.response);
    },
    // ADD-TO    ADD-TO    ADD-TO    ADD-TO    ADD-TO    ADD-TO    ADD-TO
    AddToPriceList: () => {
      console.log(this.props.match.params.clubId, this.props.newPhohtoDataUrl);
      return axios({
        method: "PUT",
        url: "/club/AddToPriceList",
        data: {
          clubId: this.props.match.params.clubId,
          Price: this.props.newPrice,
          SportTypeId: this.props.newSportTypeId,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    //  DELETE    DELETE    DELETE    DELETE    DELETE    DELETE    DELETE
    DeletePriceList: (priceListId) => {
      return axios({
        method: "PUT",
        url: "/club/DeletePriceList",
        data: {
          clubId: this.props.match.params.clubId,
          priceListId: priceListId,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => console.log(error.response));
    },
  };

  // ===========================================================================

  render() {
    return (
      <>
        <PriceList {...this.props} API={this.API} />
      </>
    );
  }
}

// ===========================================================================

let mapStateToProps = (state) => {
  return {
    clubPriceList: state.clubPriceList.clubPriceList,

    isClubPriceListFetching: state.clubPriceList.isClubPriceListFetching,
    sportTypes: state.clubPriceList.sportTypes,
    isAdded: state.clubPriceList.isAdded,
    isDeliting: state.clubPriceList.isDeliting,

    newPrice: state.clubPriceList.newPrice,
    newSportTypeId: state.clubPriceList.newSportTypeId,
    isEditMode: state.clubPriceList.isEditMode,
    // _____________________________________
    isAuthorized: state.auth.isAuthorized,
    clubCoachesIdArray: state.auth.clubCoachesIdArray, 
    isCoach: state.auth.isCoach,
    isAdmin: state.auth.isAdmin,
  };
};

// ===========================================================================

let withURLDataComponentContainer = withRouter(PriceListAPIComponent);

// ===========================================================================

const PriceListConteiner = connect(mapStateToProps, {
  setClubPriceList,
  setIsClubPriceListFetching,
  setSportTypes,
  setIsAdded,
  setIsDeliting,
  setNewPrice,
  setNewSportTypeId,
  setIsEditMode,
})(withURLDataComponentContainer);

// ===========================================================================

export default PriceListConteiner;
