import React from "react";
import  TEXTJSON   from "../../../data/text.json";
let PaginationEnlarge = (props) => {
  console.log(props.localUsersCount, props.totalProductsCount)
  if (props.localUsersCount  >= props.totalProductsCount) return <></>;
  return (
    <button onClick={() => props.act()} className={"btn     btn-lg rounded-pill btn-" + (props.isLoad === true ? "secondary" : "danger")}>
      {props.isLoad === true ?  TEXTJSON.ZAGRUZKA  :TEXTJSON.ESHCHE}
    </button>
  );
};

export default PaginationEnlarge;
