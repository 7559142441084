import React from "react";
import BasicInfoConteiner from "./BasicInfo/BasicInfoConteiner";
import ImagesConteiner from "./Images/ImagesConteiner";
import InteractionButtonsAreaConteiner from "./InteractionButtonsArea/InteractionButtonsAreaConteiner";
import MenegmentConteiner from "./Menegment/MenegmentConteiner";
import PriceListConteiner from "./PriceList/PriceListConteiner";
import ScheduleConteiner from "./Schedule/ScheduleConteiner";
import { withRouter } from "react-router-dom";
import CoachesConteiner from "./Coaches/CoachesConteiner";
function Club(props) {
  return (
    <article className="d-flex flex-column  ">
      <div className=" container  ">
        <div className="row  justify-content-center g-0 g-sm-0 g-md-4 g-lg-4 g-xl-4 gy-4  gy-sm-4 gy-md-0 gy-lg-0 gy-xl-0      ">
          <InteractionButtonsAreaConteiner {...props} />

          <MenegmentConteiner clubId={props.match.params.clubId} />
          <ImagesConteiner {...props} />
          <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
            <div className="container-fluid p-0">
              <div className="row gx-0 gx-sm-3 gx-md-3 gx-lg-3 gx-xl-3 gy-4">
                <BasicInfoConteiner {...props} />
                <PriceListConteiner {...props} />
                <ScheduleConteiner {...props} />
              </div>
            </div>
          </div>

          <CoachesConteiner {...props} />
        </div>
      </div>
    </article>
  );
}
export default withRouter(Club);
