import React from "react";
import { connect } from "react-redux";
import { setUser, setAllTypes, setPhone, setEmail, setFacebook, setInstagram, setTelegram, setVk, setYoutube, setOdnoklassniki, setIsFetching, setBtnsLoadingStatus } from "../../redux/admin-reducer";
import * as axios from "axios";
import Admin from "./Admin";
import BigPreloader from "../common/preloaders/BigPreloader";
import TEXTJSON from "../../data/text.json";
// ================================================================================
class AdminAPIComponent extends React.Component {
  // ================================================================================
  componentDidMount() {
    this.props.setIsFetching(true);

    Promise.all([this.API.GetJsonSiteConfig()]).then((results) => {
      console.log(results[0].data);
      this.props.setAllTypes(results[0].data); 
    });
  }
  // ================================================================================
  API = {
    GetJsonSiteConfig: () => {
      return axios({
        method: "GET",
        url: "jsonData/site.json",
      })
        .then((response) => response)
        .catch((error) => error.response);
    },

    SetJsonSiteConfig: (type, value) => {
      console.log(type, value, 12441);
      return axios({
        method: "PUT",
        url: "api/site/PutSiteJson",
        data: {
          Type: type,
          Value: value,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => console.log(error.response));
    },
    // ___________
  };
  // ================================================================================
  render() {  

    return (
      <>
        {this.props.isFetching && this.props.isFetchingAuth ? (
          <BigPreloader preloaderColor="danger" />
        ) : this.props.isAuthorized ? (
          !this.props.isAdmin ? (
            <div className=" container">
              <div className="row g-4 justify-content-center">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  {/* <img alt className="w-100  " src="/images/elements/goaway.png" /> */}
                  <p className="text-danger display-6">{TEXTJSON.dostup_zapreshchen}</p>
                </div>
              </div>
            </div>
          ) : (
            <Admin {...this.props} API={this.API} />
          )
        ) : (
          <>
            <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center">
              <btn className="btn mb-3 btn-lg  btn-primary rounded-pill" data-bs-toggle="modal" data-bs-target="#authModal" data-bs-dismiss="offcanvas" aria-label={TEXTJSON.zakryt}>
              {TEXTJSON.vojti}  
              </btn>
              <btn className="btn   btn-lg  btn-outline-primary rounded-pill" data-bs-toggle="modal" data-bs-target="#authModal" data-bs-dismiss="offcanvas" aria-label={TEXTJSON.zakryt}>
              {TEXTJSON.zaregistrirovatsya}     
              </btn>
            </div>
          </>
        )}
      </>
    );
  }
}
// ================================================================================
let mapStateToProps = (state) => {
  return {
    user: state.admin.user,
    // ===========
    phone: state.admin.phone,
    email: state.admin.email,
    facebook: state.admin.facebook,
    instagram: state.admin.instagram,
    telegram: state.admin.telegram,
    vk: state.admin.vk,
    youTube: state.admin.youTube,
    odnoklassniki: state.admin.odnoklassniki,
    // =======================
    isFetching: state.admin.isFetching,
    isFetchingAuth: state.auth.isFetching,
    // ===========
    btnsLoadingStatus: state.admin.btnsLoadingStatus,
    // ===========
    isAuthorized: state.auth.isAuthorized,
    clubCoachesIdArray: state.auth.clubCoachesIdArray, 
    isCoach: state.auth.isCoach,
    isAdmin: state.auth.isAdmin,
  };
};
// ================================================================================
const AdminConteiner = connect(mapStateToProps, {
  setUser,
  setAllTypes,
  setPhone,
  setEmail,
  setFacebook,
  setInstagram,
  setTelegram,
  setVk,
  setYoutube,
  setOdnoklassniki,
  setIsFetching,
  setBtnsLoadingStatus,
})(AdminAPIComponent);
// ================================================================================
export default AdminConteiner;
