import React from "react";
import { NavLink } from "react-router-dom";
import TEXTJSON from "../../../data/text.json";
function NavLinkToPersonalAccount(props) {
  return (
    <NavLink to="/ProfileEdit" >     <img alt="" height={props.isBig?50:20}  src="/images/icons/profile.svg"/>
    </NavLink>
  );
}

export default NavLinkToPersonalAccount;
