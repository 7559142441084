import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import EmbedContainer from "react-oembed-container";
import TEXTJSON from "../../../data/text.json";
import BigPreloader from "../preloaders/BigPreloader";
import parse from "html-react-parser";
import InnerHTML from 'dangerously-set-html-content';

var options = {
  year: "numeric",
  month: "long",
  day: "numeric",
  // weekday: 'long',
  timezone: "UTC",
};

function ArticleReaderWithEditor(props) {
  let onCreate = () => {
    let savingIdForRequesr = props.articleId;
    props.setIsBtnLoad(true);
    let result = window.confirm(TEXTJSON.confirmation_of_article_creation);
    if (result) {
      Promise.all([props.API.edit()]).then((results) => {
        if (results[0].data === "missing_text") {
          window.alert(TEXTJSON.missing_text);
        } else if (results[0].data === "missing_name") {
          window.alert(TEXTJSON.missing_name);
        } else if (results[0].data === "successfully") {
          props.setArticleName("");
          props.setArticle("");
          props.setIsFetching(true);

          Promise.all([props.API.getArticleById(savingIdForRequesr)]).then((results) => {
            if (results[0]) {
              console.log(results[0].data);
              props.setArticleId(results[0].data.id);
              props.setArticleName(results[0].data.name);
              props.setArticleTitle(results[0].data.title);
              props.setArticleDate(results[0].data.date);
              props.setArticle(results[0].data.htmlText);
            }
            props.setIsFetching(false);
          });
          window.alert(TEXTJSON.successfully);
        } else {
          window.alert(TEXTJSON.universal_error);
        }
        props.setIsBtnLoad(false);
      });
    } else {
      props.setIsBtnLoad(false);
    }
  };
  return (
    <article>
      <div className=" container-fluid">
        <div className="row gy-1 justify-content-center">
          {props.isFetching ? (
            <BigPreloader preloaderColor="danger" />
          ) : (
            <>
              {!props.isBlock ? (
                <>
                  <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">
                    <p className="text-black-50">{new Date(props.articleDate).toLocaleString("ru", options)}</p>
                  </div>
                  <div className="col-12"></div>
                </>
              ) : (
                <></>
              )}
              <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">
                <EmbedContainer markup={props.article}>
                  {/* for example, */}
                  <article className="my-news">
                    <h1 className=" text-uppercase">{props.articleTitle}</h1>
                    <div dangerouslySetInnerHTML={{ __html: props.article }} />
                  </article>
                </EmbedContainer>
                <div className="embed-container  d-flex flex-column   ">
                  {props.articleCode&&<EmbedContainer markup={props.articleCode}>
                  <InnerHTML html={props.articleCode.replace('VK.Widgets.Post','window.VK &&  VK.Widgets.Post').replace(';\n  }());',' || setTimeout(arguments.callee, 50);\n }());')} />
                  </EmbedContainer>}
                </div>
              </div>
            </>
          )}
          {props.isAdmin ? (
            <>
              <div className="col-12">
                <hr />
              </div>
              <div className="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-7 ">
                <input value={props.articleTitle} onChange={(e) => props.setArticleTitle(e.target.value)} className="form-control form-control-lg" type="text" placeholder={TEXTJSON.article_name} aria-label=".form-control-lg example" />
              </div>

              <div className="col-12">
                <CKEditor
                  editor={ClassicEditor}
                  data={props.article}
                  onReady={(editor) => {
                    console.log("Editor is ready to use!", editor);
                  }}
                  config={{
                    mediaEmbed: {
                      previewsInData: true,
                    },
                    ckfinder: {
                      uploadUrl: "/club/UploadImage",
                    },
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    props.setArticle(data);
                    console.log({ event, editor, data });
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
              <div className="col-12"></div>
              <div className="col-12">
                <div className=" display-6 text-uppercase fw-bold"> {TEXTJSON.VSTRAIVAEMY_COD}</div>
                <textarea rows="20" value={props.articleCode} onChange={(e) => props.setArticleCode(e.target.value)} className="form-control form-control-sm bg-dark text-white" type="text" aria-label=".form-control-lg example" />
              </div>
              <div className="col-12"></div>
              <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">
                <div className="d-flex flex-column">
                  {props.isBtnLoad ? (
                    <button className="btn btn-secondary btn-lg rounded-pill " type="button" disabled>
                      <span className="spinner-border spinner-border  me-2" role="status" aria-hidden="true"></span>
                      {TEXTJSON.ZAGRUZKA}
                    </button>
                  ) : (
                    <button onClick={() => onCreate()} type="button" className="btn btn-success btn-lg  rounded-pill">
                      {TEXTJSON.edit_article}
                    </button>
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </article>
  );
}

export default ArticleReaderWithEditor;
