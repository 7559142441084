import React from "react";
import AddItem from "./components/AddItem";
import ClubPriceList from "./components/ClubPriceList";
import SwitchIsEditMode from "./components/SwitchIsEditMode";
import TEXTJSON from "../../../data/text.json";
function PriceList(props) {
  let deleteItme = (priceListId) => {
    props.setIsClubPriceListFetching(true);
    Promise.all([props.API.DeletePriceList(priceListId)]).then((results) => {
      console.log(results[0].data);
      if (results[0].data === "successfully") { 
        Promise.all([props.API.GetPriceList()]).then((results) => {
          if (results[0].data.priceList) props.setClubPriceList(results[0].data.priceList);
          props.setIsClubPriceListFetching(false);
        });
      } else window.alert(TEXTJSON.universal_error);
    });
  };
  return (
    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
      <div className=" rounded-3 shadow  bg-white w-100   p-2">
        <SwitchIsEditMode {...props} />
        <p className="text-wrap  text-uppercase  fw-bold display-6">{TEXTJSON.MESECHNYJ_ABONEMENT}</p>
        {props.isClubPriceListFetching ? (
          <div className="d-flex flex-grow-1 justify-content-center align-items-center  py-5 my-5 align-self-center">
            <div className={"spinner-border my-spinner-border-xl text-danger"} role="status">
              <span className="visually-hidden">{TEXTJSON.ZAGRUZKA}</span>
            </div>
          </div>
        ) : (
          <ol className="list-group   ">
            {props.clubPriceList.map((ps,index) => (
              <ClubPriceList key={`ClubPriceList${index}`} deleteItme={deleteItme} {...ps} isEditMode={props.isEditMode} />
            ))}
          </ol>
        )}
        {props.isEditMode ? <AddItem {...props} /> : <></>}
      </div>
    </div>
  );
}

export default PriceList;
