import React from "react";
import { NavLink } from "react-router-dom";
import NextSpanIcon from "../../../common/NextSpanIcon/NextSpanIcon";

let TopItem = (props) => {
  
  return (
    <li className="list-group-item d-flex justify-content-between p-0 px-1 align-items-start">
      <div className="ms-2 m-auto ">
        <div className="fw-bold  fs-5   d-flex flex-row align-items-center  "> 
          <NavLink to={"/PersonalAccount/" + props.idItem} className="text-wrap small  link-dark">
            {props.lastName}<br/> {props.firstName}<NextSpanIcon/>
          </NavLink>
        </div>
      </div>
      <div className="my-top-table-img-sh myImg rounded-pill my-2" style={{ backgroundImage: `url(${props.avatarUrl})` }}></div>
    </li>
  );
};

export default TopItem;
