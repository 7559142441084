import React from "react";
import TEXTJSON from "../../../../data/text.json";
let Footer = (props) => {
  /**************************************************************** */
  let backBtnIsNone;
  let backBtnText;
  let backBtnIsdismiss;
  let backBtnClassColor;

  let nextBtnIsNone;
  /**************************************************************** */
  if (props.currentPage === "email") {
    backBtnIsNone = false;
    backBtnText = TEXTJSON.VYJTI;
    backBtnClassColor = "secondary";
    backBtnIsdismiss = true;

    nextBtnIsNone = false;
  }
  if (props.currentPage === "code") {
    backBtnIsNone = false;
    backBtnText =  TEXTJSON.NAZAD;
    backBtnClassColor = "primary";
    backBtnIsdismiss = false;

    nextBtnIsNone = false;
  }
  if (props.currentPage === "new-password") {
    backBtnIsNone = false;
    backBtnText = TEXTJSON.NAZAD;
    backBtnClassColor = "primary";
    backBtnIsdismiss = false;

    nextBtnIsNone = false;
  }
  if (props.currentPage === "password") {
    backBtnIsNone = false;
    backBtnText = TEXTJSON.NAZAD;
    backBtnClassColor = "primary";
    backBtnIsdismiss = false;

    nextBtnIsNone = false;
  }
  if (props.currentPage === "finish") {
    backBtnIsNone = false;
    backBtnText = TEXTJSON.PRODOLZHIT;
    backBtnClassColor = "success";
    backBtnIsdismiss = true;

    nextBtnIsNone = true;
  }
  /**************************************************************** */
  function letsBack() {
    switch (props.currentPage) {
      case "email":
        break;
      case "code":
        props.setCurrentPage("email");
        break;
      case "new-password":
        props.setCurrentPage("code");
        break;
      case "password":
        props.setCurrentPage("email");
        break;
      case "finish":
        break;

      default:
        break;
    }
  }
  function letsGo() {
    props.setErrorMessage("");
    props.setIsFetching(true);

    switch (props.currentPage) {
      case "email":
        Promise.all([props.checkEmail()]).then((results) => {
          console.log(results[0].data);
          switch (results[0].data) {
            case "invalid-email":
              props.setErrorMessage(TEXTJSON.NEKORREKTNYJ_ADRES_ELEKTRONNOJ_POCHTY);
              break;

            case "user-registered":
              props.setCurrentPage("password");
              break;

            case "cache-not-save":
              alert(TEXTJSON.universal_error);
              break;

            case "user-saved-in-cache-create":
              props.setCurrentPage("code");
              break;

            default:
              break;
          }
          props.setIsFetching(false);
        });
        break;

      case "code":
        Promise.all([props.checkCode()]).then((results) => {
          console.log(results[0].data);
          switch (results[0].data) {
            case "no-code-for-user":
              props.setErrorMessage(TEXTJSON.VREMYA_HRANENIE_KODA_ISTEKLO);
              break;

            case "incorrect-code":
              props.setErrorMessage(TEXTJSON.KOD_NE_VEREN);
              break;

            case "code-correct":
              props.setCurrentPage("new-password");
              break;

            default:
              break;
          }
          props.setIsFetching(false);
        });
        break;

      case "new-password":
        if (props.inputPassword !== props.inputConfirmationPassword) {
          props.setErrorMessage(TEXTJSON.PAPROLI_NE_POHOZHI);
        } else {
          Promise.all([props.createPassword()]).then((results) => {
            console.log(results[0].data);
            switch (results[0].data) {
              case "invalid-password":
                props.setErrorMessage(TEXTJSON.NEKORREKTNYJ_PAROL);
                break;

              case "no-code-for-user":
                props.setErrorMessage(TEXTJSON.VREMYA_HRANENIE_KODA_ISTEKLO);
                break;

              case "incorrect-code":
                alert(TEXTJSON.universal_error);
                break;

              case "user-not-creating":
                alert(TEXTJSON.universal_error);
                break;

              case "not-add-to-role":
                alert(TEXTJSON.universal_error);
                break;

              case "Invalid-username-or-password":
                alert(TEXTJSON.universal_error);
                break;

              case "password-not-added":
                alert(TEXTJSON.universal_error);
                break;

              default:
                sessionStorage.setItem("accessToken", results[0].data.access_token);
                props.setIsAuthorized(true);
                props.setCurrentPage("finish");
                Promise.all([props.checkToken()]).then((results) => {
                  if (results[0] !== "error") props.setNewObjects(results[0].data);
                  console.log(results[0].data);
                });
                break;
            }
            props.setIsFetching(false);
          });
        }
        break;

      case "password":
        Promise.all([props.getToken()]).then((results) => {
          console.log(results[0].data);
          switch (results[0].data) {
            case "Invalid-username-or-password":
              props.setErrorMessage(TEXTJSON.NE_VERNYJ_PAROL);
              break;

            default:
              sessionStorage.setItem("accessToken", results[0].data.access_token);
              props.setIsAuthorized(true);
              props.setCurrentPage("finish");
              Promise.all([props.checkToken()]).then((results) => {
                if (results[0] !== "error") props.setNewObjects(results[0].data);
                console.log(results[0].data);
              });
              break;
          }
          props.setIsFetching(false);
        });
        break;

      case "finish":
        break;

      default:
        break;
    }
  }

  return (
    <div className={"modal-footer   border-0 d-flex flex-row " + (nextBtnIsNone ? " justify-content-end" : " justify-content-between")}>
      <button type="button" onClick={() => letsBack()} className={" rounded-pill btn btn-" + backBtnClassColor + (backBtnIsNone ? " d-none" : "")} data-bs-dismiss={backBtnIsdismiss ? "modal" : ""}>
        {backBtnText}
      </button>
      <button type="button" onClick={() => letsGo()} className={" rounded-pill btn btn-danger " + (nextBtnIsNone ? " d-none" : "")+" btn-"+(props.isFetching?"secondary":"primary")} disabled={(props.isFetching?true: false)}>
     {props.isFetching?TEXTJSON.ZAGRUZKA:TEXTJSON.DALEE}   
      </button>

    </div>
  );
};

export default Footer;
