 

import React from "react";
import Preloader from "../../../common/preloaders/Preloader";
// ============================================================
let SelectCity = (props) => {
  // ============================================================
  let save = () => {
    props.setIsUpdating("city", true);
    Promise.all([props.API.UpdateCity()]).then((results) => {
      props.setIsUpdating("city", false);
      Promise.all([props.API.GetBasicInfo()]).then((results) => {
        //
      });
    });
  };
  // ============================================================
  let isCityNull = props.city ? true : false;
  if (!isCityNull && !props.isEditMode) return <></>;

  let clubCity = { id: 0, name: "" };

  if (props.city) clubCity = props.city;
  // ============================================================
  return (
    <>
        <div className="  d-flex align-items-center   ">
        <img alt="" className="me-2" width="20" src="/images/icons/city.svg" />
          {props.isEditMode ? (
            <div className="input-group   ">
              <select
              value={clubCity.id}
                className="form-select "
                onChange={(e) => {
                  // console.log()
                  props.setCity({ id: e.target.options[e.target.options.selectedIndex].id, name: e.target.options[e.target.options.selectedIndex].value });
                }}
              > 
                {props.cities.map((city,index) => (
                  <option key={`optionSelectCity${index}`}   id={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </select>
              <button onClick={() => save()} className="btn  btn-primary" type="button" id="button-addon2">
                {props.isUpdating.city ? <Preloader preloaderColor={"white"} /> : <img alt="" height="20" src="/images/icons/save.svg" />}
              </button>
            </div>
          ) : (
            <p className=" m-0 fw-bold black">{props.city.name}</p>
          )}
        </div>
    </>
  );
  // ============================================================
};
// ============================================================
export default SelectCity;
