import React from "react";
import { NavLink } from "react-router-dom";

let TopItem = (props) => {
  
  return (
    <li className="list-group-item d-flex justify-content-between align-items-start">
      <div className="ms-2 m-auto ">
        <div className="fw-bold  fs-5   d-flex flex-row align-items-center  ">
          <span className="badge bg-dark rounded-pill mx-3">{props.itemIndex + 1}</span>
          <NavLink to={"/PersonalAccount/" + props.idItem} className="text-wrap  link-dark">
            {props.lastName} {props.firstName}
          </NavLink>
        </div>
      </div>
      <div className="my-top-table-img myImg rounded-pill my-2" style={{ backgroundImage: `url(${props.avatarUrl})` }}></div>
    </li>
  );
};

export default TopItem;
