import React from "react";
import { NavLink } from "react-router-dom";
import TEXTJSON from "../../../../data/text.json";
let InLoginButton = (props) => { 
  return (
    <NavLink   type="button" to="/ProfileEdit" className="btn btn-dark rounded-pill btn-lg text-uppercase ">
    {TEXTJSON.VSTUPIT_V_KLUB}  
    </NavLink>
  );
};
export default InLoginButton;
