import React from "react";
import MinUserList from "../common/Alert/MinUserList/MinUserList";
import PaginationEnlarge from "../common/PaginationEnlarge/PaginationEnlarge";
import TEXTJSON from "../../data/text.json";
function CoachesList(props) {
  let moreUsers = () => {  
    props.setIsPaginationLoad(true); 
    Promise.all([props.setCurrentPage(props.currentPage + 1)]).then(() => {
      Promise.all([props.API.getStars()]).then((results) => {
        props.setUsers(props.users.concat(results[0].data.users));
        props.setIsPaginationLoad(false);
      });
    }); 
  };
  return (
    <article className="container">
      <div className=" mb-5">
        <p className="display-1 text-center text-wrap">
        {TEXTJSON.TRENERSKIJ_SOSTAV}
        </p>
      </div>
      <MinUserList {...props} />

      {console.log(props.totalProductsCount+"L00") }
      <div className=" mt-3 d-flex align-items-center justify-content-center">
        <PaginationEnlarge  act={moreUsers} isLoad={props.isPaginationLoad} totalProductsCount={props.totalProductsCount} localUsersCount={props.users.length}/>
      </div>
    </article>
  );
}

export default CoachesList;
