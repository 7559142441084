import React from "react";
import { NavLink } from "react-router-dom";
import BigTextAlert from "../common/BigTextAlert/BigTextAlert";
import NextSpanIcon from "../common/NextSpanIcon/NextSpanIcon";
import OneUser from "../common/OneUser/OneUser";
import Presentation from "./Presentation/Presentation";
import Top from "./Top/Top";
import TEXTJSON from "../../data/text.json";

function Main(props) {
  return (
    <article className="mt-1 ">
      <div className="container">
        <div className="row   gy-4">
          <div className="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12">
            <Presentation />
          </div>
          <div className="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
            <div className=" container-fluid px-0">
              <div className=" row gx-0 gy-4">
                <div className="col-12">
                  <div
                    className="myImg rounded-3"
                    style={{
                      backgroundImage: `url(/images/photos/grapplingtour.jpg)`,
                    }}
                  >
                    <div className="  d-flex flex-column align-items-center  p-0 py-5   rounded-3  shadow-lg   my-bd-rgb">
                      <p className=" text-uppercase  display-3 my-rubik text-white text-center text-wrap ">
                        {TEXTJSON.TURNIRY}
                      </p>

                      <NavLink
                        to={"/Tournaments"}
                        activeClassName="  btn btn-danger  rounded-pill   border-2  active"
                        className="fs-3 btn  btn-danger    border-2  rounded-pill     "
                      >
                        {TEXTJSON.KUPIT_BILET}
                        <NextSpanIcon />
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div
                    className="myImg rounded-3"
                    style={{
                      backgroundImage: `url(/images/photos/training.jpg)`,
                    }}
                  >
                    <div className="  d-flex flex-column align-items-center  p-0 py-5   rounded-3  shadow-lg   my-bd-rgb">
                      <p className=" text-uppercase  display-3 text-white text-center text-wrap  my-rubik ">
                        {TEXTJSON.TRENIROVKI}
                      </p>

                      <NavLink
                        to={"/allclubs"}
                        activeClassName="  btn btn-danger  border-2   rounded-pill  active"
                        className="fs-3 btn btn-danger  border-2   rounded-pill     "
                      >
                        {TEXTJSON.ZAPISATYA}
                        <NextSpanIcon />
                      </NavLink>
                    </div>
                  </div>
                </div>
                {props.isAuthorized ? (
                  <div className="col-12 ">
                    <div className="rounded-3  shadow-lg bg-white  ">
                      <p className="nav-link text-dark m-0     display-4   text-wrap my-rubik text-uppercase">
                        {TEXTJSON.TRENERSKIJ_SOSTAV}
                      </p>
                      <div className="container-fluid p-2">
                        <div className="row  ">
                          {props.coaches.length ? (
                            <>
                              {props.coaches.map((p, i) => {
                                return (
                                  <div className="col-4">
                                    <OneUser {...p} key={"OneUserMain" + i} />
                                  </div>
                                );
                              })}
                              <div className="col-4">
                                <div className="  w-100 h-100  d-flex flex-column align-items-center justify-content-center">
                                  <NavLink
                                    to={"/coaches"}
                                    className="text-wrap text-center       "
                                  >
                                    <p>{TEXTJSON.ESHCHE}</p>
                                  </NavLink>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="col-12">
                              <BigTextAlert textinner={TEXTJSON.empty} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
            <Top users={props.topUsers} />
          </div>
          <div className="col-12">
            <p className="nav-link text-dark m-0 my-4 p-0 display-4   text-wrap  my-rubik text-uppercase ">
              {TEXTJSON.FILEALY_PO_VSEJ_ROSSII}
            </p>

            <iframe
              src="https://yandex.ru/map-widget/v1/?um=constructor%3A2193e8f0164c9a1f6740206ca42c33f047e26dccd69a067cc9b47575d9d9ca25&amp;source=constructor"
              className=" shadow-lg w-100 rounded-3 my-map-frame"
              frameBorder="0"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="  blur footer-buy-bg py-1 fixed-bottom d-flex flex-column justify-content-center align-items-center   ">
        <NavLink
          to={"/allclubs"}
          className=" btn btn-danger btn-sm rounded-pill"
        >
          {TEXTJSON.ZAPISAT_YA_NA_TRENIROVKU}
        </NavLink>
      </div>
    </article>
  );
}

export default Main;
