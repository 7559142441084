import React from "react";
import { connect } from "react-redux";
import AdminUserList from "./AdminUserList";
import { withRouter } from "react-router-dom";
import {
  setOrderBy,
  //______________
  setFirstName,
  setLastName,
  setCityId,
  setGender,
  setDateOfBirthFrom,
  setDateOfBirthTo,
  setWeightFrom,
  setHeightFrom,
  setHeightTo,
  setWeightTo,
  setIsCoach,
  setIsProfessional,
  setIsRatingFighter,
  //______________
  setRating,
  //______________
  setIsClubFighter,
  setUsers,
  setIsFething,
  setCurrentPage,
  setTotalProductsCount,
  //________________
  setCities,
} from "../../redux/admin-userList-reducer";
import * as axios from "axios";
import GetUrlCreater from "../../util/GetUrlCreater";

// ===========================================================================

class AdminUserListAPIComponent extends React.Component {
  componentDidMount() {
    Promise.all([
      this.props.setFirstName(""),
      this.props.setLastName(""),
     this.props.setCityId(""),
      this.props.setGender(""),
      // this.props.setDateOfBirthFrom(''),
     //  this.props.setDateOfBirthTo(""),
     this.props.setWeightFrom(""),
        this.props.setHeightFrom(""),
        this.props.setHeightTo(""),
        this.props.setWeightTo(""),
        this.props.setIsCoach(false),
        this.props.setIsProfessional(false),
        this.props.setIsRatingFighter(false),
      // //______________
       this.props.setRating(""),
      // //______________
        this.props.setIsClubFighter(false),
     this.props.setCurrentPage(1),
       this.props.setTotalProductsCount(0),
      //________________
      this.props.setCities([]),
    ]).then(() => {
      Promise.all([this.API.GetWithFilter()]).then((results) => {
        this.props.setUsers(results[0].data.users);
        console.log(results[0].data);
        this.props.setTotalProductsCount(results[0].data.usersCount);
        this.props.setIsFething(false);
      });
    });

    Promise.all([this.API.GetCities()]).then((results) => {
      this.props.setCities(results[0].data);
    });
  }

  // componentWillUnmount() {
  //   this.props.setFirstName("");
  //   this.props.setLastName("");
  //   this.props.setCityId("");
  //   this.props.setGender("");
  //   this.props.setDateOfBirthFrom("");
  //   this.props.setDateOfBirthTo("");
  //   this.props.setWeightFrom("");
  //   this.props.setHeightFrom("");
  //   this.props.setHeightTo("");
  //   this.props.setWeightTo("");
  //   this.props.setIsCoach(false);
  //   this.props.setIsProfessional(false);
  //   this.props.setIsRatingFighter(false);
  //   //______________
  //   this.props.setRating("");
  //   //______________
  //   this.props.setIsClubFighter(false);
  //   this.props.setCurrentPage(1);
  //   this.props.setTotalProductsCount(0);
  //   //________________
  //   this.props.setCities([]);
  // }

  // size: 'middle',
  // //_____________
  // orderBy: '',
  // //_____________
  // firstName: '',
  // lastName: '',

  // cityId: '',

  // gender: '',
  // //_____________
  // dateOfBirthFrom: '',
  // dateOfBirthTo: '',

  // weightFrom: '',
  // weightTo: '',

  // heightFrom: '',
  // heightTo: '',
  // //_____________
  // isCoach: false,
  // isProfessional: false,
  // isRatingFighter: false,
  // isClubFighter: false,
  // //_____________
  // rating: '',
  // //_____________
  // users: [],
  // pageSize: 12,
  // totalProductsCount: 0,
  // currentPage: 1,
  // //_____________
  // IsFetching: true,
  // //___________
  // cities:[],

  // ===========================================================================

  API = {
    //   GET   GET   GET   GET   GET   GET   GET   GET   GET   GET
    GetWithFilter: () => {
      let arrayForUrl = [
        { size: this.props.size },
        { firstN: this.props.firstName },
        { lastN: this.props.lastName },
        { dateOfBirthFrom: this.props.dateOfBirthFrom },
        { dateOfBirthTo: this.props.dateOfBirthTo },

        { cityId: this.props.cityId },

        { userType: this.props.userType },

        { gender: this.props.gender },

        { orderBy: this.props.orderBy },

        { weightFrom: this.props.weightFrom },
        { weightTo: this.props.weightTo },

        { heightFrom: this.props.heightFrom },
        { heightTo: this.props.heightTo },

        { isCoach: this.props.isCoach },
        { isProfessional: this.props.isProfessional },

        { isRatingFighter: this.props.isRatingFighter },
        { isClubFighter: this.props.isClubFighter },

        { page: this.props.currentPage },
        { pageItemCount: this.props.pageSize },
      ];
      return axios({
        method: "GET",
        url: GetUrlCreater("/api/get/user/OrderingWFilter", arrayForUrl),
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    GetCities: () => {
      return axios
        .get("/City/GetCities")
        .then((response) => response)
        .catch((error) => error.response);
    },

    // DELETE   DELETE   DELETE   DELETE   DELETE   DELETE   DELETE   DELETE
    DeleteUser: (userId) => {
      return axios({
        method: "DELETE",
        url: "/api/delete/user/Completely/" + userId,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    ChangeProStatus: (fighterId) => {
      return axios({
        method: "PUT",
        url: "/api/put/user/ChangeProStatus",
        data: {
          UserId: fighterId,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => console.log(error.response));
    },
    ChangeRating: (fighterId) => {
      console.log(fighterId, this.props.rating);
      return axios({
        method: "PUT",
        url: "/api/put/user/ChangeRating",
        data: {
          UserId: fighterId,
          Number: this.props.rating,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => console.log(error.response));
    },
  };

  // ===========================================================================

  render() {
    return <AdminUserList {...this.props} API={this.API} />;
  }
}

// ===========================================================================

let mapStateToProps = (state) => {
  return {
    size: state.adminUserList.size,
    orderByArray: state.adminUserList.orderByArray,
    genderArray: state.adminUserList.genderArray,
    //_____________
    orderBy: state.adminUserList.orderBy,
    firstName: state.adminUserList.firstName,
    lastName: state.adminUserList.lastName,
    cityId: state.adminUserList.cityId,
    gender: state.adminUserList.gender,
    dateOfBirthFrom: state.adminUserList.dateOfBirthFrom,
    dateOfBirthTo: state.adminUserList.dateOfBirthTo,
    weightFrom: state.adminUserList.weightFrom,
    weightTo: state.adminUserList.weightTo,
    heightFrom: state.adminUserList.heightFrom,
    heightTo: state.adminUserList.heightTo,
    //_____________
    isCoach: state.adminUserList.isCoach,
    isProfessional: state.adminUserList.isProfessional,
    isRatingFighter: state.adminUserList.isRatingFighter,
    isClubFighter: state.adminUserList.isClubFighter,
    //_____________

    rating: state.adminUserList.rating,
    //_____________

    users: state.adminUserList.users,
    pageSize: state.adminUserList.pageSize,
    totalProductsCount: state.adminUserList.totalProductsCount,
    currentPage: state.adminUserList.currentPage,
    //_____________

    IsFetching: state.adminUserList.IsFetching,
    // _____________________________________
    isAuthorized: state.auth.isAuthorized,
    //____________________________________
    cities: state.adminUserList.cities,
  };
};

// ===========================================================================

let withURLDataComponentContainer = withRouter(AdminUserListAPIComponent);

// ===========================================================================

const AdminUserListConteiner = connect(mapStateToProps, {
  setOrderBy,
  //______________
  setFirstName,
  setLastName,
  setCityId,
  setGender,
  setDateOfBirthFrom,
  setDateOfBirthTo,
  setWeightFrom,
  setHeightFrom,
  setHeightTo,
  setWeightTo,
  setIsCoach,
  setIsProfessional,
  setIsRatingFighter,
  //______________
  setRating,
  //______________
  setIsClubFighter,
  //______________
  setUsers,
  setIsFething,
  setCurrentPage,
  setTotalProductsCount,
  //___________
  setCities,
})(withURLDataComponentContainer);

// ===========================================================================

export default AdminUserListConteiner;
