import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import {
//   setUser,
// } from "../../redux/article-reducer";
// import * as axios from "axios";
import Article from "./Article"; 
// ================================================================================
class ArticleAPIComponent extends React.Component {
  // ================================================================================
  componentDidMount() { 
 
  }
  // ================================================================================
  API = {
     
    // ___________
  };
  // ================================================================================
  render() {
    return   <Article searchingArticleName={this.props.match.params.articleName}   /> ; 
  
  }
}
// ================================================================================
let mapStateToProps = (state) => {
  return {
    // user: state.profileEdit.user,
  };
};
let withURLDataComponentContainer = withRouter(ArticleAPIComponent);
// ================================================================================
const ArticleConteiner = connect(mapStateToProps, {
//   setUser,
})(withURLDataComponentContainer);
// ================================================================================
export default ArticleConteiner;
