/************************************************************************************* */
let initialState = {
  user: [],

  phone: "",
  email: "",
  facebook: "",
  instagram: "",
  telegram: "",
  vk: "",
  youTube: "",
  odnoklassniki: "",
  // =======================
  isFetching: true,
  // =======================
  btnsLoadingStatus: [false, false, false, false, false, false, false, false],
};
/************************************************************************************* */
const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SN/ADMIN-REDUCER/SET-USER":
      return { ...state, ...action.user };
    // ===========================
    case "SN/ADMIN-REDUCER/SET-ALL-TYPES":
      console.log(action.values, 1241);
      return { ...state, ...action.values };
    // ===========================
    case "SN/ADMIN-REDUCER/SET-PHONE":
      return { ...state, phone: action.value };
    case "SN/ADMIN-REDUCER/SET-EMAIL":
      return { ...state, email: action.value };
    case "SN/ADMIN-REDUCER/SET-FACEBOOK":
      return { ...state, facebook: action.value };
    case "SN/ADMIN-REDUCER/SET-INSTAGRAM":
      return { ...state, instagram: action.value };
    case "SN/ADMIN-REDUCER/SET-TELEGRAM":
      return { ...state, telegram: action.value };
    case "SN/ADMIN-REDUCER/SET-VK":
      return { ...state, vk: action.value };
    case "SN/ADMIN-REDUCER/SET-YOUTUBE":
      return { ...state, youTube: action.value };
    case "SN/ADMIN-REDUCER/SET-ODNOKLASSNIKI":
      return { ...state, odnoklassniki: action.value };
    // ===========================
    case "SN/ADMIN-REDUCER/SET-ISFETCHING":
      return { ...state, isFetching: action.value };
    // ===========================
    case "SN/ADMIN-REDUCER/SET-BTNSLOADINGSTATUS":
      console.log("fuck",action.value,action.btnIndex)
      var b = state.btnsLoadingStatus;
      b[action.btnIndex] = action.value;
      return { ...state, btnsLoadingStatus: [...b] };
    // ===========================
    default:
      return state;
  }
};
/************************************************************************************* */
export const setUser = (user) => ({
  type: "SN/ADMIN-REDUCER/SET-USER",
  user,
});
// ===========================
export const setAllTypes = (values) => ({
  type: "SN/ADMIN-REDUCER/SET-ALL-TYPES",
  values,
});
// ===========================
export const setPhone = (value) => ({
  type: "SN/ADMIN-REDUCER/SET-PHONE",
  value,
});

export const setEmail = (value) => ({
  type: "SN/ADMIN-REDUCER/SET-EMAIL",
  value,
});

export const setFacebook = (value) => ({
  type: "SN/ADMIN-REDUCER/SET-FACEBOOK",
  value,
});

export const setInstagram = (value) => ({
  type: "SN/ADMIN-REDUCER/SET-INSTAGRAM",
  value,
});

export const setTelegram = (value) => ({
  type: "SN/ADMIN-REDUCER/SET-TELEGRAM",
  value,
});

export const setVk = (value) => ({
  type: "SN/ADMIN-REDUCER/SET-VK",
  value,
});

export const setYoutube = (value) => ({
  type: "SN/ADMIN-REDUCER/SET-YOUTUBE",
  value,
});
export const setOdnoklassniki = (value) => ({
  type: "SN/ADMIN-REDUCER/SET-ODNOKLASSNIKI",
  value,
});
// ===========================
export const setIsFetching = (value) => ({
  type: "SN/ADMIN-REDUCER/SET-ISFETCHING",
  value,
});
// =============================
export const setBtnsLoadingStatus = (value, btnIndex) => ({
  type: "SN/ADMIN-REDUCER/SET-BTNSLOADINGSTATUS",
  value,
  btnIndex,
});
/************************************************************************************* */
export default AdminReducer;
