import React from "react";
import { NavLink } from "react-router-dom";
import TEXTJSON from "../../data/text.json";


let Footer = (props) => {
  if (!props.jsonData) return <></>;

  return (
    <footer className="bg-dark mt-5  py-4">
      <div className="container bg-dark text-white mb-3  pt-3">
        <div className="row gy-4 justify-content-center ">
          {/*  */}
          <div className=" col-12  col-sm-12  col-md-3  col-lg-3  col-xl-3 text-sm-center text-center text-md-start text-lg-start text-xl-start d-flex flex-column">
            <h5 className="fw-bold "> {TEXTJSON.KONTAKTY}</h5>

            <p className="small text-white-50 ">{props.jsonData.phone}</p>
            <p className="small text-white-50 ">{props.jsonData.email}</p>
          </div>
          {/*  */}
          <div className=" col-12  col-sm-12  col-md-3  col-lg-3  col-xl-3 text-sm-center text-center text-md-start text-lg-start text-xl-start d-flex flex-column">
            <h5 className="fw-bold ">{TEXTJSON.NAVIGACIYA}</h5>

            <NavLink to="/Tournaments" className="small text-white-50 text-decoration-none ">
            {TEXTJSON.KUPIT_BILET} 
            </NavLink>
            <NavLink to="/allclubs" className="small text-white-50 text-decoration-none ">
            {TEXTJSON.ZAPISAT_YA_NA_TRENIROVKU} 
            </NavLink>
            <NavLink to="/stars" className="small text-white-50 text-decoration-none ">
            {TEXTJSON.REJTING_BOJCOV}
            </NavLink>
            <NavLink to="/coaches" className="small text-white-50 text-decoration-none ">
            {TEXTJSON.TRENERSKIJ_SOSTAV_KLUBA}
            </NavLink>
          </div>
          {/*  */}
          <div className=" col-12  col-sm-12  col-md-3  col-lg-3  col-xl-3 text-sm-center text-center text-md-start text-lg-start text-xl-start d-flex flex-column">
            <h5 className="fw-bold "> {TEXTJSON.media}</h5>

            <NavLink to="/NewsList" className="small text-white-50 text-decoration-none ">
            {TEXTJSON.news}     
            </NavLink>
            <NavLink to="/photofolders" className="small text-white-50 text-decoration-none ">
            {TEXTJSON.photo}     
            </NavLink>
            <NavLink to="/MediaList" className="small text-white-50 text-decoration-none ">
            {TEXTJSON.video}     
            </NavLink>
          </div>
          {/*  */}
          <div className=" col-12  col-sm-12  col-md-3  col-lg-3  col-xl-3 text-sm-center text-center text-md-start text-lg-start text-xl-start d-flex flex-column">
            <h5 className="fw-bold "> {TEXTJSON.O_NAS}</h5>

            <NavLink to="/ClubManagement" className="small text-white-50 text-decoration-none ">
            {TEXTJSON.RUKOVODSTVO_KLUBA} 
            </NavLink>
            <NavLink to="/TrusteesBoard" className="small text-white-50 text-decoration-none ">
            {TEXTJSON.POPUCHITEL_SKIJ_SOVET}
            </NavLink>
            <NavLink to="/about" className="small text-white-50 text-decoration-none ">
            {TEXTJSON.O_KLUBE}
            </NavLink>
          </div> 
          <div className="text-center mb-1 mt-4  col-12  ">
            <p className="  pt-4 text-center text-white-50">
              <small>{TEXTJSON.FOOTER_TITLE_NAME}</small>
            </p>
          </div>
          <div className=" mt-2 col-12">
            <div className="d-flex flex-row justify-content-center align-items-center flex-wrap">
              <div className="social youtube">
                <a href={props.jsonData.youtube} >
                  <i className="fa fa-youtube fa-2x"></i>
                </a>
              </div>
              <div className="social instagram">
                <a href={props.jsonData.instagram} >
                  <i className="fa fa-instagram fa-2x"></i>
                </a>
              </div>
              <div className="social facebook">
                <a href={props.jsonData.facebook} >
                  <i className="fa fa-facebook fa-2x"></i>
                </a>
              </div>
              <div className="social vk">
                <a href={props.jsonData.vk} >
                  <i className="fa fa-vk fa-2x"></i>
                </a>
              </div>
              <div className="social odnoklassniki">
                <a href={props.jsonData.odnoklassniki} >
                  <i className="fa fa-odnoklassniki fa-2x"></i>
                </a>
              </div>
              <div className="social telegram">
                <a href={props.jsonData.telegram} >
                  <i className="fa fa-paper-plane fa-2x"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
