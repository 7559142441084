import React from "react";
import TEXTJSON from "../../../../data/text.json";

let Header = (props) => (
  <div className="modal-header   border-0">
    <h1 className="modal-title" id="exampleModalLabel">
    {TEXTJSON.VHOD}
    </h1>
    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
  </div>
);

export default Header;
