import React from "react"; 
import Top from "./Top/Top";

function Coaches(props) {
  return (
    <>
    {props.isAuthorized?
       <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 ">
        <Top isFeching={props.isFeching} users={props.coaches} />
    </div>
      :null}
      </>
  );
}

export default Coaches;
