import React from "react";
import TEXTJSON from "../../../data/text.json";
let Presentation = (props) => (
  <div id="carouselExampleIndicators" className="carousel slide carousel-fade rounded-3 " data-bs-ride="carousel">
    <div className="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="7" aria-label="Slide 8"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="8" aria-label="Slide 9"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="9" aria-label="Slide 10"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="10" aria-label="Slide 11"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="11" aria-label="Slide 12"></button> 
    </div>
    <div className="carousel-inner  rounded-3 shadow-lg">
      <div className="carousel-item active ">
        <img src="/images/presentation/1.webp" className="d-block w-100" alt="..." />
      </div>
      <div className="carousel-item">
        <img src="/images/presentation/2.webp" className="d-block w-100" alt="..." />
      </div>
      <div className="carousel-item">
        <img src="/images/presentation/3.webp" className="d-block w-100" alt="..." />
      </div>
      <div className="carousel-item">
        <img src="/images/presentation/4.webp" className="d-block w-100" alt="..." />
      </div>
      <div className="carousel-item">
        <img src="/images/presentation/5.webp" className="d-block w-100" alt="..." />
      </div>
      <div className="carousel-item">
        <img src="/images/presentation/6.webp" className="d-block w-100" alt="..." />
      </div>
      <div className="carousel-item">
        <img src="/images/presentation/7.webp" className="d-block w-100" alt="..." />
      </div>
      <div className="carousel-item">
        <img src="/images/presentation/8.webp" className="d-block w-100" alt="..." />
      </div>
      <div className="carousel-item">
        <img src="/images/presentation/9.webp" className="d-block w-100" alt="..." />
      </div>
      <div className="carousel-item">
        <img src="/images/presentation/10.webp" className="d-block w-100" alt="..." />
      </div>
      <div className="carousel-item">
        <img src="/images/presentation/11.webp" className="d-block w-100" alt="..." />
      </div>
      <div className="carousel-item">
        <img src="/images/presentation/12.webp" className="d-block w-100" alt="..." />
      </div> 
    </div>
    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="visually-hidden">{TEXTJSON.PREDYDUSHCHIJ}</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="visually-hidden">{TEXTJSON.SLEDUYUSHCHIJ}</span>
    </button>
  </div>
);

export default Presentation;
