import React from "react";
function ClubPriceList(props) {
  return (
    <li className="list-group-item d-flex justify-content-between align-items-start">
      <div className="ms-2 me-auto">
        <div className="fw-bold">
          
        {props.sportType.name.split('(').map((item)=>{
          if(item==="детям)")return (<> <span className="kids-text rounded p-0 px-1 fw-bold">детям</span></>)
          else return (item)
        })}
          </div>
      </div>
      <span className="  fw-bold  text-nowrap">{props.price} ₽</span>
      {props.isEditMode ? (
        <span onClick={(e) => props.deleteItme(props.id)} className="badge bg-transparent">
          <img alt="" height="15" src="/images/icons/delete.svg" />
        </span>
      ) : (
        <></>
      )}
    </li>
  );
}
export default ClubPriceList;
