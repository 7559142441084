import React from "react";
import TEXTJSON from "../../../../data/text.json";
let SubmitRequestCoachButton = (props) => {
  let submitRequest = () => {
    props.setCoachStatus("load");

    Promise.all([props.API.AddToCoaches()]).then((results) => {
      console.log(results[0].data);
      //   if (results[0].data) props.setCoachStatus(results[0].data);
      Promise.all([props.API.GetCoachStatusInClub()]).then((results) => {
        if (results[0].data) props.setCoachStatus(results[0].data);
      });
    });
  };
  return (
    <button onClick={() => submitRequest()} type="button" className="btn btn-success rounded-pill btn-sm text-uppercase ">
    {TEXTJSON.STAT_TRENEROM}  
    </button>
  );
};
export default SubmitRequestCoachButton;
