import React from "react";
import TEXTJSON from "../../../data/text.json";
import Item from "./components/Item";

let ArticlesList = (props) => {
  if (props.items.length === 0) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <p className="display-4 text-danger">{TEXTJSON.news_empty}</p>
      </div>
    );
  }
  return (
    <div className=" container">
      <div className="row  gx-0   justify-content-center gy-4">
        <div className="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8">
          <div className="display-4">{props.titleForList}</div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
        <div className="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8">
          <div className=" container-fluid">
            <div className="row gx-sm-1 gx-md-2 gx-md-3 gx-xl-4 gy-4">
              {props.items.map((item) => {
                return (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <Item     noPhoto={props.noPhoto} forNavLink={props.forNavLink} {...item} />
                    {props.isAdmin&&<button className="btn btn-danger btn-sm mt-1" onClick={()=>{props.deleteNewsItem(item.id)?props.deleteNewsItem():console.log("")}}>{TEXTJSON.delete}</button>}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticlesList;
