import React from "react";
import { connect } from "react-redux";
import Images from "./Images";
import { withRouter } from "react-router-dom";
import { setClubImage, setIsClubImageFetching, setIsAdded, setIsDeliting, setNewPhohtoDataUrl, setIsEditMode } from "../../../redux/club-images-reducer";
import * as axios from "axios";

// ===========================================================================

class ImagesAPIComponent extends React.Component {
  componentDidMount() {
    this.props.setIsClubImageFetching(true)
    Promise.all([this.API.GetImages()]).then((results) => {
    this.props.setIsClubImageFetching(false) 
      if (results[0].data.clubImages) this.props.setClubImage(results[0].data.clubImages);
    });
  }

  // ===========================================================================

  API = {
    //   GET   GET   GET   GET   GET   GET   GET   GET   GET   GET
    GetImages: () => {
      return axios
        .get("/club/GetImages/" + this.props.match.params.clubId)
        .then((response) => response)
        .catch((error) => error.response);
    },
    // ADD-TO    ADD-TO    ADD-TO    ADD-TO    ADD-TO    ADD-TO    ADD-TO
    AddToImages: () => {
      console.log(this.props.match.params.clubId, this.props.newPhohtoDataUrl);
      return axios({
        method: "PUT",
        url: "/club/AddToImages",
        data: {
          clubId: this.props.match.params.clubId,
          dataUrl: this.props.newPhohtoDataUrl,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    //  DELETE    DELETE    DELETE    DELETE    DELETE    DELETE    DELETE
    DeleteImage: (clubImageId) => {
      return axios({
        method: "PUT",
        url: "/club/DeleteImage",
        data: {
          clubId: this.props.match.params.clubId,
          ClubImageId: clubImageId,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => console.log(error.response));
    },
  };

  // ===========================================================================

  render() {
    return (
      <>
        <Images {...this.props} API={this.API} />
      </>
    );
  }
}

// ===========================================================================

let mapStateToProps = (state) => {
  return {
    clubImages: state.clubImages.clubImage,

    isClubImageFetching: state.clubImages.isClubImageFetching,

    isAdded: state.clubImages.isAdded,
    isDeliting: state.clubImages.isDeliting,

    newPhohtoDataUrl: state.clubImages.newPhohtoDataUrl,

    isEditMode: state.clubImages.isEditMode,
    // _____________________________________
    isAuthorized: state.auth.isAuthorized,
    clubCoachesIdArray: state.auth.clubCoachesIdArray, 
    isCoach: state.auth.isCoach,
    isAdmin: state.auth.isAdmin,
  };
};

// ===========================================================================

let withURLDataComponentContainer = withRouter(ImagesAPIComponent);

// ===========================================================================

const ImagesConteiner = connect(mapStateToProps, {
  setClubImage,
  setIsClubImageFetching,
  setIsAdded,
  setIsDeliting,
  setNewPhohtoDataUrl,
  setIsEditMode,
})(withURLDataComponentContainer);

// ===========================================================================

export default ImagesConteiner;
