import React from "react";
import TEXTJSON from "../../../data/text.json";
function FilterCities(props) {
  return (
    <div className=" align-self-start">
      <select className="form-select w-auto" value={props.currentCityId} onChange={(e) =>{ props.updateClubListDueToFilter(e.target.value);props.setCurrentCityId(e.target.value)}} aria-label="Default select example">
        <option value="0" >
        {TEXTJSON.VSE}  
        </option>
        {props.cities.map((city,index) => (
          <option key={`${index}props.cities.map`} id={city.id} value={city.id}>
            {city.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default FilterCities;
// console.log(e.target.options[e.target.options.selectedIndex].innerHTML)
