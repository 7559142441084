import React from "react";
import { connect } from "react-redux";
import BasicInfo from "./BasicInfo";
import { withRouter } from "react-router-dom";
import { setCities, setPhoneNumber, setEmail, setAddress, setCity, setIsEditMode, setIsBasicInfoFetching,setIsUpdating } from "../../../redux/club-basicInfo-reducer";
import * as axios from "axios";

// ===========================================================================

class BasicInfoAPIComponent extends React.Component {
  componentDidMount() {
    this.props.setIsBasicInfoFetching(true);

    Promise.all([  this.API.GetBasicInfo()]).then((results) => {
      this.props.setPhoneNumber(results[0].data.phoneNumber);
      this.props.setAddress(results[0].data.address);
      this.props.setEmail(results[0].data.email);
      this.props.setCity(results[0].data.city);
      this.props.setIsBasicInfoFetching(false);
    });
    Promise.all([this.API.GetCities()]).then((results) => {
      this.props.setCities(results[0].data);
    });
  }

  // ===========================================================================

  API = {
    //   GET   GET   GET   GET   GET   GET   GET   GET   GET   GET

    GetBasicInfo: () => {
      return axios
        .get("/club/GetBasicInfo/" + this.props.match.params.clubId)
        .then((response) => response)
        .catch((error) => error.response);
    },

    GetSocialMedia: () => {
      return axios
        .get("/club/GetSocialMedia/" + this.props.match.params.clubId)
        .then((response) => response)
        .catch((error) => error.response);
    },

    GetCities: () => {
      return axios
        .get("/City/GetCities ")
        .then((response) => response)
        .catch((error) => error.response);
    },

    // UPDATE    UPDATE    UPDATE    UPDATE    UPDATE    UPDATE    UPDATE

    UpdatePhoneNumber: () => {
      return axios({
        method: "PUT",
        url: "/club/UpdatePhoneNumber",
        data: {
          clubId: this.props.match.params.clubId,
          value: this.props.phoneNumber,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },

    UpdateEmail: () => {
      return axios({
        method: "PUT",
        url: "/club/UpdateEmail",
        data: {
          clubId: this.props.match.params.clubId,
          value: this.props.email,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) =>console.log( error.response));
    },

    UpdateCity: () => {
      return axios({
        method: "PUT",
        url: "/club/UpdateCity",
        data: {
          clubId: this.props.match.params.clubId,
          value: this.props.city.id,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => console.log(error.response));
    },

    UpdateAddress: () => {
      return axios({
        method: "PUT",
        url: "/club/UpdateAddress",
        data: {
          clubId: this.props.match.params.clubId,
          value: this.props.address,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => console.log(error.response));
    },
    UpdateSocialMediaItem: (smName, smURL) => {
      return axios({
        method: "PUT",
        url: "/club/UpdateSocialMediaItem",
        data: {
          clubId: this.props.match.params.clubId,
          SocialMediaName: smName,
          SocialMediaURL: smURL,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => console.log(error.response));
    },
  };

  // ===========================================================================

  render() {
    return (
      <>
        <BasicInfo {...this.props} API={this.API} />
      </>
    );
  }
}

// ===========================================================================

let mapStateToProps = (state) => {
  return {
    cities: state.clubBasicInfo.cities,

    isBasicInfoFetching: state.clubBasicInfo.isBasicInfoFetching,

    phoneNumber: state.clubBasicInfo.phoneNumber,
    email: state.clubBasicInfo.email,
    address: state.clubBasicInfo.address,
    city: state.clubBasicInfo.city,
    isUpdating:state.clubBasicInfo.isUpdating,
    isEditMode: state.clubBasicInfo.isEditMode,
    // _____________________________________
    isAuthorized: state.auth.isAuthorized,
    clubCoachesIdArray: state.auth.clubCoachesIdArray, 
    isCoach: state.auth.isCoach,
    isAdmin: state.auth.isAdmin,
  };
};

// ===========================================================================

let withURLDataComponentContainer = withRouter(BasicInfoAPIComponent);

// ===========================================================================

const BasicInfoConteiner = connect(mapStateToProps, {
  setCities,
  setPhoneNumber,
  setEmail,
  setAddress,
  setCity,
  setIsEditMode,
  setIsBasicInfoFetching,
  setIsUpdating
})(withURLDataComponentContainer);

// ===========================================================================

export default BasicInfoConteiner;
