import React from "react";
import parse from "html-react-parser";
import SocialMediaItems from "./components/SocialMediaItmes";
import { NavLink } from "react-router-dom";
import NextSpanIcon from "../common/NextSpanIcon/NextSpanIcon";
import TEXTJSON from "../../data/text.json";
import InputText from "../common/InputText/InputText";
import UpdateAvatar from "../ProfileEdit/UpdateAvatar/UpdateAvatar";
import InputTextarea from "../common/InputTextarea/InputTextarea";
import InputDate from "../common/InputDate/InputDate";
import InputSelect from "../common/InputSelect/InputSelect";
import InputSelectCity from "../common/InputSelectCity/InputSelectCity";
import SocialMediaConteiner from "../SocialMedia/SocialMediaConteiner";

function PersonalAccount(props) {
  let updateChanges = () => {
    props.setIsFething(true);
    Promise.all([props.API.UpdateAll()]).then((results) => {
      if (results[0].data === "successfully") {
        Promise.all([props.API.GetProfileById()]).then((results) => {
          props.setUser(results[0].data);
          props.setUserAll(results[0].data);
          props.setIsFething(false);
        });
      } else if (results[0].data === "parametrs_empty") {
        alert("Анкета не должна быть пуста");
        props.setIsFething(false);
      } else {
        alert(TEXTJSON.universal_error);
        props.setIsFething(false);
      }
    });
  };

  let setNewPhotoDataUrlFromInputFile = (iputFile) => {
    var file = iputFile.target.files[0];
    var reader = new FileReader();

    reader.onloadend = function () {
      props.setDataUtiFromInputFile(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      props.setDataUtiFromInputFile("");
    }
  };

  return (
    <article>
      <div className="container-xl container-lg container-fluid">
        <div className="row justify-content-center g-0 g-sm-0 g-md-0 gy-4 gy-sm-4 g-md-3 g-lg-4 g-xl-4">
          <div className="col-9 col-sm-6 col-md-5 col-lg-4 col-xl-3 d-flex justify-content-center align-items-center">
            <div className="my-wrapper w-100 ">
              <div className="my-mini-img-box   myImg rounded-pill" style={{ backgroundImage: `url(${props.user.avatarUrl})` }}></div>
            </div>
            {/* <img src={props.user.avatarUrl} alt="" className="w-100 rounded-circle " /> */}
          </div>
          <div className="col-12"></div>
          <div className="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6">
            <div className=" shadow-lg rounded-3 bg-light   h-100   p-1">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <td className="text-end text-muted ">{TEXTJSON.IMYA}</td>
                    <td className="fw-bold w-50">{props.user.firstName ? props.user.firstName : TEXTJSON.NE_UKAZANO}</td>
                  </tr>
                  <tr>
                    <td className="text-end text-muted">{TEXTJSON.FAMILIYA}</td>
                    <td className="fw-bold">{props.user.lastName ? props.user.lastName : TEXTJSON.NE_UKAZANO}</td>
                  </tr>
                  <tr>
                    <td className="text-end text-muted">{TEXTJSON.GOROD}</td>
                    <td className="fw-bold">{props.user.cityName ? props.user.cityName : TEXTJSON.NE_UKAZANO}</td>
                  </tr>
                  <tr>
                    <td className="text-end text-muted">{TEXTJSON.VES}</td>
                    <td className="fw-bold">{props.user.weight ? props.user.weight : TEXTJSON.NE_UKAZANO} </td>
                  </tr>
                  <tr>
                    <td className="text-end text-muted">{TEXTJSON.ROST}</td>
                    <td className="fw-bold">{props.user.height ? props.user.height : TEXTJSON.NE_UKAZANO} </td>
                  </tr>
                  <tr>
                    <td className="text-end text-muted">{TEXTJSON.DATA_ROZHDENIYA}</td>
                    <td className="fw-bold">{props.user.dateOfBirth ? new Date(props.user.dateOfBirth).toLocaleDateString() : <span className="text-black-50 fw-light">{TEXTJSON.NE_UKAZANO}</span>}</td>
                  </tr>
                  <tr>
                    <td className="text-end text-muted">{TEXTJSON.LICHNYJ_TRENER}</td>
                    <td className="fw-bold">
                      {props.user.coachName ? (
                        <NavLink to={"/PersonalAccount/" + props.user.coachId}>
                          {props.user.coachName}
                          <NextSpanIcon />
                        </NavLink>
                      ) : (
                        <span className="text-black-50 fw-light">{TEXTJSON.NET}</span>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="text-end text-muted">{TEXTJSON.SPORTSMEN_CLUBOV}</td>
                    <td className="fw-bold">
                      {props.user.clubTrainInfos && props.user.clubTrainInfos.length ? (
                        props.user.clubTrainInfos.map((clb) => (
                          <>
                            <NavLink to={"/club/" + clb.clubId}>
                              {clb.clubName}
                              <NextSpanIcon />
                            </NavLink>
                            <br/>
                          </>
                        ))
                      ) : (
                        <span className="text-black-50 fw-light">{TEXTJSON.empty}</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-end text-muted">{TEXTJSON.TRENER_CLUBOV}</td>
                    <td className="fw-bold">
                      {props.user.clubCoachInfos && props.user.clubCoachInfos.length ? (
                        props.user.clubCoachInfos.map((clb) => (
                          <>
                            <NavLink to={"/club/" + clb.clubId}>
                              {clb.clubName}
                              <NextSpanIcon />
                            </NavLink>
                            <br/>
                          </>
                        ))
                      ) : (
                        <span className="text-black-50 fw-light">{TEXTJSON.empty}</span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              {props.user.socialMedia && (
                <>
                  <SocialMediaItems socialMedia={props.user.socialMedia} />
                </>
              )}
            </div>
          </div>
          <div className="col-12"></div>
          {props.user.about && (
            <div className="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6">
              <div className=" shadow-lg rounded-3 bg-light w-100 my-bg-gradient   p-2 p-sm-3 p-md-3 p-lg-3 p-xl-4">
                <p className="text-uppercase  display-5    ">{TEXTJSON.DOSTIZHENIYA}</p>
                <div className=" ">{props.user.about && parse(props.user.about.split("\n").join("<br>"))}</div>
              </div>
            </div>
          )}
          {/*  */}
          {/*  */}
          {/*  */}

          {props.isAdmin && (
            <>
              <div className="col-12"></div>
              <div className="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6">
                <div className="w-100 h-100 rounded-3 p-2 bg-white shadow-lg d-flex flex-column">
                  <div className="mb-5">
                    <div className="display-5 text-uppercase">
                      Изменить
                      <br />
                      данные
                      <br />
                      пользователя
                    </div>
                  </div>
                  <div>
                  <div className=" fw-bold text-uppercase">Аватарка</div>
                    <input className="form-control" onChange={(e) => setNewPhotoDataUrlFromInputFile(e)} type="file" id="formFile" />
                  </div>
                  <div>
                    <div className=" fw-bold text-uppercase">Имя</div>
                    <InputText setItem={props.setFirstName} inputValue={props.firstName} noBtn={true} />
                  </div>
                  <div>
                    <div className=" fw-bold text-uppercase">Фамилия</div>
                    <InputText setItem={props.setLastName} inputValue={props.lastName} noBtn={true} />
                  </div>
                  <div>
                    <div className=" fw-bold text-uppercase">Вес</div>
                    <InputText setItem={props.setWeight} inputValue={props.weight} noBtn={true} />
                  </div>
                  <div>
                    <div className=" fw-bold text-uppercase">Рост</div>
                    <InputText setItem={props.setHeight} inputValue={props.height} noBtn={true} />
                  </div>
                  <div>
                    <div className=" fw-bold text-uppercase">Город</div>
                    <InputSelectCity optionArray={props.cities} setItem={props.setCity} inputValue={props.cityId ? props.cityId : 0} noBtn={true} />
                  </div>

                  <div>
                    <div className=" fw-bold text-uppercase">Пол</div>
                    <InputSelect noBtn="true" optionArray={props.genderArray} setItem={props.setGender} inputValue={props.gender !== "man" && props.gender !== "woman" ? "all" : props.gender} />
                  </div>
                  <div>
                    <div className=" fw-bold text-uppercase">Дата рождения</div>
                    <InputDate setItem={props.setDateOfBirth} inputValue={props.dateOfBirth} noBtn={true} />
                  </div>

                  <div>
                    <div className=" fw-bold text-uppercase">Номер телефона</div>
                    <InputText lable="+7" setItem={props.setPhoneNumber} inputValue={props.phoneNumber} noBtn={true} />
                  </div>
                  <div>
                    <div className=" fw-bold text-uppercase">О себе</div>
                    <InputTextarea setItem={props.setAbout} inputValue={props.about} noBtn={true} />
                  </div>
                  <div className="d-flex my-3  flex-column justify-content-center align-items-center">
                    <button className="btn   btn-success rounded-pill " onClick={() => updateChanges()}>
                      Изменить
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12"></div>
              <div className="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6">
                <div className="shadow-lg w-100 p-2 h-100 bg-white  rounded-3">
                  <div className="mb-5">
                    <div className="display-5 text-uppercase">
                      Изменить
                      <br />
                      соц. сети
                      <br />
                      пользователя
                    </div>
                  </div>
                  <SocialMediaConteiner passedId={props.match.params.userId} />
                </div>
              </div>
            </>
          )}

          {/*  */}
          {/*  */}
          {/*  */}
        </div>
      </div>
    </article>
  );
}
export default PersonalAccount;
