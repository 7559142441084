let initialState = {
  orderByArray: [
    { value: 'desc-id', isSelect: true, text: 'по умолчанию' },
    { value: 'desc-weight', isSelect: false, text: 'вес ↓' },
    { value: 'asc-weight', isSelect: false, text: 'Весу ↑' },
    { value: 'desc-height', isSelect: false, text: 'рост ↓' },
    { value: 'asc-height', isSelect: false, text: 'Росту ↑' },
    { value: 'desc-date-of-birth', isSelect: false, text: 'дате рождения ↓' },
    { value: 'asc-date-of-birth', isSelect: false, text: 'дате рождения ↑' },
  ],

  genderArray: [
    { value: 'all', isSelect: true, text: 'все' },
    { value: 'woman', isSelect: false, text: 'жен.' },
    { value: 'man', isSelect: false, text: 'муж.' },
  ],
  size: 'middle',
  //_____________
  orderBy: '',
  //_____________
  firstName: '',
  lastName: '',

  cityId: '',

  gender: '',
  //_____________
  dateOfBirthFrom: '',
  dateOfBirthTo: '',

  weightFrom: '',
  weightTo: '',

  heightFrom: '',
  heightTo: '',
  //_____________
  isCoach: false,
  isProfessional: false,
  isRatingFighter: false,
  isClubFighter: false,
  //_____________
  rating: '',
  //_____________
  users: [],
  pageSize: 12,
  totalProductsCount: 0,
  currentPage: 1,
  //_____________
  IsFetching: true,
  //___________
  cities:[],
}

const adminUserListReducer = (state = initialState, action) => {
  switch (action.type) {
    //================================================
    case 'SN/ADMIN-USER-LIST-REDUCER/SET-ORDERBY':
      console.log('gsrgff')
      return { ...state, orderBy: action.value }

    case 'SN/ADMIN-USER-LIST-REDUCER/SET-FIRSTNAME':
      console.log('fef')
      return { ...state, firstName: action.value }

    case 'SN/ADMIN-USER-LIST-REDUCER/SET-LASTNAME':
      console.log('fef')
      return { ...state, lastName: action.value }

    case 'SN/ADMIN-USER-LIST-REDUCER/SET-CITYID':
      return { ...state, cityId: action.value }

    case 'SN/ADMIN-USER-LIST-REDUCER/SET-GENDER':
      return { ...state, gender: action.value }

    case 'SN/ADMIN-USER-LIST-REDUCER/SET-DATEOFBIRTHFROM':
      return { ...state, dateOfBirthFrom: new Date(action.value) }

    case 'SN/ADMIN-USER-LIST-REDUCER/SET-DATEOFBIRTHTO':
      return { ...state, dateOfBirthTo: new Date(action.value) }

    case 'SN/ADMIN-USER-LIST-REDUCER/SET-WEIGHTFROM':
      return { ...state, weightFrom: action.value }

    case 'SN/ADMIN-USER-LIST-REDUCER/SET-WEIGHTTO':
      return { ...state, weightTo: action.value }

    case 'SN/ADMIN-USER-LIST-REDUCER/SET-HEIGHTFROM':
      return { ...state, heightFrom: action.value }

    case 'SN/ADMIN-USER-LIST-REDUCER/SET-HEIGHTTO':
      return { ...state, heightTo: action.value }
    //=-=-------=-============-============
    case 'SN/ADMIN-USER-LIST-REDUCER/SET-ISCOACH':
      return { ...state, isCoach: action.value }

    case 'SN/ADMIN-USER-LIST-REDUCER/SET-ISPROFESSIONAL':
      return { ...state, isProfessional: action.value }

    case 'SN/ADMIN-USER-LIST-REDUCER/SET-ISRATINGFIGHTER':

      return { ...state, isRatingFighter: action.value  }

    case 'SN/ADMIN-USER-LIST-REDUCER/SET-ISCLUBFIGHTER':
      return { ...state, isClubFighter: action.value }
    //================================================
    case 'SN/ADMIN-USER-LIST-REDUCER/SET-RATING':
      console.log(action.value,1244)
      return { ...state, rating: action.value }
    //================================================
    case 'SN/ADMIN-USER-LIST-REDUCER/SET-USERS':
      return { ...state, users: action.value }

    case 'SN/ADMIN-USER-LIST-REDUCER/SET-IS-FETHING':
      return { ...state, IsFetching: action.value }

    case 'SN/ADMIN-USER-LIST-REDUCER/SET-TOTAL-PRODUCTS-COUNT':
      return { ...state, totalProductsCount: action.value }

    case 'SN/ADMIN-USER-LIST-REDUCER/SET-CURRENT-PAGE':
      return { ...state, currentPage: action.value }
      //_________________________________+++++++++++++++
      case 'SN/ADMIN-USER-LIST-REDUCER/SET-CITIES':
      return { ...state, cities: action.value }

    default:
      return state
  }
}
//================================================
export const setOrderBy = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-ORDERBY',
  value,
})
export const setFirstName = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-FIRSTNAME',
  value,
})

export const setLastName = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-LASTNAME',
  value,
})

export const setCityId = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-CITYID',
  value,
})
export const setGender = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-GENDER',
  value,
})
export const setDateOfBirthFrom = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-DATEOFBIRTHFROM',
  value,
})

export const setDateOfBirthTo = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-DATEOFBIRTHTO',
  value,
})

export const setWeightFrom = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-WEIGHTFROM',
  value,
})

export const setWeightTo = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-WEIGHTTO',
  value,
})

export const setHeightFrom = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-HEIGHTFROM',
  value,
})

export const setHeightTo = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-HEIGHTTO',
  value,
})

//================================================

export const setRating = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-RATING',
  value,
})
//================================================
export const setIsCoach = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-ISCOACH',
  value,
})

export const setIsProfessional = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-ISPROFESSIONAL',
  value,
})

export const setIsRatingFighter = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-ISRATINGFIGHTER',
  value,
})

export const setIsClubFighter = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-ISCLUBFIGHTER',
  value,
})
//================================================

export const setUsers = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-USERS',
  value,
})

export const setIsFething = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-IS-FETHING',
  value,
})

export const setCurrentPage = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-CURRENT-PAGE',
  value,
})
export const setTotalProductsCount = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-TOTAL-PRODUCTS-COUNT',
  value,
})
// ================================================

export const setCities = (value) => ({
  type: 'SN/ADMIN-USER-LIST-REDUCER/SET-CITIES',
  value,
})

export default adminUserListReducer
