import React from "react";
import { NavLink } from "react-router-dom";
import TEXTJSON from "../../data/text.json";

import ArticlesList from "../common/ArticlesList/ArticlesList";
import BigTextAlert from "../common/BigTextAlert/BigTextAlert";
import NextSpanIcon from "../common/NextSpanIcon/NextSpanIcon";
import PaginationEnlarge from "../common/PaginationEnlarge/PaginationEnlarge";
function NewsList(props) {
  let moreArticles = () => {
    props.setIsPaginationLoad(true);
    Promise.all([props.setCurrentPage(props.currentPage + 1)]).then((results) => {
      Promise.all([props.API.TakeMany()]).then((results) => {
        props.setArticles(props.articles.concat(results[0].data.articles));
        props.setIsPaginationLoad(false);
      });
    });
  };

  let deleteNewsItem = (articleId) => {
    props.setIsFetching(true);
    Promise.all([props.API.DeleteUser(articleId)]).then((results) => {
      console.log(results[0].data);
      if (results[0].data == "successfully") {
        Promise.all([props.API.TakeMany()]).then((results) => {
          props.setArticles(results[0].data.articles);
          props.setIsFetching(false);
          window.alert(TEXTJSON.successfully);
        });
      } else {
        props.setIsFetching(false);
        window.alert(TEXTJSON.universal_error);
      }
    });
  };
  return (
    <article>
      <div className=" d-flex flex-column align-items-center">
        {props.isAuthorized && (
          <NavLink to="/newarticle" className="btn btn-dark mb-5   rounded-pill">
      {TEXTJSON.create_article}
            <NextSpanIcon />
          </NavLink>
        )}
        {props.articles.length ? (
          <>
            <ArticlesList deleteNewsItem={deleteNewsItem} isAdmin={props.isAdmin} forNavLink={"article"} titleForList={TEXTJSON.news} items={props.articles} />
            <div className="mt-5">
              <PaginationEnlarge act={() => moreArticles()} localUsersCount={props.articles.length} totalProductsCount={props.totalProductsCount} isLoad={props.isPaginationLoad} />
            </div>
          </>
        ) : (
          <BigTextAlert textinner={TEXTJSON.NOVOSTI_OTUVSTUYUT} />
        )}
      </div>
    </article>
  );
}

export default NewsList;
