import React from "react";
import AuthConteiner from "../Auth/AuthConteiner";
import logotip from "../../img/uf_brand_icon.svg";
import { NavLink } from "react-router-dom";
import NavLinkNav from "./components/NavLinkNav";
import NavLinkCanvas from "./components/NavLinkCanvas";
import NavLinkNavGroup from "./components/NavLinkNavGroup";
import TEXTJSON from "../../data/text.json";

function Header(props) {
  return (
    <header className="p-2">
      <nav className="nav  bg-danger      rounded-3 d-flex   justify-content-between ">
        <NavLink
          to="/"
          activeClassName={
            "  nav-link order-1 order-sm-1 order-md-1 order-lg-0 order-xl-0"
          }
          className=" nav-link order-1 order-sm-1 order-md-1 order-lg-0 order-xl-0 "
        >
          <img alt="" width="30" src={logotip} />
        </NavLink>
        <NavLinkNav linkPath={"/allclubs"} linkText={TEXTJSON.FILIALY} />
        <NavLinkNav linkPath={"/Tournaments"} linkText={TEXTJSON.BILETY} />
        <NavLinkNav linkPath={"/stars"} linkText={TEXTJSON.REJTING} />
        {props.isAuthorized ? (
          <NavLinkNav linkPath={"/coaches"} linkText={TEXTJSON.TRENERA} />
        ) : null}
        <NavLinkNav linkPath={"/NewsList"} linkText={TEXTJSON.news} />
        {/* ===================================================================================================================== */}
        <div className="btn-group    shadow-0 d-none d-sm-none d-md-none d-lg-block d-lg-block">
          <button
            type="button"
            className="btn btn-danger    dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {TEXTJSON.media}
          </button>
          <ul className="dropdown-menu text-black">
            <li>
              <NavLinkNavGroup
                linkPath={"/MediaList"}
                linkText={TEXTJSON.video}
              />
            </li>
            <li>
              <NavLinkNavGroup
                linkPath={"/photofolders"}
                linkText={TEXTJSON.photo}
              />
            </li>
          </ul>
        </div>
        {/* ===================================================================================================================== */}
        <div className="btn-group   shadow-0 d-none d-sm-none d-md-none d-lg-block d-lg-block">
          <button
            type="button"
            className="btn   btn-danger  dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {TEXTJSON.O_NAS}
          </button>
          <ul className="dropdown-menu text-black">
            <li>
              <NavLinkNavGroup
                linkPath={"/ClubManagement"}
                linkText={TEXTJSON.RUKOVODSTVO_KLUBA}
              />
            </li>
            <li>
              <NavLinkNavGroup
                linkPath={"/TrusteesBoard"}
                linkText={TEXTJSON.POPUCHITEL_SKIJ_SOVET}
              />
            </li>
            <li>
              <NavLinkNavGroup
                linkPath={"/about"}
                linkText={TEXTJSON.O_KLUBE}
              />
            </li>
          </ul>
        </div>
        {/* ===================================================================================================================== */}

        <div className="nav-link    order-2 order-sm-2 order-md-2 order-lg-1 order-xl-1 ">
          <AuthConteiner className=" " />
        </div>

        <img
          alt=""
          height="20"
          className="d-block d-sm-block d-md-block d-lg-none align-self-center mx-3 order-0 order-sm-0 order-md-0 order-lg-2 order-xl-2 "
          src="/images/icons/toggler.svg"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasWithBothOptions"
          aria-controls="offcanvasWithBothOptions"
        />
        <div
          className="offcanvas my-bg-red-uf offcanvas-start d-block d-sm-block d-md-block d-lg-none d-xl-none"
          data-bs-scroll="true"
          tabIndex="-1"
          id="offcanvasWithBothOptions"
          aria-labelledby="offcanvasWithBothOptionsLabel"
        >
          <div className="offcanvas-header">
            <button
              type="button"
              className="btn-close btn-close-white text-reset"
              data-bs-dismiss="offcanvas"
              aria-label={TEXTJSON.zakryt}
            ></button>
          </div>
          <div className="offcanvas-body">
            <NavLinkCanvas linkPath={"/allclubs"} linkText={TEXTJSON.FILIALY} />
            <NavLinkCanvas
              linkPath={"/Tournaments"}
              linkText={TEXTJSON.BILETY}
            />
            <NavLinkCanvas linkPath={"/stars"} linkText={TEXTJSON.REJTING} />
            {props.isAuthorized ? (
          <NavLinkCanvas linkPath={"/coaches"} linkText={TEXTJSON.TRENERA} />
        ) : null}
            <NavLinkCanvas linkPath={"/NewsList"} linkText={TEXTJSON.news} />
            <NavLinkCanvas linkPath={"/MediaList"} linkText={TEXTJSON.video} />
            <NavLinkCanvas
              linkPath={"/photofolders"}
              linkText={TEXTJSON.photo}
            />
            <NavLinkCanvas
              linkPath={"/ClubManagement"}
              linkText={TEXTJSON.RUKOVODSTVO_KLUBA}
            />
            <NavLinkCanvas
              linkPath={"/TrusteesBoard"}
              linkText={TEXTJSON.POPUCHITEL_SKIJ_SOVET}
            />
            <NavLinkCanvas linkPath={"/about"} linkText={TEXTJSON.O_KLUBE} />
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
