import React from "react";

let SwitchIsEditMode = (props) => {
  if ( props.clubCoachesIdArray.indexOf(  props.match.params.clubId ) === -1&&! props.isAdmin ) return <></>;
  let swithing = (checkedValue) => {
    
    
    props.setIsEditMode(checkedValue);
  };
  // ============================================================
  return (
    <div className="d-flex justify-content-end">
      <div className="form-check form-switch  ">
        <label className="form-check-label" htmlFor="flexSwitchCheckImageClub">
          ✐
        </label>
        <input className="form-check-input" onChange={(e) => swithing(e.target.checked)} type="checkbox" id="flexSwitchCheckImageClub" />
      </div>
    </div>
  );
  // ============================================================
};

export default SwitchIsEditMode;
