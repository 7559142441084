import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import EmbedContainer from "react-oembed-container";
import TEXTJSON from "../../data/text.json";
import parse from "html-react-parser";

function ArticleCreater(props) {
  let onCreate = () => {
    props.setIsBtnLoad(true);
    let result = window.confirm(TEXTJSON.confirmation_of_article_creation);
    if (result) {
      Promise.all([props.API.create()]).then((results) => {
        if (results[0].data === "missing_text") {
          window.alert(TEXTJSON.missing_text);
        } else if (results[0].data === "missing_name") {
          window.alert(TEXTJSON.missing_name);
        } else if (results[0].data === "successfully") {
          window.alert(TEXTJSON.successfully);
          props.setArticleName("");
          props.setArticle("");
          props.setArticleCode("");
        } else {
          console.log(results[0].data);
          window.alert(TEXTJSON.universal_error);
        }
        props.setIsBtnLoad(false);
      });
    } else {
      props.setIsBtnLoad(false);
    }
  };
  return (
    <article>
      <div className=" container-fluid">
        <div className="row gy-4 justify-content-center">
          <div className="col-12">
            <hr />
          </div>
          <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">
            <EmbedContainer markup={props.article}>
              {/* for example, */}
              <article className="my-news">
                <h1 className=" text-uppercase">{props.articleName}</h1>
                <div dangerouslySetInnerHTML={{ __html: props.article }} />
              </article>
            </EmbedContainer>
            <div className="embed-container  d-flex flex-column  align-items-center">
              <EmbedContainer markup={props.articleCode}>
                <div className="py-4" dangerouslySetInnerHTML={{ __html: props.articleCode.split("\n").join("<br>") }} />
              </EmbedContainer>
            </div>
          </div>

          <div className="col-12 my-4">
            <hr />
          </div>

          <div className="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-7 ">
            <input value={props.articleName} onChange={(e) => props.setArticleName(e.target.value)} className="form-control form-control-lg" type="text" placeholder={TEXTJSON.article_name} aria-label=".form-control-lg example" />
          </div>
          <div className="col-12">
            <CKEditor
              editor={ClassicEditor}
              data={props.article}
              onReady={(editor) => {
                console.log("Editor is ready to use!", editor);
              }}
              config={{
                mediaEmbed: {
                  previewsInData: true,
                },
                ckfinder: {
                  uploadUrl: "/club/UploadImage",
                },
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                props.setArticle(data);
                console.log({ event, editor, data });
              }}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
            />
          </div>
          <div className="col-12"></div>
          <div className="col-12">
            <div className=" display-6 text-uppercase fw-bold"> {TEXTJSON.VSTRAIVAEMY_COD}</div>
            <textarea rows="20" value={props.articleCode} onChange={(e) => props.setArticleCode(e.target.value)} className="form-control form-control-sm bg-dark text-white" type="text" aria-label=".form-control-lg example" />
          </div>
          <div className="col-12"></div>
          <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">
            <div className="d-flex flex-column">
              {props.isBtnLoad ? (
                <button className="btn btn-secondary btn-lg rounded-pill " type="button" disabled>
                  <span className="spinner-border spinner-border  me-2" role="status" aria-hidden="true"></span>
                  {TEXTJSON.ZAGRUZKA}
                </button>
              ) : (
                <button onClick={() => onCreate()} type="button" className="btn btn-success btn-lg  rounded-pill">
                  {TEXTJSON.create_article}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default ArticleCreater;
