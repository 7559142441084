import React from "react";
import TEXTJSON from "../../../../data/text.json";
let LoadingBtn = () => {
  return (
    <button className="btn btn-secondary btn-lg rounded-pill " type="button" disabled>
      <span className="spinner-border spinner-border  me-2" role="status" aria-hidden="true"></span>
      {TEXTJSON.ZAGRUZKA}
    </button>
  );
};
export default LoadingBtn;
