let initialState = {
  figterStatus: null,
  coachStatus: null,
  isFigterStatusFetching: null,
  isCoachStatusFetching: null,
}

const clubInteractionButtonsAreaReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SN/CLUB-IMAGES/SET-FIGTERSTATUS':
      return { ...state, figterStatus: action.value }

    case 'SN/CLUB-IMAGES/SET-ISFIGTERSTATUSFETCHING':
      return { ...state, isFigterStatusFetching: action.value }

    case 'SN/CLUB-IMAGES/SET-COACHSTATUS':
      return { ...state, coachStatus: action.value }

    case 'SN/CLUB-IMAGES/SET-ISCOACHSTATUSFETCHING':
      return { ...state, isCoachStatusFetching: action.value }

    default:
      return { ...state }
  }
}

export const setFigterStatus = (value) => ({
  type: 'SN/CLUB-IMAGES/SET-FIGTERSTATUS',
  value,
})

export const setIsFigterStatusFetching = (value) => ({
  type: 'SN/CLUB-IMAGES/SET-ISFIGTERSTATUSFETCHING',
  value,
})

export const setCoachStatus = (value) => ({
  type: 'SN/CLUB-IMAGES/SET-COACHSTATUS',
  value,
})

export const setIsCoachStatusFetching = (value) => ({
  type: 'SN/CLUB-IMAGES/SET-ISCOACHSTATUSFETCHING',
  value,
})

export default clubInteractionButtonsAreaReducer
