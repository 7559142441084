import React from "react";
import TEXTJSON from "../../../data/text.json";
let UpdateAvatar = (props) => {
  let setNewPhotoDataUrlFromInputFile = (iputFile) => {
    var file = iputFile.target.files[0];
    var reader = new FileReader();

    reader.onloadend = function () {
      props.setDataUtiFromInputFile(reader.result);
      console.log(reader.result);
      console.log(props.dataUtiFromInputFile);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      props.setDataUtiFromInputFile("");
    }
  };

  let updateUserAvatar = () => {
    props.setItemIsLoadArray(6, true);
    Promise.all([props.setAvatarUrl()]).then((results) => {
      props.setItemIsLoadArray(6, false);

      if (results[0].data === "successfully") {
        alert(TEXTJSON.FOTO_IZMENINO);
        props.updataPhoto(props.dataUtiFromInputFile);
      } else if (results[0].data === "parametrs_empty") alert(TEXTJSON.DLYA_IMENENIYA_FOTOGRAFII_PROFILYA_NEOBHODIMO_EE_VYBRAT);
      else alert(TEXTJSON.universal_error);
    });
  };

  return (
    <>
      <button type="button" className="btn btn-primary mt-4 rounded-pill" data-bs-toggle="modal" data-bs-target="#updateAvatarModal">
        {TEXTJSON.IZMENIT_AVATARKU}
      </button>

      <div className="modal fade" id="updateAvatarModal" tabindex="-1" aria-labelledby="updateAvatarModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="updateAvatarModalLabel">
                {TEXTJSON.VYBERI_FOTO}
              </h5>
              <button type="button" onClick={() => props.setDataUtiFromInputFile("")} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <input className="form-control" onChange={(e) => setNewPhotoDataUrlFromInputFile(e)} type="file" id="formFile" />
              <img src={props.dataUtiFromInputFile} className="w-100 p-3" alt="" />
            </div>
            <div className="modal-footer">
              <button type="button" onClick={() => props.setDataUtiFromInputFile("")} className="btn btn-secondary rounded-pill" data-bs-dismiss="modal">
                {TEXTJSON.zakryt}
              </button>
              {console.log(props.isLoad)}
              <button type="button" disabled={props.isLoad ? true : false} onClick={() => updateUserAvatar()} className={"btn btn-primary  rounded-pill btn-" + (props.isLoad ? "secondary" : "primary")}>
                {props.isLoad ? TEXTJSON.ZAGRUZKA :TEXTJSON.IZMENIT_AVATARKU }
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateAvatar;
