/************************************************************************************* */
let initialState = {
  tournaments: [],
  newTournament: {
    article: '',
    cityName: '',
    dateTime: '',
    imageDataUrl: '',
    linkToBuyTicket: '',
    name: '',
  },
  isSended:false,
  isFetching: true,
}
/************************************************************************************* */
const tournamentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SN/TOURNAMENTS-REDUCER/SET-TOURNAMENTS':
      return { ...state, tournaments: action.value }

    case 'SN/TOURNAMENTS-REDUCER/SET-NEW-TOURNAMENTS':  
      if (action.valueType === 'article')
        return {
          ...state,
          newTournament: { ...state.newTournament, article: action.value },
        }
      else if (action.valueType === 'cityName')
        return {
          ...state,
          newTournament: { ...state.newTournament, cityName: action.value },
        }
      else if (action.valueType === 'dateTime')
        return {
          ...state,
          newTournament: { ...state.newTournament, dateTime: action.value },
        }
      else if (action.valueType === 'imageDataUrl')

        return {
          ...state,
          newTournament: { ...state.newTournament, imageDataUrl: action.value },
        }
      else if (action.valueType === 'linkToBuyTicket')
        return {
          ...state,
          newTournament: { ...state.newTournament, linkToBuyTicket: action.value },
        }
      else if (action.valueType === 'name')
        return {
          ...state,
          newTournament: { ...state.newTournament, name: action.value },
        }
      else return { ...state }

    case 'SN/TOURNAMENTS-REDUCER/SET-ISFETCHING':
      return { ...state, isFetching: action.value }

      case 'SN/TOURNAMENTS-REDUCER/SET-ISSENDED':
      return { ...state, isSended: action.value }

    default:
      return state
  }
}
/************************************************************************************* */
export const setTournaments = (value) => ({
  type: 'SN/TOURNAMENTS-REDUCER/SET-TOURNAMENTS',
  value,
})

export const setNewTournament = (valueType,value) => ({
  type: 'SN/TOURNAMENTS-REDUCER/SET-NEW-TOURNAMENTS',
  valueType,
  value,
})

export const setIsFetching = (value) => ({
  type: 'SN/TOURNAMENTS-REDUCER/SET-ISFETCHING', 
  value,
})

export const setIsSended = (value) => ({
  type: 'SN/TOURNAMENTS-REDUCER/SET-ISSENDED', 
  value,
})

/************************************************************************************* */
export default tournamentsReducer
