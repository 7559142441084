import React from "react";
import { NavLink } from "react-router-dom";
import BigTextAlert from "../../common/BigTextAlert/BigTextAlert";
import NextSpanIcon from "../../common/NextSpanIcon/NextSpanIcon";
import TopItem from "./TopItem";
import TEXTJSON from "../../../data/text.json";

let Top = (props) => {
  return (
    <>
      <ol className="list-group  shadow-lg ">
        <li className="list-group-item text-uppercase display-3  my-rubik ">Топ 25</li>
        {props.users.length ? (
          <>
            {props.users.map((u, i) => {
              return <TopItem idItem={u.id} avatarUrl={u.avatarUrl} firstName={u.firstName} lastName={u.lastName} itemIndex={i} key={u.id + i + "TopItem"} />;
            })}
            <li className="list-group-item text-uppercase   fw-bold text-end">
              <NavLink to="/stars">
              {TEXTJSON.VES_REJTING}<NextSpanIcon />
              </NavLink>
            </li>
          </>
        ) : (
          <li className="list-group-item ">
            <BigTextAlert textinner= {TEXTJSON.empty} />
          </li>
        )}
      </ol>
    </>
  );
};

export default Top;
