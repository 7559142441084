import React from "react";
import { NavLink } from "react-router-dom";
import NextSpanIcon from "../../../common/NextSpanIcon/NextSpanIcon";
import TEXTJSON from "../../../../data/text.json";
let ResponseWaitButton = () => {
  return (
    <>
    <div className="d-flex flex-column">
       <div className="alert alert-warning" role="alert">
        <h4 className="alert-heading">{TEXTJSON.warning}</h4>
        <hr />
        <p className="mb-0">{TEXTJSON.OBYAZATEL_NO_ZAPOLNITE_VASHI_DANNYE}</p>
        <NavLink to="/ProfileEdit" type="button" className="btn btn-link btn-lg">
        {TEXTJSON.PEREJTI_V_LICHNYJ_KABINET}<NextSpanIcon/>
        </NavLink>
      </div>
      <button type="button" className="btn btn-warning rounded-pill btn-lg text-uppercase align-self-center ">
      {TEXTJSON.ZAPROS_OBROBATYVAETSYA} 
      </button>
    </div>
     
    </>
  );
};
export default ResponseWaitButton;
