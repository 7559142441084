import React from "react";

import ClubLeaveButton from "./components/ClubLeaveButton";
import ResponseWaitButton from "./components/ResponseWaitButton";
import SubmitRequestButton from "./components/SubmitRequestButton";
import LoadingBtn from "./components/LoadingBtn";
import InLoginButton from "./components/InLoginButton";

import ClubLeaveWorkButton from "./components/ClubLeaveWorkButton";
import ResponseWaitCoachButton from "./components/ResponseWaitCoachButton";
import SubmitRequestCoachButton from "./components/SubmitRequestCoachButton"; 
import InLoginCoachButton from "./components/InLoginCoachButton";
import LoadingCoachBtn from "./components/LoadingCoachBtn";

function InteractionButtonsArea(props) { 
  let RequestBtn = () => <></>; 
  switch (props.figterStatus) {

    case "load":
      RequestBtn = LoadingBtn;
      break;

    case "parametrs_empty":
      RequestBtn = () => <></>;
      break;

    case "context_empty":
      RequestBtn = InLoginButton ;
      break;

    case "club_unexist":
      RequestBtn = () => <></>;
      break;

    case "user_unexist":
      RequestBtn = InLoginButton ;
      break;

    case "not_a_member":
      RequestBtn = SubmitRequestButton ;
      break;

    case "wait_answer":
        RequestBtn =  ResponseWaitButton ;
      break;

    case "a_member":
        RequestBtn =  ClubLeaveButton ;
      break;

    default:
        RequestBtn = InLoginButton ;
      break;
  }
    // ╔════╗╔═══╗╔═══╗╔╗╔╗╔═══╗╔═══╗
    // ╚═╗╔═╝║╔═╗║║╔══╝║║║║║╔══╝║╔═╗║
    // ──║║──║╚═╝║║╚══╗║╚╝║║╚══╗║╚═╝║
    // ──║║──║╔══╝║╔══╝║╔╗║║╔══╝║╔══╝
    // ──║║──║║───║╚══╗║║║║║╚══╗║║
    // ──╚╝──╚╝───╚═══╝╚╝╚╝╚═══╝╚╝
  let RequestCoachBtn = () => <></>; 

  switch (props.coachStatus) {

    case "load":
      RequestCoachBtn = LoadingCoachBtn;
      break;

    case "parametrs_empty":
      RequestCoachBtn = () => <></>;
      break;

    case "context_empty":
      RequestCoachBtn = InLoginCoachButton ;
      break;

    case "club_unexist":
      RequestCoachBtn = () => <></>;
      break;

    case "user_unexist":
      RequestCoachBtn = InLoginCoachButton ;
      break;

    case "not_a_member":
      RequestCoachBtn = SubmitRequestCoachButton ;
      break;

    case "wait_answer":
        RequestCoachBtn =  ResponseWaitCoachButton ;
      break;

    case "a_member":
        RequestCoachBtn =  ClubLeaveWorkButton ;
      break;

    default:
        RequestCoachBtn = InLoginCoachButton ;
      break;
  }
  return (
    <>
      <div className="col-12      ">
        <div className="d-flex  justify-content-center">
          <RequestBtn {...props} />
        </div>
      </div>
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */} 
      <div className="col-12  order-5    ">
      <div className="d-flex  justify-content-end">
          <RequestCoachBtn {...props} />
        </div>
      </div>
    </>
  );
}

export default InteractionButtonsArea;
