import React from "react";
import { connect } from "react-redux";
import Header from "./Header";

import { /*setsetCategory,*/} from "../../redux/header-reducer";
//import * as axios from "axios";

class AuthAPIComponent extends React.Component {
  componentDidMount() {
    // this.props.setSurrentPage(PagesEnum.PagePhone);
  }

  render() {
    return (
      <>
        <Header {...this.props} />
      </>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    isAuthorized: state.auth.isAuthorized
  };
};

const AuthConteiner = connect(mapStateToProps, {
//   setInputEmail,
})(AuthAPIComponent);

export default AuthConteiner;
