import React from "react";
import TEXTJSON from "../../../data/text.json";
let BigPreloader = (props) => (
  <div className="d-flex flex-grow-1 justify-content-center align-items-center w-100 h-100 py-5 my-5 align-self-center">
    <div className={"spinner-border my-spinner-border-xl text-"+(props.preloaderColor?props.preloaderColor:"primary")} role="status">
      <span className="visually-hidden">{TEXTJSON.ZAGRUZKA}</span>
    </div>
  </div>
);

export default BigPreloader;
