import React from "react";
import Card from "./components/Card";
import Manager from "./components/Manager";
import TEXTJSON from "../../data/text.json";
import BigTextAlert from "../common/BigTextAlert/BigTextAlert";

function Tournaments(props) {
  let deleteNewsItem = (tournamentId) => {
    let result = window.confirm(TEXTJSON.are_you_sure);
    if (!result) return;
    props.setIsFetching(true);
    Promise.all([props.API.DeleteTournament(tournamentId)]).then((results) => {
      console.log(results[0].data);
      if (results[0].data == "successfully") {
        Promise.all([props.API.GetList()]).then((results) => {
          props.setTournaments(results[0].data);
          props.setIsFetching(false);
          window.alert(TEXTJSON.successfully);
        });
      } else {
        props.setIsFetching(false);
        window.alert(TEXTJSON.universal_error);
      }
    });
  };

  return (
    <article>
      <div className="container">
        <div className="row gx-0 gx-sm-0 gx-md-4 gx-lg-4 gx-xl-4 gy-4 gy-sm-4 gy-md-4 gy-lg-4 gy-xl-4">
          {/*  */}
          <div className="col012">{props.isAdmin ? <Manager {...props} /> : <></>}</div>
          {/*  */}
          <p className="display-1     col-12 text-center text-sn-center text-md-start text-lg-start text-xl-start">{TEXTJSON.TURNIRY}</p>
          {props.tournaments.length ?  props.tournaments.map((t, i) => (
            <>
              <Card deleteNewsItem={deleteNewsItem} isAuthorized={props.isAuthorized} {...t} indexT={i} key={`${i}Card`} />
            </>
          )):    <BigTextAlert textinner={TEXTJSON.TURNIRY_OTUVSTUYUT} />}
        </div>
      </div>
    </article>
  );
}

export default Tournaments;
