import React from "react";
import TEXTJSON from "../../../data/text.json";
function ModalBtn(props) {
  return (
    <img height={props.isBig?50:20}   src="/images/icons/login.svg"  data-bs-toggle="modal" data-bs-target="#authModal" data-bs-dismiss="offcanvas" aria-label={TEXTJSON.zakryt}/> 
    
  );
}

export default ModalBtn;
