import React from "react";

import InputPhoneNum from "./components/InputPhoneNum";
import InputEmail from "./components/InputEmail";
import SelectCity from "./components/SelectCity";
import InputAddress from "./components/InputAddress";
import SwitchIsEditMode from "./components/SwitchIsEditMode";
import BigPreloader from "../../common/preloaders/BigPreloader";
import SocialMediaConteiner from "../../SocialMedia/SocialMediaConteiner";
import TEXTJSON from "../../../data/text.json";
import SocialMediaConteinerList from "../../SocialMediaList/SocialMediaConteiner";

function BasicInfo(props) {
  return (
    <>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6     ">
        <div className="rounded-3 shadow   bg-white      ">
          <SwitchIsEditMode {...props} />
          <div className="py-3 ps-3  ps-sm-2 ps-md-2 ps-lg-3 ps-xl-4 d-flex flex-column  align-items-start">
            <p className="display-6    fw-bold  text-uppercase">{TEXTJSON.KONTAKTY}</p>
            {props.isBasicInfoFetching ? (
              <BigPreloader preloaderColor={"danger"} />
            ) : (
              <div className="container  ">
                <div className="row gy-3  ">
                  <div className="col-12">
                    <InputPhoneNum {...props} />
                  </div>
                  <div className="col-12">
                    <InputEmail {...props} />
                  </div>
                  <div className="col-12">
                    <InputAddress {...props} />
                  </div>
                  <div className="col-12">
                    <SelectCity {...props} />
                  </div>
                </div>
              </div>
            )}
            <hr /> 
            <>
              <SocialMediaConteinerList passedId={props.match.params.clubId} />
            </>
            {props.isEditMode && (
              <>
                <hr />
                <SocialMediaConteiner passedId={props.match.params.clubId} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default BasicInfo;
