/************************************************************************************* */
let initialState = {
  jsonData:{}
};
/************************************************************************************* */
const footerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SN/FOOTERS-REDUCER/SET-JSONDATA": 
      return { ...state, jsonData:action.value };

    default:
      return state;
  }
};
/************************************************************************************* */
export const setJsonData = (value) => ({
  type: "SN/FOOTERS-REDUCER/SET-JSONDATA",
  value,
});

/************************************************************************************* */
export default footerReducer;
