import React from "react";
import { connect } from "react-redux";
import Schedule from "./Schedule";
import { withRouter } from "react-router-dom";
import { setClubTrainings, setIsScheduleFetching, setSportTypes, setCoaches, setIsAdded, setIsDeliting, setIsEditMode, setNewTime, setNewIndeDayInWeekId, setNewUserTrainerId, setNewSportTypeId,setNewTrainerName } from "../../../redux/club-schedule-reducer";
import * as axios from "axios";

// ===========================================================================

class ScheduleAPIComponent extends React.Component {
  componentDidMount() {
    this.props.setIsScheduleFetching(true);
    Promise.all([this.API.GetTrainings()]).then((results) => {
      if (results[0].data.trainings) this.props.setClubTrainings(results[0].data.trainings);
      this.props.setIsScheduleFetching(false);
    });
    Promise.all([this.API.GetCoaches()]).then((results) => {
      if (results[0].data) this.props.setCoaches(results[0].data);
    });
    Promise.all([this.API.GetSportTypeList()]).then((results) => { 
      if (results[0].data) this.props.setSportTypes(results[0].data);
    });
  }

  // ===========================================================================

  API = {
    //   GET   GET   GET   GET   GET   GET   GET   GET   GET   GET
    GetTrainings: () => {
      return axios
        .get("/club/GetTrainings/" + this.props.match.params.clubId)
        .then((response) => {console.log(response);return response})
        .catch((error) => error.response);
    },
    GetCoaches: () => {
      return axios
        .get("/club/GetCoaches/" + this.props.match.params.clubId)
        .then((response) => response)
        .catch((error) => error.response);
    },
    GetSportTypeList: () => {
      return axios
        .get("/SportType/GetSportTypeList")
        .then((response) => response)
        .catch((error) => error.response);
    },
    // UPDATE    UPDATE    UPDATE    UPDATE    UPDATE    UPDATE    UPDATE
    AddToTrainings: () => { 
      return axios({
        method: "PUT",
        url: "/club/AddToTrainings",
        data: {
          clubId: this.props.match.params.clubId,
          Time: this.props.newTime,
          IndeDayInWeekId: this.props.newIndeDayInWeekId,
          UserTrainerId: this.props.newUserTrainerId,
          SportTypeId: this.props.newSportTypeId,
          TrainerName: this.props.newTrainerName,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    // DELETE    DELETE    DELETE    DELETE    DELETE    DELETE    DELETE
    DeleteTraining: (trainingId) => { 
      return axios({
        method: "PUT",
        url: "/club/DeleteTraining",
        data: {
          clubId: this.props.match.params.clubId,
          trainingId: trainingId,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
  };

  // ===========================================================================

  render() {
    return (
      <>
        <Schedule {...this.props} API={this.API} />
      </>
    );
  }
}

// ===========================================================================

let mapStateToProps = (state) => {
  return {
    clubTrainings: state.clubSchedule.clubTrainings,

    isScheduleFetching: state.clubSchedule.isScheduleFetching,

    coaches: state.clubSchedule.coaches,
    sportTypes: state.clubSchedule.sportTypes,

    newTrainerName:state.clubSchedule.newTrainerName,

    isAdded: state.clubSchedule.isAdded,

    newPhohtoDataUrl: state.clubSchedule.newPhohtoDataUrl,

    isEditMode: state.clubSchedule.isEditMode,
    //________________________________________
    newTime: state.clubSchedule.newTime,
    newIndeDayInWeekId: state.clubSchedule.newIndeDayInWeekId,
    newUserTrainerId: state.clubSchedule.newUserTrainerId,
    newSportTypeId: state.clubSchedule.newSportTypeId,
    // _____________________________________
    isAuthorized: state.auth.isAuthorized,
    clubCoachesIdArray: state.auth.clubCoachesIdArray, 
    isCoach: state.auth.isCoach,
    isAdmin: state.auth.isAdmin,
  };
};

// ===========================================================================

let withURLDataComponentContainer = withRouter(ScheduleAPIComponent);

// ===========================================================================

const ScheduleConteiner = connect(mapStateToProps, {
  setClubTrainings,
  setIsScheduleFetching,
  setCoaches,
  setSportTypes,
  setIsAdded,
  setIsDeliting,
  setIsEditMode,
  setNewTime,
  setNewIndeDayInWeekId,
  setNewUserTrainerId,
  setNewSportTypeId,
  setNewTrainerName
})(withURLDataComponentContainer);

// ===========================================================================

export default ScheduleConteiner;
