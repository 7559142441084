import React from "react";
import { NavLink } from "react-router-dom";
import NextSpanIcon from "../../common/NextSpanIcon/NextSpanIcon";
import TEXTJSON from "../../../data/text.json";

let NavLinkCanvas = (props) => {
  return (
    <NavLink  to={props.linkPath} activeClassName={"nav-link active link-light  "}   aria-label={TEXTJSON.zakryt} className={"nav-link   link-light    " }>
      <div aria-current="page" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
        {props.linkText}{props.badgeLable && <span className="badge ms-1 p-1   bg-warning text-dark">{props.badgeLable}</span>}
        <NextSpanIcon />
      </div>
    </NavLink>
  );
};

export default NavLinkCanvas;
