import React from "react";
import ArticleReaderWithEditorConteiner from "../common/ArticleReaderWithEditor/ArticleReaderWithEditorConteiner";

function Article(props) {
  return (
    <article>
        <ArticleReaderWithEditorConteiner searchingArticleName={props.searchingArticleName}/>
    </article>
  );
}

export default Article;
