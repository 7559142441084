import React from "react";
import { connect } from "react-redux";
import ClubCoachRequests from "./ClubCoachRequests";
import { withRouter } from "react-router-dom";
import { setCoaches, setIsFething, setCurrentPage, setTotalProductsCount } from "../../redux/clubCoachRequests-reducer";
import * as axios from "axios";
import BtnPreloader from "../common/preloaders/BtnPreloader";

// ===========================================================================

class ClubCoachRequestsAPIComponent extends React.Component {
  componentDidMount() {
    console.log("fefe")
    this.props.setIsFething(true);

    Promise.all([this.API.GetUnapprovedCoaches()]).then((results) => { 
      this.props.setCoaches(results[0].data.users);
      this.props.setIsFething(false);
    });
  }

  // ===========================================================================

  API = {
    //   GET   GET   GET   GET   GET   GET   GET   GET   GET   GET
    GetUnapprovedCoaches: () => {
      return axios({
        method: "GET",
        url: "/club/GetUnapprovedCoaches?clubId=" + this.props.match.params.clubId,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },

    // UPDATE    UPDATE    UPDATE    UPDATE    UPDATE    UPDATE    UPDATE

    ApproveCoachRequest: (CoachId) => {
      
      return axios({
        method: "PUT",
        url: "/club/ApproveCoachRequest",
        data: {
          clubId: this.props.match.params.clubId,
          CoachId: CoachId,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    // DELETE   DELETE   DELETE   DELETE   DELETE   DELETE   DELETE   DELETE
    DeleteClubCoach: (CoachId) => {
      return axios({
        method: "PUT",
        url: "/club/DeleteClubCoach",
        data: {
          clubId: this.props.match.params.clubId,
          CoachId: CoachId,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
  };

  // ===========================================================================

  render() {
    return <>{this.props.IsFetching ? <BtnPreloader preloaderColor="dark" /> : <ClubCoachRequests {...this.props} API={this.API} />}</>;
  }
}

// ===========================================================================

let mapStateToProps = (state) => {
  return {
    coaches: state.clubCoachRequests.coaches,

    IsFetching: state.clubCoachRequests.IsFetching,
    //_____________
    pageSize: state.clubCoachRequests.pageSize,
    TotalProductsCount: state.clubCoachRequests.TotalProductsCount,
    currentPage: state.clubCoachRequests.currentPage,

    // _____________________________________
    isAuthorized: state.auth.isAuthorized,
  };
};

// ===========================================================================

let withURLDataComponentContainer = withRouter(ClubCoachRequestsAPIComponent);

// ===========================================================================

const ClubCoachRequestsConteiner = connect(mapStateToProps, {
  setCoaches,
  setIsFething,
  setCurrentPage,
  setTotalProductsCount,
})(withURLDataComponentContainer);

// ===========================================================================

export default ClubCoachRequestsConteiner;
