import React from "react";
import CarouselItem from "./components/CarouselItem";
import AddPhoto from "./components/AddPhoto";
import BigPreloader from "../../common/preloaders/BigPreloader";
import SwitchIsEditMode from "./components/SwitchIsEditMode";

function Images(props) {
  if (!props.clubImages)
    return (
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
        <div className=" w-100 h-100 rounded-3 border d-flex justify-content-center align-items-center">
          <BigPreloader preloaderColor={"danger"} />
        </div>
      </div>
    );
    // data-bs-interval="5000"  data-bs-touch="true"
  return (
    <>
      <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-1 "+(props.isEditMode ? " " : "")}>
        <SwitchIsEditMode {...props} />
        <div id="carouselExampleControls" className="carousel carousel-fade slide club-images-h w-100 "  data-bs-ride="carousel">
          <div className="carousel-inner w-100 h-100 shadow-lg">
            {props.clubImages.map((photoData, i) => {
              let activeOrEmpty = i === 0 ? "active" : "";
              return <CarouselItem key={`CarouselItem${i}`} {...props} photoData={photoData} activeOrEmpty={activeOrEmpty} />;
            })}
          </div>
          <button className="carousel-control-prev " type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span className="carousel-control-prev-icon   rounded-circle p-3 " aria-hidden="true"></span>
            
          </button>
          <button className="carousel-control-next  " type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span className="carousel-control-next-icon   rounded-circle   p-3" aria-hidden="true"></span> 
          </button>
        </div>
        {props.isEditMode ? <AddPhoto {...props} /> : <></>}
      </div>
    </>
  );
}

export default Images;
