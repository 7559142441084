import React from "react";

let SwitchIsEditMode = (props) => {
  if ( props.clubCoachesIdArray.indexOf(  props.match.params.clubId ) === -1&&! props.isAdmin ) return <></>;
  let swithing = (checkedValue) => {
    Promise.all([ props.API.GetBasicInfo()]).then((results) => {
      props.setPhoneNumber(results[0].data.phoneNumber);
      props.setAddress(results[0].data.address);
      props.setEmail(results[0].data.email);
      props.setCity(results[0].data.city);
    });
    props.setIsEditMode(checkedValue);
  };
  // ============================================================
  return (
    <div className="d-flex justify-content-end">
      <div className="form-check form-switch  ">
        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
          ✐
        </label>
        <input className="form-check-input" onChange={(e) => swithing(e.target.checked)} type="checkbox" id="flexSwitchCheckDefault" />
      </div>
    </div>
  );
  // ============================================================
};

export default SwitchIsEditMode;
