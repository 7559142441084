import React from "react";
import Preloader from "../../../common/preloaders/Preloader";
import TEXTJSON from "../../../../data/text.json";
let AddPhoto = (props) => {
  let setNewPhotoDataUrlFromInputFile = (iputFile) => {
    var file = iputFile.target.files[0];
    var reader = new FileReader();

    reader.onloadend = function () {
      props.setNewPhohtoDataUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      props.setDataUtiFromInputFile("");
    }
  };

  let updateUserAvatar = () => {
    props.setIsAdded(true);
    Promise.all([props.API.AddToImages(props.dataUtiFromInputFile)]).then((results) => {
      props.setIsAdded(false);
      if (results[0].data === "successfully") alert(TEXTJSON.FOTO_DOBAVLENO);
      else alert(TEXTJSON.universal_error);
      Promise.all([props.API.GetImages()]).then((results) => {
        console.log(results[0].data);
        if (results[0].data.clubImages) props.setClubImage(results[0].data.clubImages);
      });
    });
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        <button type="button" className="btn btn-primary my-2  rounded-pill" data-bs-toggle="modal" data-bs-target="#updateAvatarModal">
          {props.isAdded ? <Preloader /> : TEXTJSON.DOBAVIT_FOTO}
        </button>
      </div>

      <div className="modal fade" id="updateAvatarModal" tabindex="-1" aria-labelledby="updateAvatarModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="updateAvatarModalLabel">
                Modal title
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <input className="form-control" onChange={(e) => setNewPhotoDataUrlFromInputFile(e)} type="file" id="formFile" />
              <img src={props.newPhohtoDataUrl} className="w-100 p-3" alt="" />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                {TEXTJSON.zakryt}
              </button>
              <button type="button" onClick={() => updateUserAvatar()} className="btn btn-primary">
                {props.isAdded ? <Preloader /> : TEXTJSON.SOHRANIT}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPhoto;
