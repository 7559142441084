import { combineReducers, createStore } from 'redux'
import headerReducer from './header-reducer'
import logRegPageReducer from './logRegPageReducer'
import allClubsReducer from './allClubsReducer'
import editorClubReducer from './editorClubReducer'
import authReducer from './auth-reducer'
import personalAccountReducer from './personal-account-reducer'
import ProfileEditReducer from './profile-edit-reducer'
import clubBasicInfoReducer from './club-basicInfo-reducer'
import clubImagesReducer from './club-images-reducer'
import clubScheduleReducer from './club-schedule-reducer'
import clubPriceListReducer from './club-priceList-reducer'
import clubInteractionButtonsAreaReducer from './club-interactionButtonsArea-reducer'
import clubFighterRequestsReducer from './clubFighterRequests-reducer'
import clubCoachRequestsReducer from './clubCoachRequests-reducer'
import clubCoachesReducer from './clubCoaches-reducer'
import clubFightersReducer from './clubFighters-reducer'
import adminUserListReducer from './admin-userList-reducer'
import mainsReducer from './main-reducer'
import starsListReducer from './starsList-reducer'
import coachesListReducer from './coachesList-reducer'
import tournamentsReducer from './tournaments-reducer'
import socialMediaReducer from './socialMedia-reducer'
import articleCreaterReducer from './article-creater-reducer'
import newsListReducer from './news-list-reducer'
import articleEditorReducer from './article-editor-reducer'
import ArticleReaderWithEditorReducer from './article-reader-with-editor-reducer'
import mediaListReducer from './mediaList-reducer'
import mediaReducer from './media-reducer'
import articleReducer from './article-reducer'
import photoListReducer from './photoList-reducer'
import eventImagesReducer from './eventImages-reducer'
import clubCoachesLisrViewReducer from './club-coachesLisrView-reducer'
import footerReducer from './footer-reducer'
import adminReducer from './admin-reducer'



let reducers = combineReducers({
  main : mainsReducer,
  header: headerReducer,
  logRegPage: logRegPageReducer,
  clubCoachesLisrView: clubCoachesLisrViewReducer,
  allClubs: allClubsReducer,
  editorClub: editorClubReducer,
  auth: authReducer,
  personalAccount: personalAccountReducer,
  profileEdit: ProfileEditReducer,
  clubBasicInfo: clubBasicInfoReducer,
  clubImages: clubImagesReducer,
  clubSchedule: clubScheduleReducer,
  clubPriceList: clubPriceListReducer,
  clubInteractionButtonsArea: clubInteractionButtonsAreaReducer,
  clubFighterRequests : clubFighterRequestsReducer,
  clubCoachRequests : clubCoachRequestsReducer,
  clubCoaches : clubCoachesReducer,
  clubFighters : clubFightersReducer,
  adminUserList : adminUserListReducer,
  starsList : starsListReducer,
  coachesList : coachesListReducer,
  tournaments : tournamentsReducer,
  socialMedia : socialMediaReducer,
  articleEditor : articleEditorReducer,
  newsList : newsListReducer,
  mediaList : mediaListReducer,
  media : mediaReducer,
  articleCreater : articleCreaterReducer,
  articleReaderWithEditor : ArticleReaderWithEditorReducer,
  eventImages : eventImagesReducer,
  article : articleReducer,
  photoList : photoListReducer,
  footer : footerReducer,
  admin : adminReducer,
})

let store = createStore(reducers)
export default store
