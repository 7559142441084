import React from "react";
import Preloader from "../../../common/preloaders/Preloader";
import TEXTJSON from "../../../../data/text.json";
// ============================================================
let InputAddress = (props) => {
  // ============================================================
  let save = () => {
    props.setIsUpdating("address", true);
    Promise.all([props.API.UpdateAddress()]).then((results) => {
      alert(TEXTJSON.ADRES_USPESHCHNO_IZMENEN);
      props.setIsUpdating("address", false);
      Promise.all([props.API.GetBasicInfo()]).then((results) => {
        //
      });
    });
  };
  // ============================================================
  let isPhoneAddress = props.address ? true : false;
  if (!isPhoneAddress && !props.address) return <></>;
  // ============================================================
  return (
    <>
      <div className="  d-flex align-items-center       ">
        <img alt="" className="me-2" width="20" src="/images/icons/address.svg" />
        {props.isEditMode ? (
          <div className="input-group   ">
            <input value={props.address} onChange={(event) => props.setAddress(event.target.value)} type="text" className="form-control   " />
            <button onClick={() => save()} className="btn  btn-primary" type="button" id="button-addon2">
              {props.isUpdating.address ? <Preloader preloaderColor={"white"} /> : <img alt="" height="20" src="/images/icons/save.svg" />}
            </button>
          </div>
        ) : (
          <p className=" m-0 fw-bold ">{props.address}</p>
        )}
      </div>
    </>
  );
  // ============================================================
};
// ============================================================
export default InputAddress;
