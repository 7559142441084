/************************************************************************************* */
let initialState = {
  isFetching: true,
  article: "",
  editorState:"",
  }
  /************************************************************************************* */
  const articleEditorReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SN/ARTICLE-EDITOR-REDUCER/SET-ISFETCHING':
        return { ...state, isFetching: action.value }

      case 'SN/ARTICLE-EDITOR-REDUCER/SET-ARTICLE':
        return { ...state, article:action.value }

        case 'SN/ARTICLE-EDITOR-REDUCER/SET-EDITORSTATE':
          console.log(action.value,"fuck") 
          return { ...state, editorState:action.value }
  
      default:
        return state
    }
  }
  /************************************************************************************* */
  export const setIsFetching = (value) => ({
    type: 'SN/ARTICLE-EDITOR-REDUCER/SET-ISFETCHING', 
    value,
  })

  export const setArticle = (value) => ({
    type: 'SN/ARTICLE-EDITOR-REDUCER/SET-ARTICLE',
    value,
  })

  export const setEditorState = (value) => ({
    type: 'SN/ARTICLE-EDITOR-REDUCER/SET-EDITORSTATE',
    value,
  })
  
  /************************************************************************************* */
  export default articleEditorReducer
  