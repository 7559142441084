import React from "react";
import { connect } from "react-redux";
import ClubFighterRequests from "./ClubFighterRequests";
import { withRouter } from "react-router-dom";
import { setFighters, setIsFething, setCurrentPage, setTotalProductsCount } from "../../redux/clubFighterRequests-reducer";
import * as axios from "axios";
import BtnPreloader from "../common/preloaders/BtnPreloader";

// ===========================================================================

class ClubFighterRequestsAPIComponent extends React.Component {
  componentDidMount() {
    console.log("fefe")
    this.props.setIsFething(true);

    Promise.all([this.API.GetUnapprovedFighter()]).then((results) => { 
      this.props.setFighters(results[0].data.users);
      this.props.setIsFething(false);
    });
  }

  // ===========================================================================

  API = {
    //   GET   GET   GET   GET   GET   GET   GET   GET   GET   GET
    GetUnapprovedFighter: () => {
      return axios({
        method: "GET",
        url: "/club/GetUnapprovedFighter?clubId=" + this.props.match.params.clubId,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },

    // UPDATE    UPDATE    UPDATE    UPDATE    UPDATE    UPDATE    UPDATE

    ApproveFighterRequest: (fightersId) => {
      return axios({
        method: "PUT",
        url: "/club/ApproveFighterRequest",
        data: {
          clubId: this.props.match.params.clubId,
          FightersId: fightersId,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    // DELETE   DELETE   DELETE   DELETE   DELETE   DELETE   DELETE   DELETE
    DeleteClubFighter: (fightersId) => {
      console.log(this.props.match.params.clubId,"AAAAAA",fightersId)
      return axios({
        method: "PUT",
        url: "/club/DeleteClubFighter",
        data: {
          clubId: this.props.match.params.clubId,
          FighterId: fightersId,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
  };

  // ===========================================================================

  render() {
    return <>{this.props.IsFetching ? <BtnPreloader preloaderColor="dark" /> : <ClubFighterRequests {...this.props} API={this.API} />}</>;
  }
}

// ===========================================================================

let mapStateToProps = (state) => {
  return {
    fighters: state.clubFighterRequests.fighters,

    IsFetching: state.clubFighterRequests.IsFetching,
    //_____________
    pageSize: state.clubFighterRequests.pageSize,
    TotalProductsCount: state.clubFighterRequests.TotalProductsCount,
    currentPage: state.clubFighterRequests.currentPage,

    // _____________________________________
    isAuthorized: state.auth.isAuthorized,
  };
};

// ===========================================================================

let withURLDataComponentContainer = withRouter(ClubFighterRequestsAPIComponent);

// ===========================================================================

const ClubFighterRequestsConteiner = connect(mapStateToProps, {
  setFighters,
  setIsFething,
  setCurrentPage,
  setTotalProductsCount,
})(withURLDataComponentContainer);

// ===========================================================================

export default ClubFighterRequestsConteiner;
