/************************************************************************************* */
let initialState = {
  users: [],

  IsFetching: true,
  //_____________
  pageSize: 9,
  totalProductsCount: 0,
  currentPage: 1,
}
/************************************************************************************* */
const starsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SN/STARS-LIST-REDUCER/SET-USERS':
      return { ...state, users: action.value }

    case 'SN/STARS-LIST-REDUCER/SET-IS-FETHING':
      console.log(action.value)
      return { ...state, IsFetching: action.value }

    case 'SN/STARS-LIST-REDUCER/SET-TOTAL-PRODUCTS-COUNT':
      return { ...state, totalProductsCount: action.value }
    case 'SN/STARS-LIST-REDUCER/SET-CURRENT-PAGE':
      console.log("FUCK")
      return { ...state, currentPage: action.value }

    default:
      return state
  }
}
/************************************************************************************* */
export const setUsers = (value) => ({
  type: 'SN/STARS-LIST-REDUCER/SET-USERS',
  value,
})

export const setIsFething = (value) => ({
  type: 'SN/STARS-LIST-REDUCER/SET-IS-FETHING',
  value,
})

export const setCurrentPage = (value) => ({
  type: 'SN/STARS-LIST-REDUCER/SET-CURRENT-PAGE',
  value,
})
export const setTotalProductsCount = (value) => ({
  type: 'SN/STARS-LIST-REDUCER/SET-TOTAL-PRODUCTS-COUNT',
  value,
})

/************************************************************************************* */
export default starsListReducer
