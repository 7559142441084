/************************************************************************************* */
let initialState = {
    user: [],
  }
  /************************************************************************************* */
  const articleReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SN/TAMPLET-REDUCER/SET-USER':
        return { ...state, ...action.user }
  
      default:
        return state
    }
  }
  /************************************************************************************* */
  export const setUser = (user) => ({
    type: 'SN/TAMPLET-REDUCER/SET-USER',
    user,
  })
  
  /************************************************************************************* */
  export default articleReducer
  