import React from "react";
import ArticleReaderWithEditorConteiner from "../common/ArticleReaderWithEditor/ArticleReaderWithEditorConteiner";

function TrusteesBoard(props) {
  return (
    <article>
        <ArticleReaderWithEditorConteiner isBlock={true} searchingArticleName={"trustees_board"}/>
    </article>
  );
}

export default TrusteesBoard;
