import React from "react";
import { connect } from "react-redux";

import { setJsonData } from "../../redux/footer-reducer";
import * as axios from "axios";
import Footer from "./Footer";
import BigPreloader from "../common/preloaders/BigPreloader";
// ================================================================================
class FooterAPIComponent extends React.Component {
  // ================================================================================
  componentDidMount() {
    let that = this;

    axios({
      method: "GET",
      url: "/jsonData/site.json",
    })
      .then((response) => { 
        that.props.setJsonData(response.data);
      })
      .catch((error) => console.log(error.response));
  }
  // ================================================================================
  API = {
    // ___________
  };
  // ================================================================================
  render() {
    return <>{this.props.isFething ? <BigPreloader preloaderColor="danger" /> : <Footer {...this.props} API={this.API} />}</>;
  }
}
// ================================================================================
let mapStateToProps = (state) => {
  return {
    jsonData: state.footer.jsonData,
  };
};
// ================================================================================
const FooterConteiner = connect(mapStateToProps, {
  setJsonData,
})(FooterAPIComponent);
// ================================================================================
export default FooterConteiner;
