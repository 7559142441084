import React from "react";
import TEXTJSON from "../../../../data/text.json";
let EmailPage = (props) => {
  let InputValidOrNo;
  if (props.errorMessage) {
    InputValidOrNo = "is-invalid";
  } else {
    InputValidOrNo = "";
  }
  return (
    <div className="mb-3">
      <label htmlFor="FormControlInputEmail" className="form-label">
       {TEXTJSON.ADRES_ELEKTRONNOJ_POCHTY} 
      </label>
      <input value={props.inputEmail} onChange={(event) => props.setInputEmail(event.target.value)} type="email" className={"form-control "+InputValidOrNo} id="FormControlInputEmail" aria-describedby="emailHelp" placeholder={TEXTJSON.EMAIL_TAMPLET} required />
      <div className="invalid-feedback">* {props.errorMessage}</div> 
    </div>
  );
};

export default EmailPage;
