import { messageInputNumberError } from '../util/messege'

let initialState = {
  //ДАННЫЕ ЗОНЫ ВВОДА НОМЕРА
  inputCityCode: '',
  inputFirstPartSubscriberNumber: '',
  inputSecondPartSubscriberNumber: '',
  inputThirdPartSubscriberNumber: '',
  //ДАННЫЕ ЗОНЫ ВВОДА КОДА
  inputCodeFirst: '',
  inputCodeSecond: '',
  inputCodeThird: '',
  inputCodeFourth: '',
  //ДАННЫЕ ЗОНЫ ВВОДА ПАРОЛЯ
  inputPassword: '',
  //ДАННЫЕ ЗОНЫ ПРОВЕРКА ПОРОЛЯ
  inputPasswordConfirmation:'',
  //ОШИБКА ЗОНЫ ВВОДА ПАРОЛЯ
  errorPassword: '',
  // ПРЕДЫДУЩАЯ СТРАНЦА
  previousPage: '',
  // ТЕКУЩАЯ СТРАНЦА
  currentPage: '',
}

const logRegPageReducer = (state = initialState, action) => {
  switch (action.type) {
    /*
     **********************
     ** setInputCityCode **
     **********************
     */
    case 'SN/LOG-REG-PAGE/SET-INPUT-CITY-CODE':
      if (isNaN(action.value) || action.value.length > 3) {
        messageInputNumberError(action.value)
        return { ...state }
      } else {
        return { ...state, inputCityCode: action.value }
      }
    /*
     ***************************************
     ** setInputFirstPartSubscriberNumber **
     ***************************************
     */
    case 'SN/LOG-REG-PAGE/SET-INPUT-FIRST-PART-SUBSCRIBER-NUMBER':
      if (isNaN(action.value) || action.value.length > 3) {
        messageInputNumberError(action.value)
        return { ...state }
      } else {
        return { ...state, inputFirstPartSubscriberNumber: action.value }
      }
    /*
     ****************************************
     ** setInputSecondPartSubscriberNumber **
     ****************************************
     */
    case 'SN/LOG-REG-PAGE/SET-INPUT-SECOND-PART-SUBSCRIBER-NUMBER':
      if (isNaN(action.value) || action.value.length > 2) {
        messageInputNumberError(action.value)
        return { ...state }
      } else {
        return { ...state, inputSecondPartSubscriberNumber: action.value }
      }
    /*
     ***************************************
     ** setInputThirdPartSubscriberNumber **
     ***************************************
     */
    case 'SN/LOG-REG-PAGE/SET-INPUT-THIRD-PART-SUBSCRIBER-NUMBER':
      if (isNaN(action.value) || action.value.length > 2) {
        messageInputNumberError(action.value)
        return { ...state }
      } else {
        return { ...state, inputThirdPartSubscriberNumber: action.value }
      }
    /*
     ********************
     ** setCurrentPage **
     ********************
     */
    case 'SN/LOG-REG-PAGE/SET-CURRENT-PAGE':
      return { ...state, currentPage: action.value }
    /*
    /*
     *********************
     ** setPreviousPage **
     *********************
     */
    case 'SN/LOG-REG-PAGE/SET-PREVIOUS-PAGE':
      return { ...state, previousPage: action.value }

    /*
     ***********************
     ** setInputCodeFirst **
     ***********************
     */
    case 'SN/LOG-REG-PAGE/SET-INPUT-CODE-FIRST':
      if (isNaN(action.value) || action.value.length > 1) {
        messageInputNumberError(action.value)
        return { ...state }
      } else {
        return { ...state, inputCodeFirst: action.value }
      }
    /*
     ************************
     ** setInputCodeSecond **
     ************************
     */
    case 'SN/LOG-REG-PAGE/SET-INPUT-CODE-SECOND':
      if (isNaN(action.value) || action.value.length > 1) {
        messageInputNumberError(action.value)
        return { ...state }
      } else {
        return { ...state, inputCodeSecond: action.value }
      }
    /*
     ***********************
     ** setInputCodeThird **
     ***********************
     */
    case 'SN/LOG-REG-PAGE/SET-INPUT-CODE-THIRD':
      if (isNaN(action.value) || action.value.length > 1) {
        messageInputNumberError(action.value)
        return { ...state }
      } else {
        return { ...state, inputCodeThird: action.value }
      }
    /*
     ************************
     ** setInputCodeFourth **
     ************************
     */
    case 'SN/LOG-REG-PAGE/SET-INPUT-CODE-FOURTH':
      if (isNaN(action.value) || action.value.length > 1) {
        messageInputNumberError(action.value)
        return { ...state }
      } else {
        return { ...state, inputCodeFourth: action.value }
      }
    /*
     ************************
     ** setInputPassword **
     ************************
     */
    case 'SN/LOG-REG-PAGE/SET-INPUT-PASSWORD':
      
      return { ...state, inputPassword: action.value,errorPassword: '' }
    /*
    /*
     ************************
     ** setInputPasswordConfirmation **
     ************************
     */
     case 'SN/LOG-REG-PAGE/SET-INPUT-PASSWORD-CONFIRMATION':
      
      return { ...state, inputPasswordConfirmation: action.value }
    /*
     ************************
     ** setErrorPassword **
     ************************
     */
    case 'SN/LOG-REG-PAGE/SET-ERROR-PASSWORD':
      
      return { ...state, errorPassword: action.value }
    /*
    
     ************
     ** DEFULT **
     ************
     */
    default:
      return state
  }
}

export const setInputCityCode = (value) => ({
  type: 'SN/LOG-REG-PAGE/SET-INPUT-CITY-CODE',
  value,
})

export const setInputFirstPartSubscriberNumber = (value) => ({
  type: 'SN/LOG-REG-PAGE/SET-INPUT-FIRST-PART-SUBSCRIBER-NUMBER',
  value,
})

export const setInputSecondPartSubscriberNumber = (value) => ({
  type: 'SN/LOG-REG-PAGE/SET-INPUT-SECOND-PART-SUBSCRIBER-NUMBER',
  value,
})

export const setInputThirdPartSubscriberNumber = (value) => ({
  type: 'SN/LOG-REG-PAGE/SET-INPUT-THIRD-PART-SUBSCRIBER-NUMBER',
  value,
})

export const setSurrentPage = (value) => ({
  type: 'SN/LOG-REG-PAGE/SET-CURRENT-PAGE',
  value,
})

export const setPreviousPage = (value) => ({
  type: 'SN/LOG-REG-PAGE/SET-PREVIOUS-PAGE',
  value,
})

export const setInputCodeFirst = (value) => ({
  type: 'SN/LOG-REG-PAGE/SET-INPUT-CODE-FIRST',
  value,
})

export const setInputCodeSecond = (value) => ({
  type: 'SN/LOG-REG-PAGE/SET-INPUT-CODE-SECOND',
  value,
})

export const setInputCodeThird = (value) => ({
  type: 'SN/LOG-REG-PAGE/SET-INPUT-CODE-THIRD',
  value,
})

export const setInputCodeFourth = (value) => ({
  type: 'SN/LOG-REG-PAGE/SET-INPUT-CODE-FOURTH',
  value,
})

export const setInputPassword = (value) => ({
  type: 'SN/LOG-REG-PAGE/SET-INPUT-PASSWORD',
  value,
})

export const setInputPasswordConfirmation = (value) => ({
  type: 'SN/LOG-REG-PAGE/SET-INPUT-PASSWORD-CONFIRMATION',
  value,
})

export const setErrorPassword = (value) => ({
  type: 'SN/LOG-REG-PAGE/SET-ERROR-PASSWORD',
  value,
})

export default logRegPageReducer
