import React from "react";
import { connect } from "react-redux";

import { setIsFetching, setArticle, setArticleId, setArticleName, setArticleTitle,setArticleCode, setArticleDate, setEditorState,setIsBtnLoad } from "../../../redux/article-reader-with-editor-reducer";
import TEXTJSON from "../../../data/text.json";
import * as axios from "axios";
import ArticleReaderWithEditor from "./ArticleReaderWithEditor";
import BigPreloader from "../preloaders/BigPreloader";
// ================================================================================
class ArticleReaderWithEditorAPIComponent extends React.Component {
  // ================================================================================
  componentDidMount() {
    this.props.setArticleName("");
    this.props.setArticle("");
    this.props.setArticleCode("");
    this.props.setIsFetching(true);

    Promise.all([this.API.getArticle()]).then((results) => {
      if (results[0]) { 
        console.log(results[0].data)
        this.props.setArticleId(results[0].data.id);
        this.props.setArticleName(results[0].data.name);
        this.props.setArticleTitle(results[0].data.title);
        this.props.setArticleDate(results[0].data.date);
        this.props.setArticle(results[0].data.htmlText);
        this.props.setArticleCode(results[0].data.inlineCode);
      this.props.setIsFetching(false);

      }
      this.props.setIsFetching(false);
    });
  }
  // ================================================================================
  API = {
    getArticleById: (articleId) => {

        return axios({
          method: "GET",
          url: "/api/get/article/TakeById/" + articleId,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          },
        })
          .then((response) => response)
          .catch((error) =>{console.log(error.response) ;window.alert(TEXTJSON.universal_error)});
      },
    getArticle: (newName) => {

      return axios({
        method: "GET",
        url: "/api/get/article/TakeByName/" + (newName?newName: this.props.searchingArticleName),
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) =>{console.log(error.response) ;window.alert(TEXTJSON.universal_error)});
    },
    edit: () => {
      console.log(this.props.article, this.props.articleName);
      return axios({
        method: "PUT",
        url: "/api/put/article/Change",
        data: {
          Id: this.props.articleId,
          Name: this.props.articleName,
          Title: this.props.articleTitle,
          Date: this.props.articleDate,
          HtmlText: this.props.article,
          Code: this.props.articleCode,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    // ___________
  };
  // ================================================================================
  render() {
    return <>{this.props.isFething ? <BigPreloader preloaderColor="danger" /> : <ArticleReaderWithEditor {...this.props} API={this.API} />}</>;
  }
}
// ================================================================================
let mapStateToProps = (state) => {
  return {
    isFetching: state.articleReaderWithEditor.isFetching,

    articleId: state.articleReaderWithEditor.articleId,
    articleName: state.articleReaderWithEditor.articleName,
    articleTitle: state.articleReaderWithEditor.articleTitle,
    articleDate: state.articleReaderWithEditor.articleDate,
    article: state.articleReaderWithEditor.article,
    isBtnLoad: state.articleReaderWithEditor.isBtnLoad,
    editorState: state.articleReaderWithEditor.editorState,
    articleCode: state.articleReaderWithEditor.articleCode,

    isAuthorized: state.auth.isAuthorized,
    clubCoachesIdArray: state.auth.clubCoachesIdArray, 
    isCoach: state.auth.isCoach,
    isAdmin: state.auth.isAdmin,
  };
};
// ================================================================================
const ArticleReaderWithEditorConteiner = connect(mapStateToProps, {
  setIsFetching,
  setArticle,
  setArticleId,
  setArticleName,
  setArticleTitle,setArticleCode,
  setArticleDate,
  setEditorState,setIsBtnLoad
})(ArticleReaderWithEditorAPIComponent);
// ================================================================================
export default ArticleReaderWithEditorConteiner;
