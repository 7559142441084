import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setIsFetching, setArticle, setArticleId, setArticleName, setArticleTitle, setArticleDate, setEditorState,setIsBtnLoad } from "../../redux/media-reducer";
import TEXTJSON from "../../data/text.json";

import * as axios from "axios";
import Media from "./Media"; 
// ================================================================================
class MediaAPIComponent extends React.Component {
  // ================================================================================
  componentDidMount() { 
    this.props.setArticleName("");
    this.props.setArticle("");
    this.props.setIsFetching(true);
    Promise.all([this.API.getArticle()]).then((results) => {
        console.log(results[0].data)
        if (results[0]) {
            console.log(results[0].data)
          this.props.setArticleId(results[0].data.id);
          this.props.setArticleName(results[0].data.name);
          this.props.setArticleTitle(results[0].data.title);
          this.props.setArticleDate(results[0].data.date);
          this.props.setArticle(results[0].data.html);
        this.props.setIsFetching(false);
  
        }
        this.props.setIsFetching(false);
      });
  }
  // ================================================================================
  API = {
    getArticle: (newName) => { 
        return axios({
          method: "GET",
          url: "/api/get/article/TakeMediaByName/" + this.props.match.params.mediaId,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          },
        })
          .then((response) => response)
          .catch((error) =>{console.log(error.response) ;window.alert(TEXTJSON.universal_error)});
      },
    // ___________
  };
  // ================================================================================
  render() {
    return   <Media  {...this.props} API={this.API}   /> ; 
  
  }
}
// ================================================================================
let mapStateToProps = (state) => {
  return {
    isFetching: state.media.isFetching,

    articleId: state.media.articleId,
    articleName: state.media.articleName,
    articleTitle: state.media.articleTitle,
    articleDate: state.media.articleDate,
    article: state.media.article,
    isBtnLoad: state.media.isBtnLoad,
    editorState: state.media.editorState,
  };
};
let withURLDataComponentContainer = withRouter(MediaAPIComponent);
// ================================================================================
const MediaConteiner = connect(mapStateToProps, {
    setIsFetching, setArticle, setArticleId, setArticleName, setArticleTitle, setArticleDate, setEditorState,setIsBtnLoad
})(withURLDataComponentContainer);
// ================================================================================
export default MediaConteiner;
