import React from 'react'

let InputSwith = (props) => { 
  return (
    <>
      <div className="form-check form-switch">
        {props.lable ? (
          <label className="form-check-label" htmlFor={props.swithName}>
            {props.lable}
          </label>
        ) : (
          <></>
        )}

        <input  
        onChange={(e) => props.setItem(e.target.checked,props.typeFotMethod)}
          className="form-check-input"
          type="checkbox"
          id={props.swithName}
        />
      </div>
    </> 
  )
}

export default InputSwith
