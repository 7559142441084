import React from "react";
import TEXTJSON from "../../../../data/text.json";
let FinishPage = (props) => {
  props.mySetTimeout();
  return (
    <div className="d-flex flex-column align-items-center">
      <p className="  display-6 text-center">{TEXTJSON.VY_USPESHNO_AVTOREZIROVANNY} </p>
    </div>
  );
};

export default FinishPage;
