import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom"; 
import { setArticle } from "../../redux/article-editor-reducer";
import * as axios from "axios"; 
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"; 
import EmbedContainer from "react-oembed-container"; 

// import RichTextEditor from "../RichTextEditor/RichTextEditor";
// ================================================================================
class ArticleEditorAPIComponent extends React.Component {
  // ================================================================================
  componentDidMount() {
    console.log(this.props) 
    // this.state = {editorState: EditorState.createEmpty()};
    // Promise.all([this.API.GetProfileInfo()]).then((results) => {
    // });
  }
  // ================================================================================
  API = {
    GetProfileInfo: () => {
      return axios({
        method: "GET",
        url: "/Profile/GetProfileInfo",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    // ___________
  };
  // ================================================================================
  render() {
    return (
      <>
        <div className=" container-fluid">
          <div className="row gy-4 justify-content-center">
            
            <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">
              <EmbedContainer markup={this.props.article}>
                {/* for example, */}
                <article className="my-news">
                  <div></div>
                  <div dangerouslySetInnerHTML={{ __html: this.props.article }} />
                </article>
              </EmbedContainer>
            </div>
            <div className="col-12">
              <CKEditor
                editor={ClassicEditor}
                data={this.props.article}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                config={{
                  mediaEmbed: {
                    previewsInData: true,
                  },
                  ckfinder: {
                    // Upload the images to the server using the CKFinder QuickUpload command
                    // You have to change this address to your server that has the ckfinder php connector
                    uploadUrl: "/club/UploadImage",
                  },
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.props.setArticle(data);
                  console.log({ event, editor, data });
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
            </div>
          </div>
        </div>
      </>
    ); 
  }
}
// ================================================================================
let mapStateToProps = (state) => {
  return {
    article: state.articleEditor.article,
  };
};
let withURLDataComponentContainer = withRouter(ArticleEditorAPIComponent);
// ================================================================================
const ArticleEditorConteiner = connect(mapStateToProps, {
  setArticle,
})(withURLDataComponentContainer);
// ================================================================================
export default ArticleEditorConteiner;
