import React from "react";
import UserList from "../common/UserList/UserList";
import TEXTJSON from "../../data/text.json";
function ClubFighters(props) {
  let setNewUserTrainerId = (coachId, fighterId) => {
    props.setIsFething(true);
    Promise.all([props.API.ChangeCoach(fighterId, coachId)]).then((results) => {
      if (results[0].data === "successfully") {
        Promise.all([props.API.GetApprovedFighter()]).then((results) => {
          props.setFighters(results[0].data.users);
          props.setIsFething(false);
        });
      } else console.log(TEXTJSON.universal_error);
      props.setIsFething(false);
    });
  };

  let deleteCoach = (fighterId) => {
    props.setIsFething(true);
    Promise.all([props.API.DeleteClubFighter(fighterId)]).then((results) => {
      if (results[0].data === "successfully") {
        Promise.all([props.API.GetApprovedFighter()]).then((results) => {
          props.setFighters(results[0].data.users);
          props.setIsFething(false);
        });
      } else console.log(TEXTJSON.universal_error);
      props.setIsFething(false);
    });
  };

  let changeStarStatus = (fighterId) => {
    props.setIsFething(true);
    Promise.all([props.API.ChangeProStatus(fighterId)]).then((results) => {
      if (results[0].data === "successfully") {
        Promise.all([props.API.GetApprovedFighter()]).then((results) => {
          props.setFighters(results[0].data.users);
          props.setIsFething(false);
        });
      } else console.log(TEXTJSON.universal_error);
      props.setIsFething(false);
    });
  };

  if (props.fighters.length < 1) return <p className="display-2 text-danger    m-auto">{TEXTJSON.empty}</p>;
  return (
    <article className="d-flex flex-column   ">
      <p className="display-6 ps-2">{TEXTJSON.BOJCY_KLUBA}</p>
      {/* {console.log(props.fighters)} */}
      <UserList listTitle={TEXTJSON.SPISOK_BOJCOV_KLUBA} coachesFighter={props.coaches} users={props.fighters} setNewUserTrainerAct={setNewUserTrainerId} changeStatusAct={changeStarStatus} deleteAct={deleteCoach} />
    </article>
  );
}

export default ClubFighters;
