import React from "react";
import { connect } from "react-redux";
import TEXTJSON from "../../data/text.json";
import {
  //
  setUsers,
  setIsFething,
  setCurrentPage,
  setTotalProductsCount,
  //
  setIsPaginationLoad
} from "../../redux/coachesList-reducer";
import * as axios from "axios";
import CoachesList from "./CoachesList";
import BigPreloader from "../common/preloaders/BigPreloader";
import BigTextAlert from "../common/BigTextAlert/BigTextAlert";
// ================================================================================
class CoachesListAPIComponent extends React.Component {
  // ================================================================================
  componentDidMount() {
    this.props.setIsFething(true);
    this.props.setUsers([]); 

    Promise.all([this.props.setUsers([])]).then(( ) => {
      Promise.all([this.props.setCurrentPage(1)]).then(( ) => {
        Promise.all([this.API.getStars()]).then((results) => {
          this.props.setUsers(results[0].data.users);
          this.props.setTotalProductsCount(results[0].data.usersCount);
          this.props.setIsFething(false);
        });
      }); 
    });  
  }  
  // ================================================================================
  API = {
    getStars: () => {
      return axios({
        method: "GET",
        url: "/api/get/user/OrderingWFilter?isCoach=true&page=" + this.props.currentPage + "&pageItemCount=" + this.props.pageSize,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => {
          console.log("error");
          return [];
        });
    },
    // ___________
  };
  // ================================================================================
  render() {
    return (
      <>
        {this.props.isFetching ? (
          <BigPreloader preloaderColor="danger" />
        ) : (
          <>
            <CoachesList   {...this.props} API={this.API} />
            {!this.props.users.length && <BigTextAlert textinner={TEXTJSON.TRENERA_OTUVSTUYUT} />}
          </>
        )}
      </>
    );
  }
}
// ================================================================================
let mapStateToProps = (state) => {
  return {
    users: state.coachesList.users,
    isFetching: state.coachesList.isFetching,
    //_____________
    pageSize: state.coachesList.pageSize,
    totalProductsCount: state.coachesList.totalProductsCount,
    currentPage: state.coachesList.currentPage,
    isPaginationLoad: state.coachesList.isPaginationLoad,
  };
};
// ================================================================================
const CoachesListConteiner = connect(mapStateToProps, {
  setUsers,
  setIsFething,
  setCurrentPage,
  setTotalProductsCount,
  setIsPaginationLoad
})(CoachesListAPIComponent);
// ================================================================================
export default CoachesListConteiner;
