/************************************************************************************* */
let initialState = {
  article: "",
  articleName: "",
  articleCode: "",
  isFetching: true,
  isBtnLoad:false
};
/************************************************************************************* */
const ArticleCreaterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SN/ARTICLE-CREATER-REDUCER/SET-ARTICLE":
      return { ...state, article: action.value };

    case "SN/ARTICLE-CREATER-REDUCER/SET-ARTICLE-NAME": 
      return { ...state, articleName: action.value };

      case "SN/ARTICLE-CREATER-REDUCER/SET-ARTICLE-CODE":
      return { ...state, articleCode: action.value };

    case "SN/ARTICLE-CREATER-REDUCER/SET-IS-FETCHING":
      return { ...state, isFetching: action.value };
      
    case "SN/ARTICLE-CREATER-REDUCER/SET-IS-IS-BTNLOAD":
      return { ...state, isBtnLoad: action.value };

    default:
      return state;
  }
};
/************************************************************************************* */
export const setArticle = (value) => ({
  type: "SN/ARTICLE-CREATER-REDUCER/SET-ARTICLE",
  value,
});

export const setArticleName = (value) => ({
  type: "SN/ARTICLE-CREATER-REDUCER/SET-ARTICLE-NAME",
  value,
});

export const setIsFetching = (value) => ({
  type: "SN/ARTICLE-CREATER-REDUCER/SET-IS-FETCHING",
  value,
});

export const setArticleCode = (value) => ({
  type: "SN/ARTICLE-CREATER-REDUCER/SET-ARTICLE-CODE",
  value,
});

export const setIsBtnLoad = (value) => ({
  type: "SN/ARTICLE-CREATER-REDUCER/SET-IS-IS-BTNLOAD",
  value,
});

/************************************************************************************* */
export default ArticleCreaterReducer;
