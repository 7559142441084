import React from "react";
import TEXTJSON from "../../../data/text.json";
let FromTo = (props) => {
  return (
    <div className="   p-2 d-flex flex-row justify-content-center align-items-center">
      <div className="me-3 ">{props.whatFilter} </div>

      <input value={props.firstItemValue} onChange={(e)=>props.setFirstItem(e.target.value,props.firstTypeFotMethod)} type={props.inputType} className="form-control" placeholder={TEXTJSON.OT} max={300} style={{ width: "5em" }} />
      <div className="mx-2"> - </div>

      <input value={props.secondItemValue} onChange={(e)=>props.setSecondItem(e.target.value,props.secondTypeFotMethod)} type={props.inputType} className="form-control" placeholder={TEXTJSON.DO} max={300} style={{ width: "5em" }} />
    </div>
  );
};

export default FromTo;
