import React from 'react'
import TEXTJSON from "../../../../data/text.json";
let ListBtnChangeRating = (props) => {
  return (
    <>
      <div className="d-flex flex-row justify-content-center align-items-center">
        <div className="fw-bold  text-uppercase me-1"> {TEXTJSON.MESTO}:</div>
        <input
          onChange={(e) => props.setItem(e.target.value)}
          type="number"
          className=" form-control rounded-pill" 
        />
       
        <button
          onClick={() => props.act(props.userId)}
          type="button"
          className="btn btn-primary ms-1"
        >
          <img alt="" className=" " width="20" src="/images/icons/save.svg"></img>
        </button>
      </div>
    </>
  )
}
export default ListBtnChangeRating
