import React from "react";
import { NavLink } from "react-router-dom";
import TEXTJSON from "../../../../data/text.json";
function DayOfWeek(props) {
  let deleteItme = (trainingId) => {
    props.setIsScheduleFetching(true);
    Promise.all([props.API.DeleteTraining(trainingId)]).then((results) => { 
      if (results[0].data === "successfully") { 

        Promise.all([props.API.GetTrainings()]).then((results) => { 
          if (results[0].data.trainings) props.setClubTrainings(results[0].data.trainings);
          props.setIsScheduleFetching(false);
        });
      } else alert(TEXTJSON.universal_error);
    });
  };
  return (
    <div className="col    ">
      <table className="table my-table table-bordered shadow caption-top">
        <caption className=" ">
          <span className="badge rounded-pill bg-dark ms-3">{props.dayName}</span>
        </caption>
        <thead className="table-dark">
          <tr>
            {/* <th scope="col">#</th> */}
            <th scope="col">{TEXTJSON.SEKCIYA}</th>
            <th scope="col">{TEXTJSON.TRENER}</th>
            <th scope="col">{TEXTJSON.VREMYA}</th>
            {props.isEditMode ? <th scope="col" className="text-end pe-1"></th> : <></>}
          </tr>
        </thead>
        <tbody>
          {props.clubTrainings.filter((t) => t.dayOfWeek.dayTurn === props.dayTurn).length > 0 ? (
            props.clubTrainings.map((training, index) => {
              if (training.dayOfWeek.dayTurn !== props.dayTurn) return null;
              return <DayOfWeekItem deleteItme={deleteItme} key={`DayOfWeekItem${index}`} isEditMode={props.isEditMode} {...training} />;
            })
          ) : (
            <tr className="">
              <td colSpan={props.isEditMode ? 5 : 4} className="text-center   display-6 pt-3 border-bottom-0">
              {TEXTJSON.TRENEROVOK_NET}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
export default DayOfWeek;

let DayOfWeekItem = (props) => {
  return (
    <tr className="small">
      {/* <th scope="row">1</th> */}
      <td>
        {props.sportType.name.split('(').map((item)=>{
          if(item==="детям)")return (<> <span className="kids-text rounded p-0 px-1 fw-bold">детям</span></>)
          else return (item)
        })}
        {/* {props.sportType.name.split('(детям)')[0]} {props.sportType.name.split('(детям)')[1]} */}
        </td>
      {/* <td>
        <NavLink to={"/PersonalAccount/" + props.userTrainer.id} className="text-wrap  link-dark text-center       ">
          {props.userTrainer.firstName ? props.userTrainer.firstName : ""}
          <br />
          {props.userTrainer.lastName ? props.userTrainer.lastName : ""}
        </NavLink>
      </td> */}
      <td>
        <p  className="text-wrap  link-dark text-center       ">
          {props.trainerName ? props.trainerName : "не указан"}
        </p>
      </td>
      <td>{new Date(props.time).toLocaleTimeString().slice(0, -3)}</td>
      {props.isEditMode ? (
        <td className="text-center    ">
          <img alt="" onClick={(e) => props.deleteItme(props.id)} height="30" className=" " src="/images/icons/delete.svg" />
        </td>
      ) : (
        <></>
      )}
    </tr>
  );
};
