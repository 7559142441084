import React from "react";
import InputText from "../common/InputText/InputText";
import TEXTJSON from "../../data/text.json"; 

function SocialMedia(props) {
  let saveSocialMediaItem = (btnIndex, socialMediaName) => {
    if (socialMediaName === "sherdog") {
      props.setItemIsLoadArray(btnIndex, true);
      goBtnSaveEvent(props.sherdog, "sherdog");
    } else if (socialMediaName === "facebook") {
      props.setItemIsLoadArray(btnIndex, true);
      goBtnSaveEvent(props.facebook, "facebook");
    } else if (socialMediaName === "instagram") {
      props.setItemIsLoadArray(btnIndex, true);
      goBtnSaveEvent(props.instagram, "instagram");
    } else if (socialMediaName === "vk") {
      props.setItemIsLoadArray(btnIndex, true);
      goBtnSaveEvent(props.vk, "vk");
    } else if (socialMediaName === "youTube") {
      props.setItemIsLoadArray(btnIndex, true);
      goBtnSaveEvent(props.youTube, "youTube");
    } else if (socialMediaName === "telegram") {
      props.setItemIsLoadArray(btnIndex, true);
      goBtnSaveEvent(props.telegram, "telegram");
    } else if (socialMediaName === "tiktok") {
      props.setItemIsLoadArray(btnIndex, true);
      goBtnSaveEvent(props.facebook, "tiktok");
    } else if (socialMediaName === "twitter") {
      props.setItemIsLoadArray(btnIndex, true);
      goBtnSaveEvent(props.facebook, "twitter");
    } else return;
  };
  let goBtnSaveEvent = (smLink, smName) => {
    Promise.all([props.API.UpdateSocialMedia(smLink, smName)]).then((results) => {
      console.log(results[0].data,"feffe");
      if (results[0].data !== "successfully") window.alert(TEXTJSON.universal_error);
      props.setAllItemIsLoadArrayFalse();
    });
  };
  return (
<>
      <div className=" d-flex flex-row flex-wrap py-2 justify-content-center">
        {props.facebook && (
          <div className="p-2">
            <a href={props.facebook}>
              <img src="/images/social/facebook.svg" alt="" width="30" className=" " />
            </a>
          </div>
        )}
        {props.instagram &&<div className="p-2">
          <a href={ props.instagram}>
            <img src="/images/social/instagram.svg" alt="" width="30" className=" " />
          </a>
        </div>}
       {props.sherdog && <div className="p-2">
          <a href={ props.sherdog}>
            <img src="/images/social/sherdog.svg" alt="" width="30" className=" " />
          </a>
        </div>}
        {props.tiktok &&props.tiktok &&<div className="p-2">
          <a href={ props.tiktok}>
            <img src="/images/social/tiktok.svg" alt="" width="30" className=" " />
          </a>
        </div>}
       {props.vk && <div className="p-2">
          <a href={ props.vk}>
            <img src="/images/social/vk.svg" alt="" width="30" className=" " />
          </a>
        </div>}
       {props.youTube &&  <div className="p-2">
          <a href={props.youTube}>
            <img src="/images/social/youTube.svg" alt="" width="30" className=" " />
          </a>
        </div>}
      </div>
    </>


    // <article>
    //   <div className=" m-0 p-0  container-fluid">
    //     <div className="row gx-0 gy-3">
    //       <div className="col-12">
    //         <InputText inputValue={props.sherdog} lableImg="/images/social/sherdog.svg" act={() => saveSocialMediaItem(0, "sherdog")} isLoad={props.isLoadArray[0]} setItem={(value) => props.setSocialMedia("sherdog", value)} />
    //       </div>
    //       <div className="col-12">
    //         <InputText inputValue={props.facebook} lableImg="/images/social/facebook.svg" setItem={(value) => props.setSocialMedia("facebook", value)} act={() => saveSocialMediaItem(1, "facebook")} isLoad={props.isLoadArray[1]} />
    //       </div>
    //       <div className="col-12">
    //         <InputText inputValue={props.instagram} lableImg="/images/social/instagram.svg" act={() => saveSocialMediaItem(2, "instagram")} isLoad={props.isLoadArray[2]} setItem={(value) => props.setSocialMedia("instagram", value)} />
    //       </div>
    //       <div className="col-12">
    //         <InputText inputValue={props.vk} lableImg="/images/social/vk.svg" act={() => saveSocialMediaItem(3, "vk")} isLoad={props.isLoadArray[3]} setItem={(value) => props.setSocialMedia("vk", value)} />
    //       </div>
    //       <div className="col-12">
    //         <InputText inputValue={props.youTube} lableImg="/images/social/youTube.svg" setItem={(value) => props.setSocialMedia("youTube", value)} act={() => saveSocialMediaItem(4, "youTube")} isLoad={props.isLoadArray[4]} />
    //       </div>
    //       <div className="col-12">
    //         <InputText inputValue={props.telegram} lableImg="/images/social/telegram.svg" setItem={(value) => props.setSocialMedia("telegram", value)} act={() => saveSocialMediaItem(5, "telegram")} isLoad={props.isLoadArray[5]} />
    //       </div>
    //       <div className="col-12">
    //         <InputText inputValue={props.twitter} lableImg="/images/social/twitter.svg" act={() => saveSocialMediaItem(6, "twitter")} isLoad={props.isLoadArray[6]} setItem={(value) => props.setSocialMedia("twitter", value)} />
    //       </div>
    //       <div className="col-12">
    //         <InputText inputValue={props.tiktok} lableImg="/images/social/tiktok.svg" act={() => saveSocialMediaItem(7, "tiktok")} isLoad={props.isLoadArray[7]} setItem={(value) => props.setSocialMedia("tiktok", value)} />
    //       </div>
    //     </div>
    //   </div>
    // </article>
  );
}

export default SocialMedia;
