import React from "react";
import ListBtnApprove from "./ListBtnApprove";
import ListBtnDelete from "./ListBtnDelete";
import ListBtnReject from "./ListBtnReject";
import ListBtnChangeStatus from "./ListBtnChangeStatus";
import ListBtnChangeRating from "./ListBtnChangeRating";
import { NavLink } from "react-router-dom";
import NextSpanIcon from "../../NextSpanIcon/NextSpanIcon";
import ListBtnChangeCoach from "./ListBtnChangeCoach";
import TEXTJSON from "../../../../data/text.json";


let List = (props) => {

  if(props.user.coach){
    props.user.coachLastName=props.user.coach.lastName;
    props.user.coachFirstName=props.user.coach.firstName
  }


  return (
    <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 ">
      <ol className="list-group  ">
        <li className="list-group-item d-flex justify-content-between align-items-start">
          <div className=" m-auto">
            <div className="my-img-table   rounded-circle" style={{ backgroundImage: `url(${props.user.avatarUrl})` }}></div>
          </div>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-start">
          <div className=" my-auto">
            <div className="fw-bold text-uppercase">{TEXTJSON.FAMILIYA}</div>
          </div>
          <span className=" ">{props.user.firstName?props.user.firstName:"ПУСТО"}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-start">
          <div className=" my-auto">
            <div className="fw-bold text-uppercase">{TEXTJSON.IMYA}</div>
          </div>
          <span className=" ">{props.user.lastName?props.user.lastName:"ПУСТО"}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-start">
          <div className=" my-auto">
            <div className="fw-bold text-uppercase">{TEXTJSON.POCHTA}</div>
          </div>
          <span className=" ">{props.user.email?props.user.email:"ПУСТО"}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-start">
          <div className=" my-auto">
            <div className="fw-bold text-uppercase">
            {TEXTJSON.NOMER_TELEFONA}   
            </div>
          </div>
          <span className="  my-auto">{props.user.phoneNumber?`+7${props.user.phoneNumber}`:"ПУСТО"}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-start">
          <div className=" my-auto">
            <div className="fw-bold text-uppercase">
            {TEXTJSON.DATA_ROZHDENIYA}  
            </div>
          </div>
          <span className="  my-auto">{new Date(props.user.dateOfBirth).toLocaleDateString()}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-start">
          <div className=" my-auto">
            <div className="fw-bold text-uppercase">{TEXTJSON.GOROD} </div>
          </div>
          <span className=" ">{props.user.cityName?props.user.cityName:"ПУСТО"}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-start">
          <div className=" my-auto">
            <div className="fw-bold text-uppercase">{TEXTJSON.POL}</div>
          </div>
          <span className=" ">{props.user.gender === "man" ? TEXTJSON.MUZHCHINA :  TEXTJSON.ZHENSHCHINA}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-start">
          <div className=" my-auto">
            <div className="fw-bold text-uppercase">{TEXTJSON.ROST}</div>
          </div>
          <span className=" ">{props.user.height?props.user.height:"ПУСТО"}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-start">
          <div className=" my-auto">
            <div className="fw-bold text-uppercase">{TEXTJSON.VES}</div>
          </div>
          <span className=" ">{props.user.weight?props.user.weight:"ПУСТО"}</span>
        </li> 

        <li className="list-group-item d-flex justify-content-between align-items-start">
          <div className=" my-auto">
            <div className="fw-bold text-uppercase">Личный тренер</div>
          </div>
          {console.log(props.user)}
          <span className=" ">{props.user.coachId?<NavLink to={"/PersonalAccount/" + props.user.coachId} >{`${props.user.coachLastName} ${props.user.coachFirstName}`}</NavLink>:"ПУСТО"}</span>
        </li> 

        {props.user.clubCoaches&& props.user.clubCoaches.length? <li className="list-group-item d-flex justify-content-between align-items-start">
          <div className=" my-auto">
            <div className="fw-bold text-uppercase ">Тренерует в</div>
          </div>
          <span className=" ">{props.user.clubCoaches?props.user.clubCoaches.map((cl)=>(<NavLink className="text-end small" to={"/Club/" + cl.clubId}>{cl.club.address}</NavLink>)):"ПУСТО"}</span>
        </li>:<></>}

        {props.user.clubFighters&& props.user.clubFighters.length?     <li className="list-group-item d-flex justify-content-between align-items-start">
          <div className=" my-auto">
            <div className="fw-bold text-uppercase ">Дерется в</div>
          </div>
          <span className=" ">{props.user.clubFighters?props.user.clubFighters.map((cl)=>(<NavLink className="text-end small" to={"/Club/" + cl.clubId}>{cl.club.address}</NavLink>)):"ПУСТО"}</span>
        </li>:<></>}

        <li className="list-group-item d-flex justify-content-between align-items-start">
          <div className=" my-auto">
            <div className="fw-bold text-uppercase">{TEXTJSON.MESTO_V_TOPE}</div>
          </div>
          <span className=" ">{props.user.whereInRanking?props.user.whereInRanking:"ПУСТО"}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between  align-items-start">
          <div className=" container-fluid m-0 p-0">
            <div className="row gy-2 gx-1 justify-content-center">
              <div className="col-12 d-flex align-items-center justify-content-center">{props.act.reject  && <ListBtnReject userId={props.user.id} act={props.act.reject} />}</div>
              <div className="col-12 d-flex align-items-center justify-content-center">{props.act.approve  && <ListBtnApprove userId={props.user.id} act={props.act.approve} />}</div>
              <div className="col-12 d-flex align-items-center justify-content-center">{props.act.delete  && <ListBtnDelete userId={props.user.id} act={props.act.delete} />}</div>
              <div className="col-12 d-flex align-items-center justify-content-center">{props.act.changeStatus  && <ListBtnChangeStatus userId={props.user.id} act={props.act.changeStatus} isStar={props.user.isProfessional} />}</div>
              {/* ===================================================================================================================================================================================================================================================== */}
              <div className="col-12 d-flex align-items-center justify-content-center">{props.act.setNewUserTrainer  && <ListBtnChangeCoach coaches={props.coachesFighter} userId={props.user.id} coachId={props.user.coachId} act={props.act.setNewUserTrainer} />}</div>
              {/* ===================================================================================================================================================================================================================================================== */}
             
              <div className="col-12 d-flex align-items-center justify-content-center">{props.act.changeRating  && <ListBtnChangeRating  setItem={ props.setRating} userId={props.user.id} act={props.act.changeRating} />}</div>
              <div className="col-12 d-flex align-items-center justify-content-center"><NavLink to={"/PersonalAccount/" + props.user.id} className="btn btn-link btn-lg">{TEXTJSON.K_PROFILYU}<NextSpanIcon/></NavLink> </div>

            </div>
          </div>
        </li>
      </ol>
    </div>
  );
};
export default List; 
 