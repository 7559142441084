import React from "react";
import AlertInfo from "../../../common/Alert/AlertInfo";
import TEXTJSON from "../../../../data/text.json";
let NewPasswordPage = (props) => {
  let InputValidOrNo;
  if (props.errorMessage) {
    InputValidOrNo = "is-invalid";
  } else {
    InputValidOrNo = "";
  }
  return (
    <div className="row gy-3 align-items-center">
      <div className="col-12">
        <label htmlFor="FormControlInputPassword" className="form-label">
        {TEXTJSON.PAROL}
        </label>
        <input value={props.inputPassword} onChange={(event) => props.setInputPassword(event.target.value)} type="password" className={"form-control  form-control-lg   " + InputValidOrNo} id="FormControlInputPassword" />
      </div>
      <div className="col-12">
        <label htmlFor="FormControlInputConfirm" className="form-label">
        {TEXTJSON.POTVERDIT}
        </label>
        <input value={props.inputConfirmationPassword} onChange={(event) => props.setInputConfirmationPassword(event.target.value)} type="password" className={"form-control  form-control-lg   " + InputValidOrNo} id="FormControlInputConfirm" />
      </div>
      <div className="col-12"> 
        <div className="invalid-feedback">* {props.errorMessage}</div>
      </div>
      <div className="col-12">
        <AlertInfo alertText={TEXTJSON.PAS_INSTRUCT} />
      </div>
    </div>
  );
};

export default NewPasswordPage;
