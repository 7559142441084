import React from "react"; 

let SocialMediaItems = (props) => {
  return (
    <>
      <div className=" d-flex flex-row flex-wrap py-2 justify-content-center">
        {props.socialMedia.facebook && (
          <div className="p-2">
            <a href={props.socialMedia.facebook}>
              <img src="/images/social/facebook.svg" alt="" width="30" className=" " />
            </a>
          </div>
        )}
        {props.socialMedia.instagram &&<div className="p-2">
          <a href={ props.socialMedia.instagram}>
            <img src="/images/social/instagram.svg" alt="" width="30" className=" " />
          </a>
        </div>}
       {props.socialMedia.sherdog && <div className="p-2">
          <a href={ props.socialMedia.sherdog}>
            <img src="/images/social/sherdog.svg" alt="" width="30" className=" " />
          </a>
        </div>}
        {props.socialMedia.tiktok &&props.socialMedia.tiktok &&<div className="p-2">
          <a href={ props.socialMedia.tiktok}>
            <img src="/images/social/tiktok.svg" alt="" width="30" className=" " />
          </a>
        </div>}
       {props.socialMedia.vk && <div className="p-2">
          <a href={ props.socialMedia.vk}>
            <img src="/images/social/vk.svg" alt="" width="30" className=" " />
          </a>
        </div>}
       {props.socialMedia.youTube &&  <div className="p-2">
          <a href={props.socialMedia.youTube}>
            <img src="/images/social/youTube.svg" alt="" width="30" className=" " />
          </a>
        </div>}
      </div>
    </>
  );
};

export default SocialMediaItems;
