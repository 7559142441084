let initialState = {
  clubs: [],
  cities: [],
  currentCityId: 0,
  isFetching: true,
  newAddress:""
}

const allClubsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SN/ALLCLUBS/SET-CLUBS':
      return { ...state, clubs: action.value }
      case 'SN/ALLCLUBS/SET-NEW-ADDRESS':
        console.log(action.value)
        return { ...state, newAddress: action.value }
    case 'SN/ALLCLUBS/SET-CITIES':
      return { ...state, cities: action.value }
    case 'SN/ALLCLUBS/SET-CURRENT-CITY-ID': 
      return { ...state, currentCityId: action.value }

      case 'SN/TOURNAMENTS-REDUCER/SET-ISFETCHING':
        return { ...state, isFetching: action.value }
  

    default:
      return state
  }
}

export const setClubs = (value) => ({
  type: 'SN/ALLCLUBS/SET-CLUBS',
  value,
})
export const setNewAddress = (value) => ({
  type: 'SN/ALLCLUBS/SET-NEW-ADDRESS',
  value,
})
 


export const setCities = (value) => ({
    type: 'SN/ALLCLUBS/SET-CITIES',
    value,
  })

  export const setIsFetching = (value) => ({
    type: 'SN/TOURNAMENTS-REDUCER/SET-ISFETCHING', 
    value,
  })

  export const setCurrentCityId = (value) => ({
    type: 'SN/ALLCLUBS/SET-CURRENT-CITY-ID',
    value,
  })

export default allClubsReducer
