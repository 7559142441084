/************************************************************************************* */
let initialState = {
  images: [],
  folder: "",
  date: "",
  isFetching: true,
  isOpen: false,
  activePhotoIndex: 0,
  postImagesMin: [],
  postImagesBig: [],
};
/************************************************************************************* */
const eventImagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SN/EVENT-IMAGES-REDUCER/SET-IMAGES":
      return { ...state, images: action.value };
    case "SN/EVENT-IMAGES-REDUCER/SET-FOLDER":
      return { ...state, folder: action.value };
    case "SN/EVENT-IMAGES-REDUCER/SET-DATE":
      return { ...state, date: action.value };
    case "SN/EVENT-IMAGES-REDUCER/SET-ISFETCHING":
      return { ...state, isFetching: action.value };
    case "SN/EVENT-IMAGES-REDUCER/SET-ISOPEN":
      return { ...state, isOpen: action.value };
    case "SN/EVENT-IMAGES-REDUCER/SET-ACTIVEPHOTOINDEX":
      return { ...state, activePhotoIndex: action.value };

    case "SN/EVENT-IMAGES-REDUCER/ADD-TO-postImagesMin":
      
    
      return { ...state, postImagesMin: action.value };
    case "SN/EVENT-IMAGES-REDUCER/ADD-TO-postImagesBig":
      
    
      return { ...state, postImagesBig: action.value };

    default:
      return state;
  }
};
/************************************************************************************* */
export const setImagesMin = (value) => ({
  type: "SN/EVENT-IMAGES-REDUCER/ADD-TO-postImagesMin",
  value,
});
export const setImagesBig = (value) => ({
  type: "SN/EVENT-IMAGES-REDUCER/ADD-TO-postImagesBig",
  value,
});

export const setImages = (value) => ({
  type: "SN/EVENT-IMAGES-REDUCER/SET-IMAGES",
  value,
});
export const setFolder = (value) => ({
  type: "SN/EVENT-IMAGES-REDUCER/SET-FOLDER",
  value,
});
export const setDate = (value) => ({
  type: "SN/EVENT-IMAGES-REDUCER/SET-DATE",
  value,
});
export const setIsFetching = (value) => ({
  type: "SN/EVENT-IMAGES-REDUCER/SET-ISFETCHING",
  value,
});
export const setIsOpen = (value) => ({
  type: "SN/EVENT-IMAGES-REDUCER/SET-ISOPEN",
  value,
});
export const setActivePhotoIndex = (value) => ({
  type: "SN/EVENT-IMAGES-REDUCER/SET-ACTIVEPHOTOINDEX",
  value,
});
/************************************************************************************* */
export default eventImagesReducer;
