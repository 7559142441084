import React from "react";
import  TEXTJSON  from "../../../data/text.json";
let InputSelectCity = (props) => {
  let activeAct = ()=>{
    props.act&&props.act();
  }
  return (
    <div className="     d-flex flex-row justify-content-center align-items-center">
      {props.lable && <div className="    text-nowrap">{props.lable}</div>} 
      <select 
      
      value= {props.inputValue}
        className="form-select rounded-pill "
        onChange={(e) => {
          props.setItem&&props.setItem( e.target.value);
        }}
      >
        {props.optionArray &&
          props.optionArray.map((item) => (
            <option id={item.id}   value={item.id} className="text-lowercase">
              {item.name}
            </option>
          ))}
      </select>
      {!props.noBtn &&  <button type="button" className={"btn btn-primary btn-sm p-1  rounded-pill  ms-1 btn-"+(props.isLoad?"secondary":"primary")} disabled={(props.isLoad?true: false)} onClick={()=>activeAct()}>
        {props.isLoad  ? (
          <div className="spinner-border spinner-border-sm text-light " role="status">
            <span className="visually-hidden">{TEXTJSON.ZAGRUZKA}</span>
          </div>
        ) : (
          // <img alt="" className=" " width="20" src="/images/icons/save.svg"></img>
          <div className=" ">{TEXTJSON.SOHRANIT}</div> 
        )}
      </button>}
    </div>
  );
};

export default InputSelectCity;
