/************************************************************************************* */
let initialState = {
  genderArray: [
    { value: 'all', isSelect: true, text: 'все' },
    { value: 'woman', isSelect: false, text: 'жен.' },
    { value: 'man', isSelect: false, text: 'муж.' },
  ],

  user: [],

  about: '',
  avatarUrl: '',
  city: { id: '', name: ' ' },
  cityId: '',
  dateOfBirth: '',
  email: '',
  firstName: '',
  gender: '',
  height: '',
  id: '',
  lastName: '',
  phoneNumber: '',
  userName: '',
  weight: '',

  cities:[],

  dataUtiFromInputFile: '',
//  1) LastName,
  //  2) FirstName,
  //  3) DateOfBirth,
  //  4) Weight,
  //  5) Height,
  //  6) About,
  //  7) AvatarUrl,
  //  8) gender,
  //  9) City,
  // 10) InputDataUtiFromInputFile,
  isLoadArray: [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ],


  user:{},
  isFething:true
}
/************************************************************************************* */
const personalAccount = (state = initialState, action) => {
  switch (action.type) {
    case 'SN/PERSONAL-ACCOUNT-REDUCER/SET-USER':
      return { ...state,  user: action.value }

      case 'SN/PERSONAL-ACCOUNT-REDUCER/SET-USERALL':
        return { ...state, ...action.user }

      case 'SN/PERSONAL-ACCOUNT-REDUCER/SET-ISFETHING':
        return { ...state,  isFething: action.value }


        case 'SN/PERSONAL-ACCOUNT-REDUCER/SET-LASTNAME':
      return { ...state, lastName: action.value }

    case 'SN/PERSONAL-ACCOUNT-REDUCER/SET-FIRSTNAME':
      return { ...state, firstName: action.value }

      case 'SN/PERSONAL-ACCOUNT-REDUCER/SET-PHONENUMBER':
        return { ...state, phoneNumber: action.value }
      
    case 'SN/PERSONAL-ACCOUNT-REDUCER/SET-GENDER':
      return { ...state, gender: action.value }

    case 'SN/PERSONAL-ACCOUNT-REDUCER/SET-DATEOFBIRTH':
      return { ...state, dateOfBirth: action.value }

    case 'SN/PERSONAL-ACCOUNT-REDUCER/SET-WEIGHT':
      return { ...state, weight: action.value }

    case 'SN/PERSONAL-ACCOUNT-REDUCER/SET-HEIGHT':
      return { ...state, height: action.value }

    case 'SN/PERSONAL-ACCOUNT-REDUCER/SET-AVATARURL':
      return { ...state, avatarUrl: action.value }

    case 'SN/PERSONAL-ACCOUNT-REDUCER/SET-ABOUT':
      return { ...state, about: action.value }

    case 'SN/PERSONAL-ACCOUNT-REDUCER/SET-CITY':
      return { ...state, cityId: action.value }

    case 'SN/PERSONAL-ACCOUNT-REDUCER/SET-DATAUTIFROMINPUTFILE':
      return { ...state, dataUtiFromInputFile: action.value }
 

      case 'SN/PERSONAL-ACCOUNT-REDUCER/SET-CITIES':
      return { ...state, cities: action.value }

    case 'SN/PERSONAL-ACCOUNT-REDUCER/SET-ITEM-IS-LOAD-ARRAY':
      console.log(action.arrIndex,action.value)
      var newIsLoadArray = state.isLoadArray
      newIsLoadArray[action.arrIndex] = action.value
      return { ...state, isLoadArray: [...newIsLoadArray] }

    default:
      return state
  }
}
/************************************************************************************* */
export const setUser = (value) => ({
  type: 'SN/PERSONAL-ACCOUNT-REDUCER/SET-USER',
  value,
})

export const setUserAll = (user) => ({
  type: 'SN/PERSONAL-ACCOUNT-REDUCER/SET-USERALL',
  user,
})

export const setIsFething = (value) => ({
  type: 'SN/PERSONAL-ACCOUNT-REDUCER/SET-ISFETHING',
  value,
})


export const setLastName = (value) => ({
  type: 'SN/PERSONAL-ACCOUNT-REDUCER/SET-LASTNAME',
  value,
})

export const setFirstName = (value) => ({
  type: 'SN/PERSONAL-ACCOUNT-REDUCER/SET-FIRSTNAME',
  value,
})

export const setPhoneNumber = (value) => ({
  type: 'SN/PERSONAL-ACCOUNT-REDUCER/SET-PHONENUMBER',
  value,
})


export const setGender = (value) => ({
  type: 'SN/PERSONAL-ACCOUNT-REDUCER/SET-GENDER',
  value,
})

export const setDateOfBirth = (value) => ({
  type: 'SN/PERSONAL-ACCOUNT-REDUCER/SET-DATEOFBIRTH',
  value,
})

export const setWeight = (value) => ({
  type: 'SN/PERSONAL-ACCOUNT-REDUCER/SET-WEIGHT',
  value,
})

export const setHeight = (value) => ({
  type: 'SN/PERSONAL-ACCOUNT-REDUCER/SET-HEIGHT',
  value,
})

export const setAvatarUrl = (value) => ({
  type: 'SN/PERSONAL-ACCOUNT-REDUCER/SET-AVATARURL',
  value,
})

export const setAbout = (value) => ({
  type: 'SN/PERSONAL-ACCOUNT-REDUCER/SET-ABOUT',
  value,
})

export const setCity = (value) => ({
  type: 'SN/PERSONAL-ACCOUNT-REDUCER/SET-CITY',
  value,
})

export const setDataUtiFromInputFile = (value) => ({
  type: 'SN/PERSONAL-ACCOUNT-REDUCER/SET-DATAUTIFROMINPUTFILE',
  value,
})

 

export const setCities = (value) => ({
  type: 'SN/PERSONAL-ACCOUNT-REDUCER/SET-CITIES',
  value,
})

export const setItemIsLoadArray = (arrIndex, value) => ({
  type: 'SN/PERSONAL-ACCOUNT-REDUCER/SET-ITEM-IS-LOAD-ARRAY',
  arrIndex,
  value,
})



/************************************************************************************* */
export default personalAccount
