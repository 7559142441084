import React from "react";
import { connect } from "react-redux";

import {
  setIsFetching,setArticleName,setArticle,setIsBtnLoad,setArticleCode
} from "../../redux/article-creater-reducer";
import * as axios from "axios";
import ArticleCreater from "./ArticleCreater";
import BigPreloader from "../common/preloaders/BigPreloader";
import TEXTJSON from "../../data/text.json"
// ================================================================================
class ArticleCreaterAPIComponent extends React.Component {
  // ================================================================================
  componentDidMount() {
    
    this.props.setIsFetching(false);

    // Promise.all([this.API.GetProfileInfo()]).then((results) => {
    // });
  }
  // ================================================================================
  API = {
    create : () => {
      console.log( this.props.article, this.props.articleName, this.props.articleCode)
      return axios({
        method: "POST",
        url: "/api/post/article/Сreate",
        data: {
          HtmlText: this.props.article,
          Title: this.props.articleName,
          Code: this.props.articleCode,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    }, 
    // ___________
  };
  // ================================================================================
  render() {
    return <>{ this.props.isFetching ? <BigPreloader preloaderColor="danger" /> :this.props.isAdmin? <ArticleCreater {...this.props} API={ this.API} />:<p className="text-center text-danger display-6"> {TEXTJSON.dostup_zapreshchen}</p>}</>; 
  
  }
}
// ================================================================================
let mapStateToProps = (state) => {
  return {
    article: state.articleCreater.article,
    articleName: state.articleCreater.articleName,
    articleCode: state.articleCreater.articleCode,
    isFetching: state.articleCreater.isFetching,
    isBtnLoad: state.articleCreater.isBtnLoad,

    isAuthorized: state.auth.isAuthorized,
    clubCoachesIdArray: state.auth.clubCoachesIdArray, 
    isCoach: state.auth.isCoach,
    isAdmin: state.auth.isAdmin,
  };
};
// ================================================================================
const ArticleCreaterConteiner = connect(mapStateToProps, {
   setIsFetching,setArticleName,setArticle,setIsBtnLoad,setArticleCode
})(ArticleCreaterAPIComponent);
// ================================================================================
export default ArticleCreaterConteiner;
