import React from "react";
import { connect } from "react-redux";
import TEXTJSON from "../../data/text.json";

import { setArticles, setCurrentPage, setTotalProductsCount, setIsFetching, setIsPaginationLoad } from "../../redux/photoList-reducer";
import * as axios from "axios";
import PhotoList from "./PhotoList";
import BigPreloader from "../common/preloaders/BigPreloader";
import BigTextAlert from "../common/BigTextAlert/BigTextAlert";
// ================================================================================
class PhotoListAPIComponent extends React.Component {
  // ================================================================================
  componentDidMount() {
    this.props.setIsFetching(true);
    this.props.setArticles([]);
    this.props.setTotalProductsCount([]);

    Promise.all([this.API.TakeFolders()]).then((results) => {

      if (results[0].data) {

        let newFolders = results[0].data.folders.map((folder) => {
          let imgHref = folder.srcViewImg;
          imgHref = `/api/get/photo/ImgWithAuthByPath/${encodeURIComponent(imgHref)}`;
          return { ...folder, srcViewImg: imgHref }

        })


        this.props.setArticles(newFolders ? newFolders : []);
        this.props.setTotalProductsCount(results[0].data.count ? results[0].data.count : 0);
      }

      this.props.setIsFetching(false);
    });
  }
  // ================================================================================
  API = {
    TakeFolders: () => {
      console.log("/api/get/photo/Folders?page=" + this.props.currentPage + "&pageItemCount=" + this.props.pageSize);
      return axios({
        method: "GET",
        url: "/api/get/photo/Folders?page=" + this.props.currentPage + "&pageItemCount=" + this.props.pageSize,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => {
          console.log("");
          return [];
        });
    },
    // ___________
  };
  // ================================================================================
  render() {
    return <>{this.props.isFetching ? <BigPreloader preloaderColor="danger" /> : this.props.articles.length ? <PhotoList {...this.props} API={this.API} /> : <BigTextAlert textinner={TEXTJSON.FOTO_OTUVSTUYUT} />}</>;
  }
}
// ================================================================================
let mapStateToProps = (state) => {
  return {
    articles: state.photoList.articles,
    isFetching: state.photoList.isFetching,
    pageSize: state.photoList.pageSize,
    totalProductsCount: state.photoList.totalProductsCount,
    currentPage: state.photoList.currentPage,
    isPaginationLoad: state.photoList.isPaginationLoad,
  };
};
// ================================================================================
const PhotoListConteiner = connect(mapStateToProps, {
  setArticles,
  setCurrentPage,
  setTotalProductsCount,
  setIsFetching,
  setIsPaginationLoad,
})(PhotoListAPIComponent);
// ================================================================================
export default PhotoListConteiner;
