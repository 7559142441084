import React from "react";
import { connect } from "react-redux";
import Main from "./Main";
import { withRouter } from "react-router-dom";
import { setTopUsers, setFighters, setCoaches, setIsFething } from "../../redux/main-reducer";
import * as axios from "axios"; 
import BigPreloader from "../common/preloaders/BigPreloader";

// ===========================================================================

class MainAPIComponent extends React.Component {
  componentDidMount() {
    this.props.setIsFething(true);

    Promise.all([this.API.GetTopUsers(), this.API.GetCoaches()]).then((results) => { 
      if (Array.isArray(results[0].data)) {
  this.props.setTopUsers(results[0].data);
      }
      if (Array.isArray(results[1].data)) {
      this.props.setCoaches(results[1].data);
        
      }
      this.props.setIsFething(false);
    });
  }

  // ===========================================================================

  API = {
    //   GET   GET   GET   GET   GET   GET   GET   GET   GET   GET
    GetFigters: () => {
      return axios({
        method: "GET",
        url: "api/get/user/random?usertype=pro&count=5",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    GetCoaches: () => {
      return axios({
        method: "GET",
        url: "api/get/user/random?usertype=coach&count=5",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
    GetTopUsers: () => {
      return axios({
        method: "GET",
        url: "api/get/user/RatingFighter/25",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
        .then((response) => response)
        .catch((error) => error.response);
    },
  };

  // ===========================================================================

  render() {
    return <>{this.props.isFething ? <BigPreloader preloaderColor="danger" /> : <Main {...this.props} API={this.API} />}</>;
  }
}

// ===========================================================================

let mapStateToProps = (state) => {
  return {
    topUsers: state.main.topUsers,
    fighters: state.main.fighters,
    coaches: state.main.coaches,

    isFething: state.main.isFething,

    // _____________________________________
    isAuthorized: state.auth.isAuthorized,
  };
};

// ===========================================================================

let withURLDataComponentContainer = withRouter(MainAPIComponent);

// ===========================================================================

const MainConteiner = connect(mapStateToProps, {
  setTopUsers,
  setFighters,
  setCoaches,
  //______________
  setIsFething,
})(withURLDataComponentContainer);

// ===========================================================================

export default MainConteiner;
