import React from "react";
import TEXTJSON from "../../../../data/text.json";
let ListBtnReject = (props) => {
  return (
    <button onClick={() => props.act(props.userId)} type="button" className="btn  rounded-pill  btn-danger">
      {TEXTJSON.OTKAZAT}
    </button>
  );
};
export default ListBtnReject;
