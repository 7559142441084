import React from "react";
import ReactBnbGallery from "react-bnb-gallery"; 
var options = {
  year: "numeric",
  month: "long",
  day: "numeric",
  // weekday: 'long',
  timezone: "UTC",
};

function EventImages(props) {




  // let PHOTOS = props.images.map((img) => { 
  //   return `/api/get/photo/ImgWithAuth/${encodeURIComponent(img.href)}` 
  // });

  // let PHOTOSBIG = props.images.map((img) => {
  //   let imgHref = img.href.replace("size=S", "size=XXXL");
  //   return `/api/get/photo/ImgWithAuth/${encodeURIComponent(imgHref)}` 
  // });

  return (
    <article>
      <ReactBnbGallery   backgroundColor={"#000000f8"} showThumbnails={false} activePhotoIndex={props.activePhotoIndex} show={props.isOpen} photos={props.postImagesBig} onClose={() => props.setIsOpen(false)} />

      <div className="container">
        <div className="row gx-1 gx-sm-1 gx-md-2 gx-lg-3 gx-xl-4  gy-4 gy-sm-4 gy-md-4 gy-lg-4 gy-xl-4 ">
          <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">
            <p className="text-black-50">{new Date(props.date).toLocaleString("ru", options)}</p>
          </div>
          <div className="col-12"></div>
          <h1 className="col-12">{props.folder}</h1>
          {props.postImagesMin.map((img, i) => (
            <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2"> 
               <img
                onClick={() => {
                  props.setIsOpen(true);
                  props.setActivePhotoIndex(i);
                }}
                className="w-100  rounded-3 my-img-folder btn p-0 m-0"
                src={img}
                alt=""
              />  
            </div>
          ))}
        </div>
      </div>
    </article>
  );
}

export default EventImages;
