import React from "react";
import AlertWarning from "../../../common/Alert/AlertWarning";
import TEXTJSON from "../../../../data/text.json";
let CodePage = (props) => {
  let InputValidOrNo;
  if (props.errorMessage) {
    InputValidOrNo = "is-invalid";
  } else {
    InputValidOrNo = "";
  }
  return (
    <div className="row gy-3 align-items-center">
      <div className="col-12">
        <label htmlFor="FormControlInputEmail" className="form-label">
        {TEXTJSON.VVEDITE_KOD_VYSLANNYJ_VAM_PO_POCHTE}   
        </label>
        <input
          value={props.inputCode}
          onChange={(event) => props.setInputCode(event.target.value)}
          type="email"
          className={"form-control  form-control-lg text-center  " + InputValidOrNo}
          id="FormControlInputEmail"
          aria-describedby="emailHelp"
          required
        />
        <div className="invalid-feedback">* {props.errorMessage}</div>
      </div>
      <div className="col-12">
        <AlertWarning alertText={TEXTJSON.ESLI_VY_NE_SMOZHETE_NAJTI_VYSLANNOE_NAMI_SOOBSHCHENIE_TO_PROVERTE_SPAM_PISMA_VOZMOZHNO_ONO_TAM}  />
      </div>
    </div>
  );
};

export default CodePage;
