import React from "react";
import TEXTJSON from "../../data/text.json";

import ArticlesList from "../common/ArticlesList/ArticlesList";
import PaginationEnlarge from "../common/PaginationEnlarge/PaginationEnlarge";
function PhotoList(props) {
  let moreArticles = () => {
    props.setIsPaginationLoad(true);
    props.setCurrentPage(props.currentPage + 1);
    Promise.all([props.API.TakeFolders()]).then((results) => {

     let newFolders = results[0].data.folders.map((folder) => {

         let imgHref = `/api/get/photo/ImgWithAuthByPath/${encodeURIComponent(folder.srcViewImg)}`;
         console.log(imgHref)
        return { ...folder, srcViewImg: imgHref }

      }) 

      props.setArticles(props.articles.concat(newFolders));
      props.setIsPaginationLoad(false);
    });
  };
  return (
    <article>
      <div className=" d-flex flex-column align-items-center">
        <ArticlesList forNavLink={"EventImages"} titleForList={TEXTJSON.photo} items={props.articles} />
        <div className="mt-5">
          <PaginationEnlarge act={() => moreArticles()} localUsersCount={props.articles.length} totalProductsCount={props.totalProductsCount} isLoad={props.isPaginationLoad} />
        </div>
      </div>
    </article>
  );
}

export default PhotoList;
