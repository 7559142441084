import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";  
import Menegment from "./Menegment"; 
// ================================================================================
class MenegmentAPIComponent extends React.Component {
  // ================================================================================
  componentDidMount() {
    
    // this.props.setIsFething(true);

    // Promise.all([this.API.GetProfileInfo()]).then((results) => {
    // });
  }
  // ================================================================================
  API = {
    // GetProfileInfo: () => {
       
    //   return axios({
    //     method: "GET",
    //     url: "/Profile/GetProfileInfo" ,
    //     headers: {
    //       Accept: "application/json",
    //       Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    //     },
    //   })
    //     .then((response) => response)
    //     .catch((error) => error.response);
    // },
    // ___________
  };
  // ================================================================================
  render() {
    return <>{this.props.clubCoachesIdArray.indexOf( this.props.match.params.clubId ) !== -1||this.props.isAdmin  ?<Menegment {...this.props} API={ this.API} />  :<></> }</>; 
  
  }
}
// ================================================================================
let mapStateToProps = (state) => {
  return {
    isAuthorized: state.auth.isAuthorized,
    clubCoachesIdArray: state.auth.clubCoachesIdArray, 
    isCoach: state.auth.isCoach,
    isAdmin: state.auth.isAdmin,
  };
};

let withURLDataComponentContainer = withRouter(MenegmentAPIComponent);
// ================================================================================
const MenegmentConteiner = connect(mapStateToProps, {
//   setUser,
})(withURLDataComponentContainer);
// ================================================================================
export default MenegmentConteiner;
