import React from "react";
import TEXTJSON from "../../../data/text.json";
let AlertWarning = (props) => {
  let alertMessage;
  if (props.alertText === undefined) {
    alertMessage = TEXTJSON.CHITAJTE_INSTRUKCIYU ;
  }
  else{
    alertMessage=props.alertText;
  }
  return (
    <div className="alert alert-warning" role="alert">
      <h4 className="alert-heading"> {TEXTJSON.warning}</h4>
      <p>{alertMessage}</p>
    </div>
  );
};

export default AlertWarning;
