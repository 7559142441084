import React from "react";
import TEXTJSON from "../../../data/text.json"
let Preloader = (props) => (
  <div className="d-flex justify-content-center">
    <div className={"spinner-border spinner-border-sm text-"+(props.preloaderColor?props.preloaderColor:"primary")} role="status">
      <span className="visually-hidden">{TEXTJSON.ZAGRUZKA}</span>
    </div>
  </div>
);

export default Preloader;
