let initialState = {
  // categories: [],
};

const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    // case "SN/HEADER/SET-CATEGORY":
    //   return { ...state, categories: action.categories };

    default:
      return state;
  }
};

// export const setCategory = (categories) => ({
//   type: "SN/HEADER/SET-CATEGORY",
//   categories,
// });

export default headerReducer;
