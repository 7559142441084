import React from 'react'
import UserList from '../common/UserList/UserList'
import Pagination from '../common/Pagination/Pagination' 
import FilterZone from './components/FilterZone'
import BtnPreloader from '../common/preloaders/BtnPreloader'
import TEXTJSON from "../../data/text.json";

function AdminUserList(props) {
  let changeStatusPro = (userId) => {
    props.setIsFething(true)
    Promise.all([props.API.ChangeProStatus(userId)]).then((results) => {
      if (results[0].data === 'successfully') {
        Promise.all([props.API.GetWithFilter()]).then((results) => {
          props.setUsers(results[0].data.users)
          props.setTotalProductsCount(results[0].data.usersCount)
          props.setIsFething(false)
        })
      } else {
        alert(TEXTJSON.universal_error)
      }
    })
  }

  let changeRating = (userId) => {
    props.setIsFething(true)
    Promise.all([props.API.ChangeRating(userId)]).then((results) => {
      if (results[0].data === 'successfully') {
        Promise.all([props.API.GetWithFilter()]).then((results) => {
          props.setUsers(results[0].data.users)
          props.setTotalProductsCount(results[0].data.usersCount)
          props.setIsFething(false)
        })
      } else {
        alert(TEXTJSON.universal_error)
      }
    })
  }

  let onPageChanged = (newPageNum) => {
    props.setIsFething(true)

    Promise.all([props.setCurrentPage(newPageNum)]).then((results) => {
      Promise.all([props.API.GetWithFilter()]).then((results) => {
        props.setUsers(results[0].data.users)
        props.setTotalProductsCount(results[0].data.usersCount)
        props.setIsFething(false)
      })
    })
  }

  let deleteUser = (userId) => {
    let result = window.confirm(TEXTJSON.are_you_sure)
    if (!result) return
    props.setIsFething(true)
    Promise.all([props.API.DeleteUser(userId)]).then((results) => {
      if (results[0].data === 'successfully') {
        Promise.all([props.API.GetWithFilter()]).then((results) => {
          props.setUsers(results[0].data.users)
          props.setTotalProductsCount(results[0].data.usersCount)
          props.setIsFething(false)
        })
      } else {
        alert(TEXTJSON.universal_error)
      }
    })
  }
 
  return (
    <article className="d-flex flex-column   ">
      {console.log(props.users)}
      <p className="ms-3 ">
        <button
          className="btn  btn-primary my-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseExample"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <img height="25" alt="" src="/images/icons/filter.svg" />
        </button>
      </p>
      <FilterZone {...props} />
      <div className="d-flex align-items-center justify-content-center my-4">
        <Pagination
          onPageChanged={onPageChanged}
          currentPage={props.currentPage}
          totalProductsCount={props.totalProductsCount}
          pageSize={props.pageSize}
        />
      </div>
      <> 
        {props.IsFetching ? (
          <BtnPreloader preloaderColor="dark" />
        ) : (
          
          <UserList
            setRating={props.setRating}
            changeRatingAct={changeRating}
            changeStatusAct={changeStatusPro}
            listTitle={TEXTJSON.SPISOK_BOJCOV_KLUBA}
            users={props.users}
            deleteAct={deleteUser}
          />
        )}
      </>
       
      <div className="d-flex align-items-center justify-content-center my-4">
        <Pagination
          onPageChanged={onPageChanged}
          currentPage={props.currentPage}
          totalProductsCount={props.totalProductsCount}
          pageSize={props.pageSize}
        />
      </div>
    </article>
  )
}

export default AdminUserList
