import React from "react";
import  TEXTJSON from "../../../data/text.json";
let BtnPreloader = (props) => (
  <div className="d-flex justify-content-center align-items-center w-100 h-100">
    <button className={"d-flex flex-row justify-content-center align-items-center btn btn-" + (props.preloaderColor ? props.preloaderColor : "primary")} type="button" disabled>
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <div className="ps-2">{TEXTJSON.ZAGRUZKA}</div>
    </button>
  </div>
);

export default BtnPreloader;
