import React from "react"; 
import { NavLink } from "react-router-dom";
import TEXTJSON from "../../../data/text.json";
let ClubsZone = (props) => {
  return (
    <>
      <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4  ">
        <div className="shadow-lg  rounded-3 w-100 h-100  bg-light p-2 d-flex flex-column">
          <img alt="" className="w-100" src={props.imageUrl} />
          <div className=" mx-2 my-3 flex-grow-1 d-flex flex-column justify-content-between ">
            <div>
              <p className="h4">{props.cityName}</p>
              <p className="text-black-50">{props.address}</p>
              <p className=" h6">+7{props.phoneNumber}</p>
            </div>
            <NavLink to={"/Club/" + props.clubId} className="manrope text-decoration-none mt-3 h5">
            {TEXTJSON.PODROBNEE}<span className="">{">"}</span>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};
export default ClubsZone;
