import React from 'react'
import FromTo from '../../common/InputFromTo/FromTo'
import InputSwith from '../../common/InputSwith/InputSwith'
import InputText from '../../common/InputText/InputText'
import Select from '../../common/Select/Selecet'
import GetUrlCreater from '../../../util/GetUrlCreater' 
import InputSelectCityOrder from '../../common/InputSelectCityOrder/InputSelectCityOrder'
import TEXTJSON from "../../../data/text.json";

let FilterZone = (props) => {
  let arrayForUrl = [
    { size: props.size },
    { firstN: props.firstName },
    { lastN: props.lastName },
    { dateOfBirthFrom: props.dateOfBirthFrom },
    { dateOfBirthTo: props.dateOfBirthTo },

    { cityId: props.cityId },

    { userType: props.userType },

    { gender: props.gender },

    { orderBy: props.orderBy },

    { weightFrom: props.weightFrom },
    { weightTo: props.weightTo },

    { heightFrom: props.heightFrom },
    { heightTo: props.heightTo },


    { isCoach: props.isCoach },
    { isProfessional: props.isProfessional },

    { isRatingFighter: props.isRatingFighter },
    { isClubFighter: props.isClubFighter },
 
  ]



  let ApiGetFunction = (value, type) => { 
    // setMethod(value,type);
    props.setIsFething(true)
    Promise.all([setMethod(value, type)]).then((results) => {
      Promise.all([props.API.GetWithFilter()]).then((results) => {
        props.setUsers(results[0].data.users)
        props.setTotalProductsCount(results[0].data.usersCount)
        props.setIsFething(false)
      })
    })
  }

  let setMethod = (value, type) => {
    console.log(value)
    console.log(type)
    switch (type) {
      case 'first-name':
        props.setFirstName(value)
        break

      case 'last-name':
        props.setLastName(value)
        break

      case 'cityId':
        props.setCityId(value)
        break

      case 'gender':
        props.setGender(value)

        break

      case 'type-user':
        props.setUserType(value)

        break

      case 'order-by':
        props.setOrderBy(value)

        break

      case 'weight-to':
        props.setWeightTo(value)

        break

      case 'weight-from':
        props.setWeightFrom(value)

        break

      case 'height-to':
        props.setHeightTo(value)

        break

      case 'height-from':
        props.setHeightFrom(value)

        break

      case 'date-of-birth-from':
        props.setDateOfBirthFrom(value)

        break

      case 'date-of-birth-to':
        props.setDateOfBirthTo(value)
        break

      case 'is-coach':
        props.setIsCoach(value)
        break

      case 'is-professional':
        props.setIsProfessional(value)
        break

      case 'is-rating-fighter':
        props.setIsRatingFighter(value)
        break
      case 'is-club-fighter':
        props.setIsClubFighter(value)
        break

      default:
        break
    }
  }

  return (
    <div className="collapse " id="collapseExample">
      <div className="card card-body">
        <div className="d-flex flex-column align-items-end">
          <a className="btn btn-success" href={GetUrlCreater('/api/get/user/OrderingWFilter?isInXls=true&size=middle&', arrayForUrl)}>{TEXTJSON.SKACHAT}</a>
        </div>
        <div className=" d-flex flex-row flex-wrap ">
          <div className="m-2">
            <InputText
              inputValue={props.firstName}
              noBtn="true"
              isLoad={false}
              typeFotMethod={'first-name'}
              setItem={ApiGetFunction}
              lable={TEXTJSON.IMYA}
            />
          </div>
          <div className="m-2">
            <InputText
              inputValue={props.lastName}
              noBtn="true"
              isLoad={false}
              typeFotMethod={'last-name'}
              setItem={ApiGetFunction}
              lable={TEXTJSON.FAMILIYA} 
            />
          </div>
          <div className="m-2">
            <Select
              typeFotMethod={'gender'}
              setItem={ApiGetFunction}
              lable={TEXTJSON.POL} 
              optionArray={props.genderArray}
            />
          </div>
          <div className="m-2">
            <Select
              typeFotMethod={'order-by'}
              setItem={ApiGetFunction}
              lable={TEXTJSON.SORTEROVAT_PO} 
              optionArray={props.orderByArray}
            />
          </div>
          <div className="m-2">
            <FromTo
              inputType="number"
              firstItemValue={props.weightFrom}
              secondItemValue={props.weightTo}
              setFirstItem={ApiGetFunction}
              setSecondItem={ApiGetFunction}
              firstTypeFotMethod={'weight-from'}
              secondTypeFotMethod={'weight-to'}
              whatFilter={TEXTJSON.VES} 
            />
          </div>

          <div className="m-2">
            <FromTo
              inputType="number"
              firstItemValue={props.heightFrom}
              secondItemValue={props.heightTo}
              setFirstItem={ApiGetFunction}
              setSecondItem={ApiGetFunction}
              firstTypeFotMethod={'height-from'}
              secondTypeFotMethod={'height-to'}
              whatFilter={TEXTJSON.ROST} 
            />
          </div>
          <div>
          <InputSelectCityOrder
              typeFotMethod={'cityId'}
              setItem={ApiGetFunction}
              lable={TEXTJSON.GOROD} 
              optionArray={props.cities}
            />

            {/* <InputSelectCityOrder
            inputType="cityId"
              optionArray={props.cities} 
               setItem={ApiGetFunction} 
               inputValue={props.city&&props.cityId}
                 /> */}
          </div>
{/* //++++++++++++++++++++++++++++++++++++++++ */}
<hr/>
          <div className="m-2">
            <InputSwith
              inputValue={props.isCoach}
              typeFotMethod={'is-coach'}
              setItem={ApiGetFunction}
              lable={TEXTJSON.TRENER} 
              swithName={'swithtopTraner'}
            />
          </div>

          <div className="m-2">
            <InputSwith
              inputValue={props.isProfessional}
              typeFotMethod={'is-professional'}
              setItem={ApiGetFunction}
              lable={TEXTJSON.PROFI} 
              swithName={'swithtopfprofiighter'}
            />
          </div>

          <div className="m-2">
            <InputSwith
              inputValue={props.isRatingFighter}
              typeFotMethod={'is-rating-fighter'}
              setItem={ApiGetFunction}
              lable={TEXTJSON.TOPPOVYJ} 
              swithName={'swithtopfighter'}
            />
          </div>

          <div className="m-2">
            <InputSwith
              inputValue={props.isClubFighter}
              typeFotMethod={'is-club-fighter'}
              setItem={ApiGetFunction}
              lable={TEXTJSON.CHLEN_KLUBA} 
              swithName={'switcthtopfighter'}
            />
          </div> 
        </div>
      </div>
    </div>
  )
}
export default FilterZone
