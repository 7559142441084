import React from "react";
import BigTextAlert from "../common/BigTextAlert/BigTextAlert";
import AddClub from "./components/AddClub";
import ClubsZone from "./components/ClubsZone";
import FilterCities from "./FilterCities/FilterCities";
import TEXTJSON from "../../data/text.json";
function AllClubs(props) {
  return (
    <>
      <article className="d-flex flex-column ">
        <div className=" container  ">
          <div className="row g-3  ">
            {/*  */}
            <div className="col-12"> {props.isAdmin ? <AddClub {...props} /> : <></>}</div>

            {/*
        ================================
        ================================
        ================================
          */}
            <div className="col-12">
              <div className="   mb-5">
                <div className="d-flex ms-4 flex-row   align-items-center">
                  <p className="h4 me-3">{TEXTJSON.GOROD}: </p>
                  <FilterCities
                    currentCityId={props.currentCityId}
                    // 
                    cities={props.cities}
                    // 
                    setCurrentCityId={props.setCurrentCityId}
                    // 
                    updateClubListDueToFilter={props.API.updateClubListDueToFilter}
                  />
                </div>
              </div>
            </div>
            {/* 
        =================
        =================
        =================
        */}
            {props.clubs.length ? props.clubs.map((club, inded) => <ClubsZone key={`${inded}ClubsZone`} {...club} />) : <BigTextAlert textinner={TEXTJSON.KLUBY_OTUVSTUYUT}  />}
          </div>
        </div>
      </article>
    </>
  );
}

export default AllClubs;
