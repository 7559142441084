import { Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
// import "./App.css";
import HeaderConteiner from "./components/Header/HeaderConteiner";
import AllClubsConteiner from "./components/AllClubs/AllClubsConteiner"; 
import PersonalAccountConteiner from "./components/PersonalAccount/PersonalAccountConteiner";
import ProfileEditConteiner from "./components/ProfileEdit/ProfileEditConteiner";
import Club from "./components/Club/Club";
import MainConteiner from "./components/Main/MainConteiner";
import ClubFighterRequestsConteiner from "./components/ClubFighterRequests/ClubFighterRequestsConteiner";
import ClubCoachRequestsConteiner from "./components/ClubCoachRequests/ClubCoachRequestsConteiner";
import ClubCoachesConteiner from "./components/ClubCoaches/ClubCoachesConteiner";
import ClubFightersConteiner from "./components/ClubFighters/ClubFightersConteiner";
import AdminUserListConteiner from "./components/AdminUserList/AdminUserListConteiner";
import StarListConteiner from "./components/StarList/StarListConteiner";
import CoachesListConteiner from "./components/CoachesList/CoachesListConteiner";
import TournamentsConteiner from "./components/Tournaments/TournamentsConteiner";
import ArticleEditorConteiner from "./components/ArticleEditor/ArticleEditorConteiner";
import ArticleCreaterConteiner from "./components/ArticleCreater/ArticleCreaterConteiner";
import ArticleConteiner from "./components/Article/ArticleConteiner";
import TrusteesBoard from "./components/TrusteesBoard/TrusteesBoard";
import ClubManagement from "./components/ClubManagement/ClubManagement";
import NewsListConteiner from "./components/NewsList/NewsListConteiner";
import MediaListConteiner from "./components/MediaList/MediaListConteiner";
import PhotoListConteiner from "./components/PhotoList/PhotoListConteiner";
import MediaConteiner from "./components/Media/MediaConteiner";
import AboutClub from "./components/AboutClub/AboutClub";
import EventImagesConteiner from "./components/EventImages/EventImagesConteiner";
import AdminConteiner from "./components/Admin/AdminConteiner";
import Footer from "./components/Footer/FooterConteiner";
import ScrollToTop from "./util/ScrollToTop";

function App() {
  return (
    <BrowserRouter> 
    <ScrollToTop/>
    <div className=" min-vh-100 d-flex flex-column">
      <HeaderConteiner />
      <main className=" d-flex flex-column flex-grow-1">
        <section className="flex-grow-1 d-flex flex-column my-3">
          <Route exact path="/" render={() => <MainConteiner />} />
          <Route exact path="/allclubs" render={() => <AllClubsConteiner />} /> 
          <Route exact path="/PersonalAccount/:userId" render={() => <PersonalAccountConteiner />} />
          <Route exact path="/ProfileEdit" render={() => <ProfileEditConteiner />} />
          <Route exact path="/Club/:clubId" render={() => <Club />} />
          <Route exact path="/ClubFighterRequests/:clubId" render={() => <ClubFighterRequestsConteiner />} />
          <Route exact path="/ClubCoachRequests/:clubId" render={() => <ClubCoachRequestsConteiner />} />
          <Route exact path="/ClubCoaches/:clubId" render={() => <ClubCoachesConteiner />} />
          <Route exact path="/ClubFighters/:clubId" render={() => <ClubFightersConteiner />} />
          <Route exact path="/media/:mediaId" render={() => <MediaConteiner />} />
          <Route exact path="/AdminUserList" render={() => <AdminUserListConteiner />} />
          <Route exact path="/Tournaments" render={() => <TournamentsConteiner />} />
          <Route exact path="/EventImages/:folderName" render={() => <EventImagesConteiner />} />
          <Route exact path="/articleeditor" render={() => <ArticleEditorConteiner />} />
          <Route exact path="/article/:articleName" render={() => <ArticleConteiner />} />
          <Route exact path="/newarticle" render={() => <ArticleCreaterConteiner />} />
          <Route exact path="/NewsList" render={() => <NewsListConteiner />} />
          <Route exact path="/MediaList" render={() => <MediaListConteiner />} />
          <Route exact path="/TrusteesBoard" render={() => <TrusteesBoard />} />
          <Route exact path="/photofolders" render={() => <PhotoListConteiner />} />
          <Route exact path="/ClubManagement" render={() => <ClubManagement />} />
          <Route exact path="/stars" render={() => <StarListConteiner />} />
          <Route exact path="/coaches" render={() => <CoachesListConteiner />} />
          <Route exact path="/about" render={() => <AboutClub />} />
          <Route exact path="/admin" render={() => <AdminConteiner />} />
        </section>
      </main>
      <Footer/>
    </div>
      
    </BrowserRouter>
  );
}

export default App;
