import React from "react"; 
import TEXTJSON from "../../../../data/text.json";
let ResponseWaitCoachButton = () => {
  return (
    <>
    <div className="d-flex flex-column"> 
      <button type="button" className="btn btn-warning rounded-pill btn-sm text-uppercase align-self-center ">
      {TEXTJSON.ZAPROS_OBROBATYVAETSYA}  
      </button>
    </div>
     
    </>
  );
};
export default ResponseWaitCoachButton;
