import React from "react";
import { NavLink } from "react-router-dom"; 
import TEXTJSON from "../../data/text.json";
function StarList(props) { 
  return (
    <article className="container">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-9 col-lg-6 col-xl-4">
            <ol className="list-group  ">
              <li className="list-group-item  display-3 fw-bold text-center">{TEXTJSON.REJTING}</li>
              {props.users.map((u, i) => {
                return (
                  <li className="list-group-item d-flex justify-content-between p-1 align-items-start">
                    <div className="ms-1 m-auto ">
                      <div className="fw-bold      d-flex flex-row align-items-center  ">
                        <span className="badge bg-dark rounded-pill me-2">{++i}</span>
                        <NavLink to={"/PersonalAccount/" + u.id} className="text-wrap  link-dark">
                          {u.lastName} {u.firstName}
                        </NavLink>
                      </div>
                    </div>
                    <div className="my-top-table-img myImg rounded-pill my-2" style={{ backgroundImage: `url(${u.avatarUrl})` }}></div>
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
      </div>
    </article>
  );
}

export default StarList;
