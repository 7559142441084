import React from "react";
import TEXTJSON from "../../../../data/text.json";
let ListBtnChangeStatus = (props) => {
  return (
      <button onClick={()=>props.act(props.userId)} type="button" className={"btn rounded-pill    "+(props.isStar?"btn-danger":"  btn-outline-danger")}>
       {props.isStar?TEXTJSON.SDELAT_OBYCHNYM_BOJCOM:TEXTJSON.SDELAT_PROF_BOJCOM} 
      </button>
  );
};
export default ListBtnChangeStatus;
